import * as React from "react";
import CancelIcon from "@mui/icons-material/Cancel";
import {
  Box,
  Divider,
  Modal,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { API } from "../../../api-services";
import LoaderImage from "../../../Assets/images/loading-screen-landing.gif";
import Image from "../../../ViewModule/FeatureLandingPage/ArchitectureImageCard";
import RViewerJS from "viewerjs-react";
import { useHistory } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { SingleSelectInputBox } from "./SingleSelectInputBox";
import { CheckBoxComponent } from "./CheckBoxComponent";
import BOMImagesandVideosView from "./BOMImagesandVideos/BOMImagesandVideosView";
import { useRouteParams } from "../../../ui-reusable-component/useRouteParams";
import { useBaseModal } from "../../SearchPage/useBaseModal";
import BOMCalculatorValueInputModal from "./BOMCalculatorValueInputModal";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import { DataGrid } from "@mui/x-data-grid";
import calcIcon from "../../../Assets/images/calculator_green.svg";
import { LoadingButton } from "@mui/lab";
import BOMRoboflowDataFetchComponent from "./BOMRoboflowDataFetchComponent";
import BOMRobotoflowFetchDataTable from "./BOMRobotoflowFetchDataTable";
import RoboflowImageContainer from "./RoboflowImageContainer";

export interface IBOMImageBasedSearchModalProps {
  onCloseModal: any;
  isOpen: any;
  tableId: any;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "95vw",
  height:"90vh",
  bgcolor: "background.paper",
  borderRadius: "10px",
  p: 1,
};

export default function BPMPCBRoboflowModal(
  props: IBOMImageBasedSearchModalProps
) {
  const { onCloseModal, isOpen ,tableId} = props;
  const { vault } = useRouteParams<any>();
  const [data, setData] = React.useState<any>({});
  const [step, setStep] = React.useState<number>(1);

  const getData = () => {
    API.get(
      "/xcpep/pcb_info_for_vault/",
      {
        vault_id: vault,
      },
      0
    )
      .then((res: any) => {
        setData(res.data);
      })
      .catch((err: any) => {});
  };

  // React.useEffect(() => {
  //   getData();
  // }, []);

  return (
    <div>
      <Modal
        open={isOpen}
        onClose={onCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box sx={{ display: "flex", alignItems: "center", height: "2.5rem" }}>
            <Typography
              style={{
                fontSize: "1rem",
                fontWeight: "600",
                width: "100%",
                textAlign: "center",
                marginRight: "-2rem",
              }}
            >
              Fetch PCB Table data from Roboflow
            </Typography>
            <CancelIcon
              titleAccess="Close"
              sx={{ cursor: "pointer", margin: "0.5rem" }}
              onClick={onCloseModal}
            />
          </Box>
          <Divider
            sx={{
              borderColor: "primary.light",
              margin: "0 1rem",
              // marginBottom: "1rem",
            }}
          />
          <Box>
            {step === 1 ? (
              <BOMRoboflowDataFetchComponent
                getData={getData}
                data={data}
                step={step}
                setStep={setStep}
              />
              //  <RoboflowImageContainer step={step} setStep={setStep}/>
            ) : (
              <BOMRobotoflowFetchDataTable tableId={tableId} onCloseModal={onCloseModal} />
            )}
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
