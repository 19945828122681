// // react
// import React, { Fragment, useEffect, useState } from 'react';

import { useDispatch, useSelector } from "react-redux";
import { useRouteParams } from "../../../../../ui-reusable-component/useRouteParams";
import { ID, ObjectType } from "../../../../../utlis/commonInterfaces";
import {
  UserInput,
  userInputSelectors,
} from "../../../../../Redux/Reducers/userInput.reducer";
import { Fragment, useContext, useEffect, useState } from "react";
import { RootState } from "../../../../../Redux/Store/storeConfigurations";
import {
  CALCULATOR_DATABASES_MAP,
  CALCULATOR_RESULT_COLOR,
} from "../../../../../Configuration/Costing/calculator.constant";
import { shallowDifference } from "../../../../../utlis/other";
import { updateUserInput } from "../../../../../Redux/Actions/userInput.actions";
import { useQueryDispatch } from "../../../../../CustomHook/useQueryDispatch";
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { toExponential, toExponentialResultParam } from "../../../../../utlis/toExponential";
import calcIcon from "../../../../../Assets/images/calculator.svg";
import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined";
import ReplyIcon from "@mui/icons-material/Reply";
// import { inputCalculationSelectors } from 'app/Redux/Reducers/inputCalculation.reducer';
// // vendors
// import { useDispatch, useSelector } from 'react-redux';
// import { Button, Icon, Input, InputOnChangeData, Table } from 'semantic-ui-react';
import update from "immutability-helper";

// // services
// // utils
// import { shallowDifference } from 'app/utils/other';
// import { toExponential } from 'app/utils/toExponential';

// // constants
// import { CALCULATOR_DATABASES_MAP, CALCULATOR_RESULT_COLOR } from 'app/constants/calculator.constants';

// // factories
// // core
// import { useQueryDispatch } from 'app/Core/CustomHooks/useQueryDispatch';
// import NoData from 'app/Core/NoData';

// // redux
// import { getInputCalculations, resetInputCalculations } from 'app/Redux/Actions/inputCalculation.actions';
// import { RootState } from 'app/Redux/storeConfigurations';
// import { UserInput, userInputSelectors } from 'app/Redux/Reducers/userInput.reducer';

// import { updateUserInput } from 'app/Redux/Actions/userInput.actions';
// import { getFlaggedAssumptions, resetFlaggedAssumptions } from 'app/Redux/Actions/flaggedAssumption.actions';

// // ui-components
// // shared
// // others
// import CalculatorRow from './CalculatorRow/CalculatorRow';
// import DatabaseSelectionModal from '../DatabaseSelectionModal/DatabaseSelectionModal';
import { useDatabaseSelectionModal } from "./DatabaseSelectionModal/useDatabaseSelectionModal";
// import { useTableFieldRowSelectionModal } from '../TableFieldRowSelectionModal/useTableFieldRowSelectionModal';
// import TableFieldRowSelectionModal from '../TableFieldRowSelectionModal/TableFieldRowSelectionModal';
// import CaclulatorResultSelectionModal from '../CaclulatorResultSelectionModal/CaclulatorResultSelectionModal';
// import { useCaclulatorResultSelectionModal } from '../CaclulatorResultSelectionModal/useCaclulatorResultSelectionModal';
// import { useSelectedDatabaseRecordInfoModal } from '../SelectedDatabaseRecordInfoModal/useSelectedDatabaseRecordInfoModal';
// import SelectedDatabaseRecordInfoModal from '../SelectedDatabaseRecordInfoModal/SelectedDatabaseRecordInfoModal';
// import { useCostingReadonly } from 'app/Components/Costing/useCostingReadonly';
import { useFlaggedAssumptionFileModal } from "./FlaggedAssumptionFileModal/useFlaggedAssumptionFileModal";
// import FlaggedAssumptionFileModal from './FlaggedAssumptionFileModal/FlaggedAssumptionFileModal';

// // styles
import styles from "./Calculator.module.scss";
import { inputCalculationSelectors } from "../../../../../Redux/Reducers/inputCalculation.reducer";
import {
  getInputCalculations,
  resetInputCalculations,
} from "../../../../../Redux/Actions/inputCalculation.actions";
import CalculatorRow from "./CalculatorRow/CalculatorRow";
import { LoadingButton } from "@mui/lab";
import FlaggedAssumptionFileModal from "./FlaggedAssumptionFileModal/FlaggedAssumptionFileModal";
import {
  getFlaggedAssumptions,
  resetFlaggedAssumptions,
} from "../../../../../Redux/Actions/flaggedAssumption.actions";
import DatabaseSelectionModal from "./DatabaseSelectionModal/DatabaseSelectionModal";
import StrokeDatabaseModal from "./DatabaseSelectionModal/StrokeOptions/StrokeDatabaseModal";
import { useStrokeDatabaseModal } from "./DatabaseSelectionModal/StrokeOptions/useStrokeDatabaseModal";
import { useHistory } from "react-router-dom";
import { useTableFieldRowSelectionModal } from "./TableFieldRowSelectionModal/useTableFieldRowSelectionModal";
import TableFieldRowSelectionModal from "./TableFieldRowSelectionModal/TableFieldRowSelectionModal";
import { useSelectedDatabaseRecordInfoModal } from "./CalculatorRow/SelectedDatabaseRecordInfoModal/useSelectedDatabaseRecordInfoModal";
import SelectedDatabaseRecordInfoModal from "./CalculatorRow/SelectedDatabaseRecordInfoModal/SelectedDatabaseRecordInfoModal";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { useBaseModal } from "../../../../SearchPage/useBaseModal";
import swal from "sweetalert";
import { API } from "../../../../../api-services";
import ConstantmsgModal from "./ConstantmsgModal";
import { userPermission } from "../../CostigngCalculatorSection";
import { userSelectors } from "../../../../../Redux/Reducers/user.reducer";
import React from "react";
import DatabaseCurrencySelectionModel from "./DatabaseSelectionModal/DatabaseCurrencySelectionModel";
import InfoIcon from "@mui/icons-material/Info";
import Popover from '@mui/material/Popover';
import BOMTableCalculatorFetch from "./BOMTableCalculatorFetch";
import { useBaseParamsModal } from "../../../../../CustomHook/useBaseParamsModal";



// // interfaces and types

// import StrokeDatabaseModal from '../DatabaseSelectionModal/StrokeOptions/StrokeDatabaseModal';
// import { useStrokeDatabaseModal } from '../DatabaseSelectionModal/StrokeOptions/useStrokeDatabaseModal';
// import { useRouteParams } from 'app/Core/CustomHooks/useRouteParams';
// import { useStrokeDatabaseRecordInfoModal } from '../SelectedDatabaseRecordInfoModal/StrokeRecordInfoModal/useStrokeDatabaseModalInfo';
// import StrokeRateInfo from '../SelectedDatabaseRecordInfoModal/StrokeRecordInfoModal/StrokeRateInfo';

// lazy

// ----------------------------------------------------------------------------------
interface Param {
  projectId: ID;
}
interface CalculatorProps {
  /** active calculatorId(menu) */
  calculatorId: ID;
  CopyAliasHandler: any;
  setActive: any;
  status: any;
  handleSave?: any;
  calculators: any;
  handleChangeFinalformula: any;
  result: any;
  isWeightInput: any;
  costModelCalculatorId: any;
  itemCategory: any;

}

const Calculator: React.FC<CalculatorProps> = (props) => {
  const {
    calculatorId,
    setActive,
    handleSave,
    CopyAliasHandler,
    status,
    handleChangeFinalformula,
    result,
    calculators,
    isWeightInput,
    costModelCalculatorId,
    itemCategory,
  } = props;
  const {
    vault,
    topVault,
    projectId,
    projectname,
    productId,
    abbrebation,
    cardtype,
    tableId,
    costEstimationId,
    tableCounter,
    condition,
    calctype,
    calcultorId,
    scenarioId,
    conditionPopup
  } = useRouteParams<any>();
  const useraction = useContext<any>(userPermission);
  const [Loader, setLoader] = useState<any>(false);
  const history = useHistory<any>();
  const [MsgType, setMsgType] = useState<any>();
  const dispatch = useDispatch();
  const [CopyStatus, setCopyStatus] = useState<any>(false);
  const [Counter, setCounter] = useState<any>(0);
  const inputCalculationIds: any = useSelector(
    inputCalculationSelectors.selectIds
  );
  const [Defaultdata, setDefaultdata] = useState<any>([]);
  const [currencyDBExistCheck, setCurrencyDBExistCheck] = useState<any>();

  // popover
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const poid = open ? "simple-popover" : undefined;

  // const readonly = useCostingReadonly();

  // ----------------------------------------------------------------------------------
  // state

  const [data, setData] = useState<UserInput>();
  const [SelectedId, setSelectedId] = useState<any>([]);
  const [constantmsg, setConstantmsg] = useState<any>();
  const [statustracker, setStatustracker] = useState<any>(status);

  // ----------------------------------------------------------------------------------
  // selectors

  const userInput = useSelector((state: RootState) => {
    return userInputSelectors.selectByCalculatorId(state, calculatorId);
  });

  /** are just input ids */
  // @ts-ignore
  useEffect(() => {
    var filterdata: any = data && data?.constant_input;
    var obj: any = {};
    var datavalue: any = {};

    for (let key in filterdata) {
      if (filterdata[key] !== null) {
        obj[key] = filterdata[key];

        // console.log(`${key}: ${filterdata[key]}`);
      }
    }

    setSelectedId(data?.constant_input && Object?.keys(obj));
  }, [data, Counter]);
  // ----------------------------------------------------------------------------------
  // modal props

  const databaseModal = useDatabaseSelectionModal();
  const tableFieldRow = useTableFieldRowSelectionModal();
  // const calculatorResult = useCaclulatorResultSelectionModal();
  const databaseRecordInfo = useSelectedDatabaseRecordInfoModal();
  const flaggedAssumptionFile = useFlaggedAssumptionFileModal();
  const strokeDatabaseModal = useStrokeDatabaseModal();
  const canstantmodal = useBaseModal();
  const BOMtableCalculatorModal = useBaseParamsModal();
  const [ReplayLoader, setReplayLoader] = useState<any>(false);
  const [permissionroute, setpermissionroute] = useState<any>();
  const [calculatorData, setCalculatorData] = useState<any>();

  // const strokeDataBaseModelInfo = useStrokeDatabaseRecordInfoModal();
  // // ----------------------------------------------------------------------------------
  // callbacks

  const onSelectDatabase = (record: any) => {
    let key = "id";

    /** save machine_id, labour_id, material_id instead of mhr, lhr & materiaCost id
     * so that records need not be re-assigned on assumption card change
     */
    if (databaseModal.database === CALCULATOR_DATABASES_MAP.MACHINE)
      key = "machine_id";
    if (databaseModal.database === CALCULATOR_DATABASES_MAP.LABOUR)
      key = "labour_id";
    if (databaseModal.database === CALCULATOR_DATABASES_MAP.MATERIAL)
      key = "material_id";
    if (databaseModal.database === CALCULATOR_DATABASES_MAP.STROKE_RATE)
      key = "stroke_rate";
    if (databaseModal.database === CALCULATOR_DATABASES_MAP.CURRENCY)
      key = "RevisionCurrency";
    handleUserInputValueChangeDB(databaseModal.inputId)(record);
    setStatustracker(true);
    databaseModal.close();
    strokeDatabaseModal.close();
  };

  const onSelectTableFieldRow = (index: number) => {
    handleUserInputValueChangeDB(tableFieldRow.inputId)(index);
    tableFieldRow.close();
  };
  const userProfilePic = useSelector(userSelectors.selectAll);

  const onSelectCalculatorResult = (record: UserInput) => {
    // handleUserInputValueChange( calculatorResult.inputId )( record.id );
    // calculatorResult.close();
  };

  // ----------------------------------------------------------------------------------
  // handlers

  useEffect(() => {
    setCalculatorData(calculators && calculators?.[0]);
  }, [calculators]);

  const handleChange = (e: any) => {
    setData((prev) =>
      Object.assign({}, prev, { [e?.target?.name]: e?.target?.value })
    );
    setStatustracker(true);
  };

  React.useEffect(() => {
    API.get(
      "/auth/calibration_user_permission/",
      {
        action: true,
        configuration: true,
        vault: vault,
        scenario: scenarioId ? scenarioId : undefined,
      },
      0
    )
      .then((res: any) => {
        setpermissionroute(res?.data);
      })
      .catch((err: any) => {
        console.log("Srver Error");
      });
  }, [vault]);
  const getToggleIds = (Ids: any, value: any, currentvalue: any, type: any) => {
    setStatustracker(true);
    if (data) {
      {
        const updated = update(data, {
          //@ts-ignore
          constant_input: {
            [Ids]: { $set: type ? data.constant_input[Ids] : null },
          },
        });
        setData(updated);
      }

      if (type == true) {
        canstantmodal.open();
        setConstantmsg(Ids);
        {
          API.get("/cost/cost_constant_message/", { calculation: data?.id })
            .then((res: any) => { })
            .catch((err: any) => { });
        }
        setMsgType("msg");
      } else {
        swal({
          title: "Remove Changes?",
          text: "We will delete existing value in this field and default constant value will come in this field!",
          buttons: ["Cancel", "Yes"],
          icon: "warning",
        }).then((confirm) => {
          if (confirm) {
            API.get("/cost/cost_constant_message/", { calculation: data?.id })
              .then((res: any) => {
                var data2 = res?.data?.filter((item: any, index: any) => {
                  return item?.input_field == Ids;
                });
                API.delete(`/cost/cost_constant_message/${data2[0]?.id}/`)
                  .then((res: any) => {
                    console.log("server Error");
                  })
                  .catch((err: any) => { });
              })
              .catch((err: any) => {
                console.log("Sever error");
              });
          }

          if (confirm == null) {
            setCounter((prev: any) => prev + 1);
            // const updated = update(data, {
            //     //@ts-ignore
            //     constant_input: {
            //         [Ids]: { $set: data.constant_input[Ids] }
            //     }
            // });

            // setData(updated);
          }
        });
      }
      // @ts-ignore
    }
  };

  // var datavalue:any=data?.constant_input;
  // var obj:any={}
  // Object.keys(datavalue).forEach((key) => {

  //     if(Ids.includes(String(key))){
  //         console.log("goinggggg",ids)

  //         obj[parseInt(key)]= datavalue[key]

  //     };
  //  });
  //  Object.keys(obj)?.map((item:any)=>{
  //     console.log("hghjghj",obj,item)
  //     handleUserInputValue(item,obj[item])
  //  })

  // for (let key in obj){

  //     console.log(key,obj[key],"fbjfbjfbjfbjbjf")
  //     handleUserInputValue(key,obj[key])
  // }

  // setData(updated);
  const handleUserInputValueChange = (inputId: ID) => (value: any) => {
    setStatustracker(true);
    if (!data) return;
    const updated = update(data, {
      user_input: {
        [inputId]: { $set: value },
      },
    });

    setData(updated);
  };

  const handleUserInputValueChangeDB = (inputId: ID) => async (value: any) => {
    setStatustracker(true);
    if (!data) return;
    const updated = update(data, {
      user_input: {
        [inputId]: { $set: value },
      },
    });

    // setData(updated);
    if (!updated || !userInput) return;
    const result = shallowDifference(updated, userInput);
    var datavalue = Math.random();
    // setLoader(true);
    // update diff object
    const action: any = await dispatch(
      updateUserInput({
        id: userInput.id,
        updates: result,
      })
    );
    if (updateUserInput.fulfilled.match(action)) {
      setLoader(false);

      setStatustracker(false);

      // history.push(
      //   `/createcostingpart/${projectId}/${projectname}/${productId}/${topVault}/${vault}/${abbrebation}/${cardtype}/${tableId}/${costEstimationId}/${tableCounter}/${datavalue}/${calctype}/${calcultorId}/${scenarioId}`
      // );
      handleSave && handleSave();
    }
    if (updateUserInput.fulfilled.match(action) == false) {
      setLoader(false);
      setStatustracker(true);
    }

    // fetch updated calculations to display in ui
    const action1: any = await dispatch(
      getInputCalculations({
        userInputId: userInput.id,
      })
    );
    setActive(calculatorId);
  };

  const handleUserConstantValueChange = (inputId: ID) => (value: any) => {
    setStatustracker(true);

    if (!data) return;
    const updated = update(data, {
      constant_input: {
        [inputId]: { $set: value },
      },
    });

    setData(updated);
  };

  useEffect(() => { }, [data]);

  const getoggleconst = (Ids: any) => { };

  const handleApply = async () => {
    if (!data || !userInput) return;
    const result = shallowDifference(data, userInput);
    var datavalue = Math.random();
    setLoader(true);
    // update diff object
    const action: any = await dispatch(
      updateUserInput({
        id: userInput.id,
        updates: result,
      })
    );

    if (updateUserInput.fulfilled.match(action)) {
      setLoader(false);

      setStatustracker(false);

      handleSave && handleSave();
    }
    if (updateUserInput.fulfilled.match(action) == false) {
      setLoader(false);
      setStatustracker(true);
    }

    // fetch updated calculations to display in ui
    const action1: any = await dispatch(
      getInputCalculations({
        userInputId: userInput.id,
        get_user_input: conditionPopup && conditionPopup == "true" ? true : false
      })
    );
    setActive(calculatorId);
  };

  // ----------------------------------------------------------------------------------
  // side effects

  useQueryDispatch({
    query: {
      action: getInputCalculations,
      arg: { userInputId: userInput?.id || 0, get_user_input: conditionPopup && conditionPopup == "true" ? true : false },
      condition: Boolean(userInput),
    },
    cleanup: { action: resetInputCalculations, args: [] },
    dependency: [userInput?.id],
  });

  useQueryDispatch({
    query: {
      action: getFlaggedAssumptions,
      arg: { userInputId: userInput?.id || 0 },
      condition: Boolean(userInput),
    },
    cleanup: { action: resetFlaggedAssumptions, args: [] },
    dependency: [userInput?.id],
  });

  // sync local data w/ redux
  useEffect(() => {
    if (userInput) setData(userInput);
  }, [userInput, Counter]);

  function CopyData(value: any) {
    setStatustracker(true);

    setCopyStatus(true);
    var modifyData = `val.${value}`;

    CopyAliasHandler(modifyData);
    copyToClipboard(value);
  }

  function copyToClipboard(text: any) {
    var dummy = document.createElement("textarea");
    document.body.appendChild(dummy);
    dummy.value = text;
    dummy.select();
    dummy.setSelectionRange(0, 99999);
    /* Copy the text inside the text field */
    navigator.clipboard.writeText(dummy.value);
    document.body.removeChild(dummy);
  }

  // ----------------------------------------------------------------------------------
  // jsx abstraction
  const Infohaandler = (ids: any) => {
    setMsgType("Info");
    canstantmodal.open();
    setConstantmsg(ids);
  };

  const openFetchBOMTableCalc = (tableId: any) => {
    BOMtableCalculatorModal.open(tableId);
  }



  const CostingConfigurationRoute = () => {


    var calIds =
      data && data?.calculator?.parent == null
        ? data?.calculator?.id
        : data?.calculator?.parent;

    setReplayLoader(true);
    API.get("/config/costing_configuration/", {
      project: productId,
      calulator_id: calIds,
    })
      .then((res: any) => {
        sessionStorage.setItem(
          "Costingconfigurationnode",
          JSON?.stringify(res?.data)
        );
        setReplayLoader(false);

        window.open(
          `/#/costingconfiguration/${projectId}/${projectname}/${parseInt(
            calIds
          )}/calculator/`
        );
      })
      .catch((err: any) => {
        setReplayLoader(false);
      });
  };



  const renderRow = (inputCalculationId: ID) => {
    // if (!data) return null;

    // NOTE: inputCalculationId & inputId are same
    return (
      <CalculatorRow
        // key={ inputCalculationId }
        inputCalculationId={inputCalculationId}
        getToggleIds={getToggleIds}
        openDatabaseModal={databaseModal.open(inputCalculationId)}
        strokeDatabaseModal={strokeDatabaseModal.open(inputCalculationId)}
        openTable={tableFieldRow.open(inputCalculationId)}
        Defaultdata={Defaultdata}
        // openCalculatorResult={ calculatorResult.open( inputCalculationId ) }
        onValueChange={handleUserInputValueChange(inputCalculationId)}
        onConstValueChange={handleUserConstantValueChange(inputCalculationId)}
        userInputValue={data && data.user_input[inputCalculationId]}
        ConstantValue={data && data.constant_input[inputCalculationId]}
        constantIds={SelectedId}
        getoggleconst={getoggleconst}
        Infohaandler={Infohaandler}
        setSelectedId={setSelectedId}
        isWeightInput={isWeightInput}
        openDatabaseInfoModal={databaseRecordInfo.open}
        calculatorData={calculatorData}
        costModelCalculatorId={costModelCalculatorId}
        // openStrokeDatabaseInfoModal={ strokeDataBaseModelInfo.open }
        userInputId={data && data.id}
        openFlaggedAssumptionFileModal={flaggedAssumptionFile.open}
        setCurrencyDBExistCheck={setCurrencyDBExistCheck}
        openFetchBOMTableCalc={openFetchBOMTableCalc}
        dataOBj={data}
        handleSave={handleSave}

      />
    );
  };

 
  // ----------------------------------------------------------------------------------
  // jsx

  if (!userInput) {
    return (
      <Box
        sx={{
          marginTop: "4rem",
          width: "100%",
          textAlign: "center",
          height: { lg: "65vh", xl: "75vh" },
        }}
      >
        <img src={calcIcon} style={{ height: "5rem" }} />
        <Typography>Cost Model Not Mapped</Typography>
      </Box>
    );
  }
  // return <NoData
  //     label="No Calculators mapped/created."
  //     iconClass="none"
  //     subheading="Visit calculators page to create"
  // />;


  return (
    <Fragment>
      {databaseModal.isOpen && databaseModal.database == 8 ? (
        <DatabaseCurrencySelectionModel
          isOpen={databaseModal.isOpen}
          onClose={databaseModal.close}
          revisiondata={databaseModal?.assumption}
          database={databaseModal.database}
          onSubmit={onSelectDatabase}
          calInfo={databaseModal.CalData}
          calculatorId={calculatorId}
          userInput={userInput}
        />
      ) : (
        <DatabaseSelectionModal
          isOpen={databaseModal.isOpen}
          onClose={databaseModal.close}
          revisionId={databaseModal.revisionId}
          revisiondata={databaseModal?.assumption}
          database={databaseModal.database}
          calInfo={databaseModal.CalData}
          onSubmit={onSelectDatabase}
          calculatorId={calculatorId}
          userInput={userInput}
          itemCategory={itemCategory}
        />
      )}

      <StrokeDatabaseModal
        isOpen={strokeDatabaseModal.isOpen}
        onClose={strokeDatabaseModal.close}
        project={projectId}
        onSubmit={onSelectDatabase}
        showTable={strokeDatabaseModal.showtable}
        selectedCard={strokeDatabaseModal.selectedCard}
        type={strokeDatabaseModal.type}
        path={strokeDatabaseModal.path}
      />
      <TableFieldRowSelectionModal
        isOpen={tableFieldRow.isOpen}
        onClose={tableFieldRow.close}
        inputType={tableFieldRow.inputType}
        parameterId={tableFieldRow.parameterId}
        onSubmit={onSelectTableFieldRow}
        selectedRowIndex={data?.user_input[tableFieldRow.inputId]}
      />
      <SelectedDatabaseRecordInfoModal
        isOpen={databaseRecordInfo.isOpen}
        onClose={databaseRecordInfo.close}
        database={databaseRecordInfo.database}
        recordId={databaseRecordInfo.recordId}
        userInput={userInput}
      />
      {canstantmodal.isOpen && (
        <ConstantmsgModal
          type={MsgType}
          onCloseModal={canstantmodal.close}
          isOpen={canstantmodal.isOpen}
          Item={data?.id}
          values={parseInt(constantmsg)}
        />
      )}
      {BOMtableCalculatorModal.isOpen && <BOMTableCalculatorFetch
        onCloseModal={BOMtableCalculatorModal.close}
        isOpen={BOMtableCalculatorModal.isOpen}
        data={data}
        Item={BOMtableCalculatorModal.propsId}
        handleSave={handleSave}
      />}

      {/* <CaclulatorResultSelectionModal
                isOpen={ calculatorResult.isOpen }
                calculatorCategory={ calculatorResult.calculatorCategory }
                onClose={ calculatorResult.close }
                onSelect={ onSelectCalculatorResult }
                currentUserInputId={ userInput.id }
            />
         
            <StrokeRateInfo
                isOpen={ strokeDataBaseModelInfo.isOpen }
                onClose={ strokeDataBaseModelInfo.close }
                database={ strokeDataBaseModelInfo.database }
                recordId={ strokeDataBaseModelInfo.recordId }
            />    */}
      <FlaggedAssumptionFileModal
        isOpen={flaggedAssumptionFile.isOpen}
        onClose={flaggedAssumptionFile.close}
        flaggedAssumptionId={flaggedAssumptionFile.flaggedAssumptionId}
        userInputId={data?.id}
      />
      <div data-testid="calculator" className={styles.container}>
        <Box
          sx={{ height: { lg: "66vh", xl: "76vh" }, marginBottom: "0.1rem" }}
          adl-scrollbar="true"
          adl-scrollbar-width="0.3"
        >
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell
                  className={styles.tableHead}
                  sx={{ width: "2rem" }}
                ></TableCell>
                <TableCell className={styles.tableHead} sx={{ width: "14rem" }}>
                  Parameter
                </TableCell>
                <TableCell className={styles.tableHead} sx={{ width: "10rem" }}>
                  Value
                </TableCell>
                <TableCell className={styles.tableHead} sx={{ width: "10rem" }}>
                  {" "}
                  <p></p>
                </TableCell>
                <TableCell
                  className={styles.tableHead}
                  sx={{
                    paddingLeft: { lg: "2rem", xl: "5rem" },
                    width: "6rem",
                    justifyContent: "center",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      // justifyContent: 'flex-end',
                      width: "7rem",
                    }}
                  >
                    <span> Unit</span>
                    {((permissionroute &&
                      permissionroute?.action?.includes("C")) ||
                      (permissionroute &&
                        permissionroute?.action?.includes("D")) ||
                      (permissionroute &&
                        permissionroute?.action?.includes("U")) ||
                      (permissionroute &&
                        permissionroute?.action?.includes("R"))) && (
                        <>
                          {ReplayLoader ? (
                            <CircularProgress size={"1rem"} color="inherit" />
                          ) : (
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                marginLeft: { lg: "1rem", xl: "2rem" },
                              }}
                            >
                              <ReplyIcon
                                onClick={() => CostingConfigurationRoute()}
                                titleAccess="Go to Costing calculator"
                                sx={{
                                  fontSize: "1.5rem",
                                  transform: "scaleX(-1)",
                                  cursor: "pointer",
                                }}
                              />
                            </Box>
                          )}
                        </>
                      )}
                  </div>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{inputCalculationIds.map(renderRow)}</TableBody>
            <TableFooter
              sx={{
                backgroundColor: "primary.light",
                position: "sticky",
                bottom: "-1px",
                zIndex: "1",
              }}
            >
              <TableRow>
                {/* sx={{ color: 'primary.main', backgroundColor: CALCULATOR_RESULT_COLOR }} */}
                <TableCell className={styles.tableStyleFooter}> </TableCell>
                <TableCell className={styles.tableStyleFooter}>
                  Result
                </TableCell>
                <TableCell className={styles.tableStyleValue}>
                  <span className={styles.tableStyleFooterValue}>
                    {toExponentialResultParam(data?.result ?? 0)}
                  </span>
                </TableCell>
                <TableCell className={styles.tableStyleFooter}> </TableCell>
                <TableCell className={styles.tableStyleFooter}> </TableCell>
              </TableRow>
            </TableFooter>
          </Table>
        </Box>

        <Box
          sx={{
            width: { lg: "157%", xl: "148%" },
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            height: "2.5rem",
            padding: "0.2rem 0 ",
            marginLeft: { lg: "-57%", xl: "-48%" },
            backgroundColor: "primary.light",
          }}
        >
          <div className={styles.other}>
            <span
              style={{
                display: "flex",
                alignItems: "center",
                columnGap: "1rem",
              }}
            >
              <b>Result Parameter:</b>
              <span>
                <TextField
                  name="alias"
                  variant="standard"
                  disabled={
                    userProfilePic[0]?.is_project_admin ||
                      userProfilePic[0]?.is_superuser
                      ? false
                      : true
                  }
                  value={data?.alias || ""}
                  placeholder="Result Parameter"
                  onChange={handleChange}
                  sx={{
                    zIndex: 2,
                    width: { lg: "14rem", xl: "14rem" },
                    ".MuiInputBase-root.MuiInput-root": {
                      fontSize: "1rem !important",
                    },
                    "& .MuiInput-underline:before": {
                      borderBottomColor: "primary.main",
                    },
                    "& .MuiInput-underline:after": {
                      borderBottomColor: "primary.main",
                    },
                  }}
                />
              </span>
            </span>
          </div>

          <Box
            sx={{ flex: "1", display: "flex", justifyContent: "space-between" }}
          >
            {calculators?.length > 0 && (
              <Typography
                sx={{
                  display: "flex",
                  alignItems: "center",
                  columnGap: "1rem",
                  paddingLeft: "0.5rem",
                  zIndex: 2,
                  fontSize: "1rem",
                  width: "100%",

                }}
              >

                <IconButton
                  title="Formula Fusion: Multi-Operation Showcase"
                  sx={{ padding: "0" }}
                  aria-describedby={poid}
                  onClick={handleClick}
                >
                  <InfoIcon sx={{ color: "primary.main" }} />
                </IconButton>
                <Popover
                  id={poid}
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  transformOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  disableRestoreFocus
                  sx={{
                    ".MuiPaper-root.MuiPopover-paper": {
                      borderRadius: "1rem",
                      //   backgroundColor:'primary.light'
                    },
                  }}
                >
                  <Typography
                    sx={{
                      width: "100%",
                      textAlign: "center",
                      fontSize: "1rem",
                      padding: "0.5rem",
                      fontWeight: "600",
                      backgroundColor: "#ddefff",
                    }}
                  >
                    Formula Fusion: Multi-Operation Showcase
                  </Typography>
                  <Divider sx={{ borderColor: "primary.light" }} />
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "flex-start",
                      columnGap: "0.5rem",
                      padding: "0.5rem",
                    }}
                  >
                    <Table>
                      <TableBody>
                        <TableRow>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                              whiteSpace: "nowrap",
                              backgroundColor: "#edfbee",
                            }}
                          >
                            1
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                              whiteSpace: "nowrap",
                              backgroundColor: "#fffaea",
                            }}
                          >
                            val.a + val.b
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                              whiteSpace: "nowrap",
                              backgroundColor: "#edfbee",
                            }}
                          >
                            Addition
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                              whiteSpace: "nowrap",
                              backgroundColor: "#edfbee",
                            }}
                          >
                            2
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                              whiteSpace: "nowrap",
                              backgroundColor: "#fffaea",
                            }}
                          >
                            val.a - val.b
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                              whiteSpace: "nowrap",
                              backgroundColor: "#edfbee",
                            }}
                          >
                            Subtraction
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                              whiteSpace: "nowrap",
                              backgroundColor: "#edfbee",
                            }}
                          >
                            3
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                              whiteSpace: "nowrap",
                              backgroundColor: "#fffaea",
                            }}
                          >
                            val.a * val.b
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                              whiteSpace: "nowrap",
                              backgroundColor: "#edfbee",
                            }}
                          >
                            Multiplication
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                              whiteSpace: "nowrap",
                              backgroundColor: "#edfbee",
                            }}
                          >
                            4
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                              whiteSpace: "nowrap",
                              backgroundColor: "#fffaea",
                            }}
                          >
                            val.a / val.b
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                              whiteSpace: "nowrap",
                              backgroundColor: "#edfbee",
                            }}
                          >
                            Division
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                              whiteSpace: "nowrap",
                              backgroundColor: "#edfbee",
                            }}
                          >
                            5
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                              whiteSpace: "nowrap",
                              backgroundColor: "#fffaea",
                            }}
                          >
                            val.a ** val.b
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                              whiteSpace: "nowrap",
                              backgroundColor: "#edfbee",
                            }}
                          >
                            Exponentiation
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                              whiteSpace: "nowrap",
                              backgroundColor: "#edfbee",
                            }}
                          >
                            6
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                              whiteSpace: "nowrap",
                              backgroundColor: "#fffaea",
                            }}
                          >
                            val.a % val.b
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                              whiteSpace: "nowrap",
                              backgroundColor: "#edfbee",
                            }}
                          >
                            Modulo
                          </TableCell>
                        </TableRow>
                        {/* <TableRow>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                              whiteSpace: "nowrap",
                              backgroundColor: "#edfbee",
                            }}
                          >
                            7
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                              whiteSpace: "nowrap",
                              backgroundColor: "#fffaea",
                            }}
                          >
                            val.a and val.b
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                              whiteSpace: "nowrap",
                              backgroundColor: "#edfbee",
                            }}
                          >
                            AND
                          </TableCell>
                        </TableRow> */}
                        <TableRow>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                              whiteSpace: "nowrap",
                              backgroundColor: "#edfbee",
                            }}
                          >
                            8
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                              whiteSpace: "nowrap",
                              backgroundColor: "#fffaea",
                            }}
                          >
                            val.a or val.b
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                              whiteSpace: "nowrap",
                              backgroundColor: "#edfbee",
                            }}
                          >
                            OR (first nonzero value will be shown)
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                              whiteSpace: "nowrap",
                              backgroundColor: "#edfbee",
                            }}
                          >
                            9
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                              whiteSpace: "nowrap",
                              backgroundColor: "#fffaea",
                            }}
                          >
                            not val.a
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                              whiteSpace: "nowrap",
                              backgroundColor: "#edfbee",
                            }}
                          >
                            NOT
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                              whiteSpace: "nowrap",
                              backgroundColor: "#edfbee",
                            }}
                          >
                            10
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                              whiteSpace: "nowrap",
                              backgroundColor: "#fffaea",
                            }}
                          >
                            val.a == val.b
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                              whiteSpace: "nowrap",
                              backgroundColor: "#edfbee",
                            }}
                          >
                            Equal
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                              whiteSpace: "nowrap",
                              backgroundColor: "#edfbee",
                            }}
                          >
                            11
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                              whiteSpace: "nowrap",
                              backgroundColor: "#fffaea",
                            }}
                          >
                            val.a != val.b
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                              whiteSpace: "nowrap",
                              backgroundColor: "#edfbee",
                            }}
                          >
                            Not equal
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                              whiteSpace: "nowrap",
                              backgroundColor: "#edfbee",
                            }}
                          >
                            12
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                              whiteSpace: "nowrap",
                              backgroundColor: "#fffaea",
                            }}
                          >
                            val.a {">"} val.b
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                              whiteSpace: "nowrap",
                              backgroundColor: "#edfbee",
                            }}
                          >
                            Greater than
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                              whiteSpace: "nowrap",
                              backgroundColor: "#edfbee",
                            }}
                          >
                            13
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                              whiteSpace: "nowrap",
                              backgroundColor: "#fffaea",
                            }}
                          >
                            val.a {"<"} val.b
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                              whiteSpace: "nowrap",
                              backgroundColor: "#edfbee",
                            }}
                          >
                            Less than
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                    <Divider
                      sx={{ borderColor: "primary.light" }}
                      orientation="vertical"
                      variant="middle"
                      flexItem
                    />
                    <Table>
                      <TableBody>
                        <TableRow>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                              whiteSpace: "nowrap",
                              backgroundColor: "#edfbee",
                            }}
                          >
                            14
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                              whiteSpace: "nowrap",
                              backgroundColor: "#fffaea",
                            }}
                          >
                            val.a {">"}= val.b
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                              whiteSpace: "nowrap",
                              backgroundColor: "#edfbee",
                            }}
                          >
                            Greater than or equal to
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                              whiteSpace: "nowrap",
                              backgroundColor: "#edfbee",
                            }}
                          >
                            15
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                              whiteSpace: "nowrap",
                              backgroundColor: "#fffaea",
                            }}
                          >
                            val.a {"<"}= val.b
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                              whiteSpace: "nowrap",
                              backgroundColor: "#edfbee",
                            }}
                          >
                            Less than or equal to
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                              whiteSpace: "nowrap",
                              backgroundColor: "#edfbee",
                            }}
                          >
                            16
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                              whiteSpace: "nowrap",
                              backgroundColor: "#fffaea",
                            }}
                          >
                            val.a + val.b if val.a {">"} val.b else val.a -
                            val.b
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                              whiteSpace: "nowrap",
                              backgroundColor: "#edfbee",
                            }}
                          >
                            If and else condition
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                              whiteSpace: "nowrap",
                              backgroundColor: "#edfbee",
                            }}
                          >
                            17
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                              whiteSpace: "nowrap",
                              backgroundColor: "#fffaea",
                            }}
                          >
                            min(val.a, val.b, val.c, val.d)
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                              whiteSpace: "nowrap",
                              backgroundColor: "#edfbee",
                            }}
                          >
                            min
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                              whiteSpace: "nowrap",
                              backgroundColor: "#edfbee",
                            }}
                          >
                            18
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                              whiteSpace: "nowrap",
                              backgroundColor: "#fffaea",
                            }}
                          >
                            max(val.a, val.b, val.c, val.d)
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                              whiteSpace: "nowrap",
                              backgroundColor: "#edfbee",
                            }}
                          >
                            max
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                              whiteSpace: "nowrap",
                              backgroundColor: "#edfbee",
                            }}
                          >
                            19
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                              whiteSpace: "nowrap",
                              backgroundColor: "#fffaea",
                            }}
                          >
                            round(val.a, 2)
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                              whiteSpace: "nowrap",
                              backgroundColor: "#edfbee",
                            }}
                          >
                            round
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                              whiteSpace: "nowrap",
                              backgroundColor: "#edfbee",
                            }}
                          >
                            20
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                              whiteSpace: "nowrap",
                              backgroundColor: "#fffaea",
                            }}
                          >
                            math.floor(val.a)
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                              whiteSpace: "nowrap",
                              backgroundColor: "#edfbee",
                            }}
                          >
                            rounded down
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                              whiteSpace: "nowrap",
                              backgroundColor: "#edfbee",
                            }}
                          >
                            21
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                              whiteSpace: "nowrap",
                              backgroundColor: "#fffaea",
                            }}
                          >
                            math.ceil(val.a)
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                              whiteSpace: "nowrap",
                              backgroundColor: "#edfbee",
                            }}
                          >
                            rounded up
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                              whiteSpace: "nowrap",
                              backgroundColor: "#edfbee",
                            }}
                          >
                            22
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                              whiteSpace: "nowrap",
                              backgroundColor: "#fffaea",
                            }}
                          >
                            math.sqrt(val.a)
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                              whiteSpace: "nowrap",
                              backgroundColor: "#edfbee",
                            }}
                          >
                            Square root
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                              whiteSpace: "nowrap",
                              backgroundColor: "#edfbee",
                            }}
                          >
                            23
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                              whiteSpace: "nowrap",
                              backgroundColor: "#fffaea",
                            }}
                          >
                            math.sin(val.a)<sup style={{ color: 'red' }}>**</sup>
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                              whiteSpace: "nowrap",
                              backgroundColor: "#edfbee",
                            }}
                          >
                            Sine function
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                              whiteSpace: "nowrap",
                              backgroundColor: "#edfbee",
                            }}
                          >
                            24
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                              whiteSpace: "nowrap",
                              backgroundColor: "#fffaea",
                            }}
                          >
                            math.cos(val.a)<sup style={{ color: 'red' }}>**</sup>
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                              whiteSpace: "nowrap",
                              backgroundColor: "#edfbee",
                            }}
                          >
                            Cosine function
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                              whiteSpace: "nowrap",
                              backgroundColor: "#edfbee",
                            }}
                          >
                            25
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                              whiteSpace: "nowrap",
                              backgroundColor: "#fffaea",
                            }}
                          >
                            math.log(val.a)
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                              whiteSpace: "nowrap",
                              backgroundColor: "#edfbee",
                            }}
                          >
                            Natural logarithm
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell colSpan={3} sx={{ padding: "0.2rem 0.5rem", borderBottomColor: "primary.light", whiteSpace: "nowrap", backgroundColor: "#edfbee", }}>
                            <span style={{ color: 'red' }}>Note: </span> <sup style={{ color: 'red' }}>**</sup> The values entered should be in radians.
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </Box>
                </Popover>
                <b>Final Formula:</b>

                <TextField
                  className={styles.input}
                  disabled={
                    (useraction && useraction?.action?.includes("C")) ||
                      (useraction && useraction?.action?.includes("U"))
                      ? false
                      : true
                  }
                  placeholder="Final Formula"
                  value={result}
                  variant="standard"
                  onChange={(e: any) => handleChangeFinalformula(e)}
                  sx={{
                    ".MuiInputBase-root.MuiInput-root": {
                      fontSize: "1rem !important",
                    },
                    "& .MuiInput-underline:before": {
                      borderBottomColor: "primary.main",
                    },
                    "& .MuiInput-underline:after": {
                      borderBottomColor: "primary.main",
                    },

                    flexGrow: 1,

                  }}
                />

              </Typography>
            )}

            <div style={{ display: "flex" }}>
              {((useraction && useraction?.action?.includes("C")) ||
                (useraction && useraction?.action?.includes("U"))) && (
                  <IconButton
                    title="Copy Alias"
                    size="small"
                    sx={{ color: "primary.main", zIndex: 1 }}
                    onClick={() => CopyData(data?.alias)}
                  >
                    <FileCopyOutlinedIcon
                      sx={{ cursor: "pointer", fontSize: "1.5rem" }}
                    />
                  </IconButton>
                )}

              {((useraction && useraction?.action?.includes("C")) ||
                (useraction && useraction?.action?.includes("U"))) && (
                  <LoadingButton
                    disabled={
                      statustracker == false && status == false ? true : false
                    }
                    loading={Loader}
                    size="small"
                    variant="contained"
                    sx={{
                      cursor: "pointer",
                      marginRight: "0.5rem",
                      "&:hover": {
                        transform: "Scale(1.05)",
                        transition: "transform 0.5s ease",
                      },
                    }}
                    onClick={handleApply}
                  >
                    Apply
                  </LoadingButton>
                )}
            </div>
          </Box>
        </Box>

      </div>
    </Fragment>
  );
};

// ----------------------------------------------------------------------------------

export default Calculator;




