import * as React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Box,
  Typography,
  Skeleton,
  ButtonProps,
  Tooltip,
} from "@mui/material";
import RViewerJS from "viewerjs-react";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import FlagIcon from "@mui/icons-material/Flag";
import flagreen from "../../../Assets/images/flag.png";
import { useRouteParams } from "../../../ui-reusable-component/useRouteParams";
import { API } from "../../../api-services";
import Image from "../../../ViewModule/FeatureLandingPage/ArchitectureImageCard";
import { DataGrid } from "@mui/x-data-grid/DataGrid/DataGrid";
import successIcon from "../../../Assets/images/success.png";
import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined";
import {
  GridCsvExportMenuItem,
  GridCsvExportOptions,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarExportContainer,
} from "@mui/x-data-grid";
import BOMDataFlagModal from "./BOMDataFlagModal";
import { useBaseModal } from "../../SearchPage/useBaseModal";
import Swal from "sweetalert2";

export interface IBOMDrawerTableProps {
  TabStatus: any;
  imageData: any;
  TablelistData: any;
  ParameterData: any;
  setParameterDaa?: any;
  setTablelistData?: any;
  setImageData?: any;
  state?: any;
}
const csvOptions: GridCsvExportOptions = {
  fileName: "BOM module CSV file",
  delimiter: ",",
  utf8WithBom: true,
};

const CustomExportButton = (props: ButtonProps) => (
  <GridToolbarExportContainer {...props}>
    <GridCsvExportMenuItem options={csvOptions} />
  </GridToolbarExportContainer>
);

const CustomToolbar = () => (
  <GridToolbarContainer style={{ height: "2rem" }}>
    <span style={{ position: "absolute", right: "0rem" }}>
      <GridToolbarColumnsButton />
      {/* <GridToolbarDensitySelector /> */}
      <CustomExportButton />
    </span>
  </GridToolbarContainer>
);

export function BOMDrawerTable(props: IBOMDrawerTableProps) {
  const {
    TabStatus,
    imageData,
    TablelistData,
    setParameterDaa,
    setTablelistData,
    setImageData,
    ParameterData,
    state,
  } = props;
  const { vault } = useRouteParams<any>();


  // const [TablelistData, setTablelistData] = React.useState<any>([]);
  // const [ParameterData, setParameterDaa] = React.useState<any>([]);
  // const [imageData, setImageData] = React.useState<any>([]);
  const [TableLoader, setTableLoader] = React.useState<any>(false);
  const [imagesLoader, setImagesLoader] = React.useState<any>(false);
  const [SigmaData, setSigmaData] = React.useState<any>({});
  const [flagData, setFlagdata] = React.useState<any>([]);
  const [commentsLoader, setCommentsLoader] = React.useState<any>(false);
  const [isLoaded, setIsLoaded] = React.useState<boolean>(false);
  const [open, setOpen] = React.useState();

  const flagModal = useBaseModal();
  const getParameterData = () => {
    setTableLoader(true);
    API.get("/xcpep/cost_bom_data/", { part: true, vault: vault }, 0)
      .then((res: any) => {
        setParameterDaa(res.data);
        setTableLoader(false);
      })
      .catch(() => {
        setTableLoader(false);
      });
  };

  const getTableDataa = () => {
    setTableLoader(true);
    API.get("/xcpep/cost_bom_data/", { table: true, vault: vault }, 0)
      .then((res: any) => {
        setTableLoader(false);
        setTablelistData(res.data);
      })
      .catch(() => {
        setTableLoader(false);
      });
  };

  const getImageData = () => {
    setImagesLoader(true);
    API.get("/xcpep/cost_bom_data/", { image: true, vault: vault }, 0)
      .then((res: any) => {
        setImagesLoader(false);
        setImageData(res.data?.image_details);
      })
      .catch(() => {
        setImagesLoader(false);
      });
  };

  React.useEffect(() => {
    if (state?.left) {
      if (TabStatus === "Parameters") {
        getParameterData();
      } else if (TabStatus === "Table") {
        getTableDataa();
      } else if (TabStatus === "Images") {
        
      }
    }
    // getParameterData();
    // getTableDataa();
    getImageData();
  }, [vault, state,TabStatus]);
  // React.useEffect(() => {
  //   setTableLoader(true);
  //   if (TabStatus == "Parameters") {
  //     setTableLoader(true);
  //     API.get("/xcpep/cost_bom_data/", { part: true, vault: vault }, 0)
  //       .then((res: any) => {
  //         // console.log(res.data, "vhdvbjdvbjdv");
  //         setParameterDaa(res.data);
  //         setTableLoader(false);
  //       })
  //       .catch((err: any) => {
  //         setTableLoader(false);
  //       });
  //   } else if (TabStatus == "Table") {
  //     setTableLoader(true);
  //     API.get("/xcpep/cost_bom_data/", { table: true, vault: vault }, 0)
  //       .then((res: any) => {
  //         // console.log(res.data, "vhdvbjdvbjdv");
  //         setTableLoader(false);
  //         setTablelistData(res.data);
  //       })
  //       .catch((err: any) => {
  //         setTableLoader(false);
  //       });
  //   } else {
  //     setImagesLoader(true);
  //     API.get("/xcpep/cost_bom_data/", { image: true, vault: vault }, 0)
  //       .then((res: any) => {
  //         // console.log(res.data, "vhdvbjdvbjdv");
  //         setImagesLoader(false);
  //         setImageData(res.data?.image_details);
  //       })
  //       .catch((err: any) => {
  //         setImagesLoader(false);
  //       });
  //   }
  // }, [TabStatus, vault]);

  function CopyData(value: any) {
    setOpen(value?.param_id);
    // console.log(value, "valuevalue");
    copyToClipboard(value?.value);
  }

  function copyToClipboard(text: any) {
    var dummy = document.createElement("textarea");
    document.body.appendChild(dummy);
    dummy.value = text;
    dummy.select();
    dummy.setSelectionRange(0, 99999);
    /* Copy the text inside the text field */
    navigator.clipboard.writeText(dummy.value);
    document.body.removeChild(dummy);
  }

  const FlagHandler = (value: any) => {
    setFlagdata(value);
    flagModal.open();
  };
  const PcbTableHeader = (HeaderData: any) => {
    var valuedata: any =
      HeaderData &&
      HeaderData?.map((key: any) => {
        return {
          field: key?.name,
          flex: 1,

          align: key?.data_type === "int" && "left",
          headerAlign: key?.data_type === "int" && "left",
          type: key?.data_type === "int" ? "number" : "string",
          //  headerAlign: 'center',
          // type: 'string',
          disableExport: false,
          headerName:
            SigmaData && Object.keys(SigmaData)?.includes(key?.name)
              ? `${key?.name} (${SigmaData[key?.name]}) `
              : key?.name,
          //   headerClassName: styles.Dataheader,

          renderCell: (params: any) => {
            return (
              <>
                <span>{params.row[key?.name]}</span>
              </>
            );
            // if (key?.data_type == "image") {
            //   return (
            //     <CameraAltIcon
            //       style={{ cursor: "pointer" }}
            //     //   onClick={() => {
            //     //     handleClick(params.row[key?.name]);
            //     //   }}
            //     />
            //   );
            // }
            //  else {
            //   return <span>{params.row[key?.name]}</span>;
            // }
          },
        };
      });
    return valuedata;
  };

  const PcbTableRow = (Rowdata: any) => {
    var valuedata =
      Rowdata &&
      Rowdata.map((item: any, id: any) => ({
        ...item,
        id: id + 1,
      }));

    return valuedata;
  };

  const flagonchangeHanlder = (event: any, item: any) => {
    if (TabStatus == "Parameters") {
      setCommentsLoader(true);
      {
        API.put(
          `/xcpep/cost_bom_data/`,
          {
            vault: vault,
            parameter_id: item,
            flag: event,
          },
          {},
          0
        )
          .then(() => {
            flagModal?.close();
            setCommentsLoader(false);
            Swal.fire({
              html: `<div><img src="${successIcon}" alt="Custom Image" style="width: 7rem; height: auto;" />
      <br />
      <p style="color:#007fff;"> Comments updated successfully
      </p>   
       </div>`,
            });
            API.get("/xcpep/cost_bom_data/", { part: true, vault: vault }, 0)
              .then((res1: any) => {
                setParameterDaa(res1.data);
                setTableLoader(false);
              })
              .catch(() => {
                setTableLoader(false);
              });
          })
          .catch(() => {
            setCommentsLoader(false);
          });
      }
    } else {
      setCommentsLoader(true);
      API.put(
        `/xcpep/cost_bom_data/`,
        {
          vault: vault,
          parameter_id: item,
          flag: event,
        },
        {},
        0
      )
        .then((res: any) => {
          flagModal?.close();
          setCommentsLoader(false);
          Swal.fire({
            html: `<div><img src="${successIcon}" alt="Custom Image" style="width: 7rem; height: auto;" />
    <br />
    <p style="color:#007fff;"> Comments updated successfully
    </p>   
     </div>`,
          });
          API.get("/xcpep/cost_bom_data/", { table: true, vault: vault }, 0)
            .then((res1: any) => {
              setTableLoader(false);
              setTablelistData(res1.data);
            })
            .catch(() => {
              setTableLoader(false);
            });
        })
        .catch(() => {
          setCommentsLoader(false);
        });
    }
  };

  return (
    <Box
      sx={{ height: { lg: "95vh", xl: "96vh" }, border: "1px solid" }}
      adl-scrollbar="true"
      adl-scrollbar-width="0.3"
    >
      {flagModal?.isOpen && (
        <BOMDataFlagModal
          isOpen={flagModal?.isOpen}
          handleClose={flagModal?.close}
          Item={flagData}
          TabStatus={TabStatus}
          loader={commentsLoader}
          callbackHandler={flagonchangeHanlder}
        />
      )}
      {TabStatus == "Parameters" ? (
        TableLoader ? (
          <>
            <Skeleton
              sx={{
                width: "70vw",
                height: "3rem",
                marginLeft: "1.2rem",
              }}
            />
            <Skeleton
              sx={{
                width: "70vw",
                height: "3rem",
                marginLeft: "1.2rem",
              }}
            />
            <Skeleton
              sx={{
                width: "70vw",
                height: "3rem",
                marginLeft: "1.2rem",
              }}
            />
            <Skeleton
              sx={{
                width: "70vw",
                height: "3rem",
                marginLeft: "1.2rem",
              }}
            />
          </>
        ) : (
          <>
            <Table>
              <TableHead
                sx={{
                  position: "sticky",
                  top: "0",
                  backgroundColor: "primary.light",
                  zIndex: 1,
                }}
              >
                <TableRow>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    Parameter Name
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    Value
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    Unit
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {ParameterData &&
                  ParameterData?.part_details?.map(
                    (item: any, index: number) => {
                      return (
                        <>
                          <TableRow>
                            <TableCell
                              sx={{
                                padding: "0.2rem 0.5rem",
                                borderBottomColor: "primary.light",
                              }}
                            >
                              {item?.paramter_name}
                            </TableCell>
                            <TableCell
                              sx={{
                                position: "relative !important",
                                padding: "0.2rem 0.5rem",
                                borderBottomColor: "primary.light",
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  position: "relative !important",
                                  gap: "0.5rem",
                                }}
                              >
                                <Tooltip
                                  title={
                                    item?.param_id == open
                                      ? "Copied"
                                      : "Click to copy"
                                  }
                                >
                                  {item?.param_id == open ? (
                                    <FileCopyIcon sx={{ fontSize: "1.2rem" }} />
                                  ) : (
                                    <>
                                      <FileCopyOutlinedIcon
                                        onClick={(e: any) => {
                                          CopyData(item);
                                          //   handleClick(e, "right");
                                        }}
                                        sx={{
                                          fontSize: "1.2rem",
                                          cursor: "pointer",
                                        }}
                                      />
                                    </>
                                  )}
                                </Tooltip>
                                {item?.value}{" "}
                              </Box>
                            </TableCell>
                            <TableCell
                              sx={{
                                padding: "0.2rem 0.5rem",
                                borderBottomColor: "primary.light",
                              }}
                            >
                              {item?.unit}
                            </TableCell>
                            <TableCell
                              sx={{
                                padding: "0.2rem 0.5rem",
                                borderBottomColor: "primary.light",
                              }}
                            >
                              {item?.flag !== null ? (
                                <FlagIcon
                                  titleAccess="Flog info"
                                  style={{
                                    height: "1.5rem",
                                    cursor: "pointer",
                                    color: "green",
                                  }}
                                  onClick={() => FlagHandler(item)}
                                />
                              ) : (
                                <img
                                  title="Flag info"
                                  onClick={() => FlagHandler(item)}
                                  src={flagreen}
                                  style={{
                                    height: "1.5rem",
                                    cursor: "pointer",
                                  }}
                                />
                              )}
                            </TableCell>
                          </TableRow>
                        </>
                      );
                    }
                  )}
              </TableBody>
            </Table>
          </>
        )
      ) : TabStatus == "Table" ? (
        <>
          {TableLoader ? (
            <>
              <Skeleton
                sx={{
                  width: "70vw",
                  height: "3rem",
                  marginLeft: "1.2rem",
                }}
              />
              <Skeleton
                sx={{
                  width: "70vw",
                  height: "3rem",
                  marginLeft: "1.2rem",
                }}
              />
              <Skeleton
                sx={{
                  width: "70vw",
                  height: "3rem",
                  marginLeft: "1.2rem",
                }}
              />
              <Skeleton
                sx={{
                  width: "70vw",
                  height: "3rem",
                  marginLeft: "1.2rem",
                }}
              />
            </>
          ) : TablelistData && TablelistData?.length == 0 ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                marginTop: "30rem",
              }}
            >
              <Typography sx={{ fontSize: "1.5rem" }}>No Data</Typography>
            </Box>
          ) : (
            TablelistData &&
            TablelistData?.map((tableItem: any) => {
              return (
                <>
                  {
                    <>
                      <Box
                        sx={{
                          display: "flex",
                          columnGap: "1rem",
                          alignItems: "center",
                          padding: "0.2rem 1rem",
                          width: "50%",
                          zIndex: "1000",
                          position: "relative",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            gap: "0.3rem",
                          }}
                        >
                          <Typography
                            sx={{ fontSize: "1rem", marginLeft: "1rem" }}
                          >
                            {" "}
                            {tableItem?.table_name}
                          </Typography>
                          <img
                            title="Flag info"
                            //

                            onClick={() => FlagHandler(tableItem)}
                            src={flagreen}
                            style={{ height: "1.3rem", cursor: "pointer" }}
                          />
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          width: "100%",
                          padding: "0 1rem",
                          marginTop: "-2rem",
                        }}
                      >
                        {/* height: tableItem?.data.length == 1 ? 200 : 337, */}
                        <DataGrid
                          headerHeight={48}
                          rowHeight={36}
                          rows={PcbTableRow(tableItem?.data)}
                          columns={PcbTableHeader(
                            tableItem && tableItem?.header_item
                          )}
                          // pageSize={5}
                          // rowsPerPageOptions={[5]}
                          sx={{
                            fontSize: "12px",
                            borderColor: "primary.light",
                            "&.MuiDataGrid-root.MuiDataGrid-autoHeight .MuiDataGrid-row--lastVisible .MuiDataGrid-cell":
                              {
                                borderBottomColor: "primary.light",
                              },
                            "&.MuiDataGrid-root .MuiDataGrid-cell": {
                              borderBottomColor: "primary.light",
                            },
                            "& .MuiDataGrid-root.MuiDataGrid-autoHeight.MuiDataGrid-root--densityCompact.MuiDataGrid-root":
                              {
                                border: "none !important",
                              },
                          }}
                          density="compact"
                          // checkboxSelection
                          // @ts-ignore
                          // components={{ Toolbar: GridToolbar }}
                          components={{
                            Toolbar: () => {
                              return CustomToolbar();
                            },
                          }}
                          autoHeight={true}
                          disableSelectionOnClick
                          hideFooterPagination={true}
                          hideFooter={true}
                          // experimentalFeatures={{ newEditingApi: true }}
                        />
                      </Box>
                    </>
                  }
                </>
              );
            })
          )}
        </>
      ) : (
        <Box sx={{ padding: "0.5rem", height: "100%" }}>
          {imagesLoader ? (
            <>
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: "repeat(auto-fit, minmax(10vw, 1fr))",
                  gap: "1rem",
                  width: "100%",
                  height: "fit-content",
                }}
              >
                <Skeleton sx={{ height: "6rem", width: "100%" }} />
                <Skeleton sx={{ height: "6rem", width: "100%" }} />
                <Skeleton sx={{ height: "6rem", width: "100%" }} />
                <Skeleton sx={{ height: "6rem", width: "100%" }} />
                <Skeleton sx={{ height: "6rem", width: "100%" }} />
                <Skeleton sx={{ height: "6rem", width: "100%" }} />
                <Skeleton sx={{ height: "6rem", width: "100%" }} />
                <Skeleton sx={{ height: "6rem", width: "100%" }} />
              </Box>
            </>
          ) : (
            <>
              {imageData?.length > 0 ? (
                <>
                  {/* Initialize RViewerJS with all the image URLs */}
                  {/* @ts-ignore */}
                  <RViewerJS
                    imageUrls={imageData?.map((item: any) => item?.value)}
                  >
                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "row", // Ensures horizontal layout
                        gap: "1rem",
                        height: "auto", // Adjust height to fit the content
                        alignItems: "center", // Centers images vertically
                      }}
                    >
                      <Box
                        sx={{
                          display: "grid",
                          gridTemplateColumns:
                            "repeat(auto-fit, minmax(10vw, 1fr))",
                          gap: "1rem",
                          width: "100%",
                          height: "100%",
                        }}
                      >
                        {imageData?.map((item: any, index: number) => {
                          return (
                            <Box
                              key={index}
                              sx={{
                                cursor: "pointer",
                                width: "auto", // Set a minimum width for each image container
                                height: "auto", // Ensure height is consistent across images
                              }}
                            >
                              <Image
                                src={item?.value}
                                style={{
                                  width: "100%",
                                  height: "100%",
                                  objectFit: "contain",
                                  borderRadius: "5px",
                                  cursor: "pointer",
                                  //boxShadow: "-2px 2px 3px -8px rgba(0,0,0,0.1)",

                                  // display: index + 1 > 4 ? "none" : "block",
                                }}
                              />
                            </Box>
                          );
                        })}
                      </Box>
                    </Box>
                  </RViewerJS>
                </>
              ) : (
                <>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      height: "100%",
                      width: "100%",
                    }}
                  >
                    No Images
                  </Box>
                </>
              )}
            </>
          )}
        </Box>
      )}
    </Box>
  );
}
