import * as React from 'react';
import { Box, Checkbox, IconButton, Typography } from '@mui/material';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { API } from '../../api-services';
import { useRouteParams } from '../../ui-reusable-component/useRouteParams';
import warningImg from "../../Assets/images/Warning.png";
interface ICostingConfigurationDrawerProps {
}
function CostingConfigurationDrawer(props: ICostingConfigurationDrawerProps) {
    const history = useHistory();
    const { url } = useRouteMatch();
    const { projectId, projectname } = useRouteParams<any>();
    // const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
    const [ConfigData, setConfigData] = React.useState<any>()

    const [checkCosting, setCheckCosting] = React.useState<any>(false);
    // const [state, setState] = React.useState({
    //     top: false,
    //     left: false,
    //     bottom: false,
    //     right: false,
    // });
    // const handleClick = () => {
    //     history.goBack();
    // };
    // const costingModel = () => {
    //     history.push(`/costingconfiguration/${projectId}/${projectname}/0/0`);
    // };
    // const commodityHandle = () => {
    //     history.push(`/costingconfiguration/${projectId}/${projectname}/0/commodity`);
    // };
    // const guideHandler = () => {
    //     history.push(`/costingconfiguration/${projectId}/${projectname}/guidelines`);
    // };
    // const assumptionHandler = () => {
    //     history.push(`/costingconfiguration/${projectId}/${projectname}/assumptioncard/0`);
    // };
    const comapnionHandle = () => {
        API.get("/web_companion/comapanion_activity_log", {
            //   get_recycle_bin_data_count: true
            project: projectId, viewed: true
        }, 0).then((res: any) => {
            // console.log(res?.data.count, "countdata")
        }).catch((err: any) => {
            // console.log("Server error")
            //   SetLoader(false)
        })
        history.push(`/companionConfig/${projectId}/${projectname}`);
    };

    React.useEffect(() => {

        API.get("auth/calibration_user_permission/", { project: projectId, allconfig: true }).then((res: any) => {
            setConfigData(res?.data)
        }).catch((err: any) => {
            console.log("Sever Error")
        })
    }, [projectId])





    React.useEffect(() => {
        if (url.includes("bomconfiguration") || url.includes("customValidation")) {
            setCheckCosting(true);
        }
    }, [url])


    const TemplateHandler = () => {

        API.get("/xcpep/product_list/", { first_config_object: true, project: projectId }).then((res: any) => {
            if (res?.data?.exists == true) {
                history.push(`/bomconfiguration/${projectId}/${projectname}/${res?.data?.product_id}/${res?.data?.category_id}/${res?.data?.category_name}/${res?.data?.product_id}/0`)
            } else {
                history.push(`/bomconfiguration/${projectId}/${projectname}/${res?.data?.product_id}/0/0/0/0`)
            }
        })


    }

    const FeatureHandler = () => {
        sessionStorage.setItem('expandedAccordionFeature', '0');
        API.get("/xcpep/product_list/", { first_config_object: true, project: projectId, feature_config_first: true }).then((res: any) => {
            if (res?.data?.exists == true) {
                history.push(`/feature-configuration/${projectId}/${projectname}/${res?.data?.id}/${res?.data?.category_id}/${res?.data?.category_name}/0/1`)
            } else {
                history.push(`/feature-configuration/${projectId}/${projectname}/0/0/0/0/1`)
            }
        })


    }


    const IdeaHandler = () => {
        API.get("/config/parameter_types/", { module: 14, project: projectId, idea_first_datatype: true }).then((res: any) => {
            history.push(`/ideaconfiguration/${projectId}/${projectname}/${res?.data?.data_type}`)
        })
    }

    return (
        <div>
            <Box sx={{ padding: '0 0.5rem' }}>
                {ConfigData && ConfigData?.bom_config == true &&
                    <Box sx={{ alignItems: "center", display: "flex" }} >
                        <Checkbox
                            style={{ padding: '3px' }}
                            checked={checkCosting || url.includes("bomconfiguration") || url.includes("customValidation")}
                            onChange={() => setCheckCosting((prev: any) => !prev)}
                            // checked={url.includes("bomconfiguration")}
                            // onClick={() => history.push(`/bomconfiguration/${projectId}/${projectname}/0/0/0/0/0`,)}
                            inputProps={{ "aria-label": "controlled" }}
                            sx={{ "& .MuiSvgIcon-root": { fontSize: { lg: '24', xl: '30' } } }}
                        />
                        <Box
                            // key={index}
                            onClick={() => setCheckCosting((prev: any) => !prev)}
                            sx={{
                                padding: "0.2rem",
                                margin: '0.5rem,0rem',
                                backgroundColor: checkCosting ? 'primary.main' : 'primary.light',
                                color: checkCosting ? 'white' : 'primary.main',
                                // backgroundColor: url.includes("bomconfiguration") ? 'primary.main' : 'primary.light',
                                // color: url.includes("bomconfiguration") ? 'white' : 'primary.main',
                                textAlign: "left",
                                cursor: "pointer",
                                display: "inline-block",
                                width: "100%",
                            }}
                        >
                            <Typography style={{ fontSize: "0.87rem", textTransform: 'capitalize' }}>BOM Configuration</Typography>
                        </Box>
                    </Box>
                }
                {checkCosting && <Box sx={{ alignItems: "center", display: "flex", marginLeft: '1.5rem' }}>
                    <Checkbox
                        style={{ padding: '3px' }}
                        checked={url.includes("bomconfiguration")}
                        onClick={() => TemplateHandler()}
                        inputProps={{ "aria-label": "controlled" }}
                        sx={{ "& .MuiSvgIcon-root": { fontSize: { lg: '24', xl: '30' } } }}
                    />
                    <Box
                        onClick={() => TemplateHandler()}
                        sx={{
                            padding: "0.2rem",
                            margin: '0.5rem,0rem',
                            backgroundColor: url.includes("bomconfiguration") ? '#2b9cf6eb' : 'primary.light',
                            color: url.includes("bomconfiguration") ? 'white' : '#2b9cf6eb',
                            textAlign: "left",
                            cursor: "pointer",
                            display: "inline-block",
                            width: "100%",
                        }}
                    >
                        <Typography style={{ fontSize: "0.87rem", textTransform: 'capitalize' }}>Template Configuration</Typography>
                    </Box>
                </Box>}
                {checkCosting && <Box sx={{ alignItems: "center", display: "flex", marginLeft: '1.5rem' }} >
                    <Checkbox
                        style={{ padding: '3px' }}
                        checked={url.includes("customValidation")}
                        onClick={() => history.push(`/customValidation/${projectId}/${projectname}/0/0/0`,)}
                        inputProps={{ "aria-label": "controlled" }}
                        sx={{ "& .MuiSvgIcon-root": { fontSize: { lg: '24', xl: '30' } } }}
                    />
                    <Box
                        // key={index}
                        onClick={() => history.push(`/customValidation/${projectId}/${projectname}/0/0/0`)}
                        sx={{
                            padding: "0.2rem",
                            margin: '0.5rem,0rem',
                            backgroundColor: url.includes("customValidation") ? "#2b9cf6eb" : 'primary.light',
                            color: url.includes("customValidation") ? 'white' : "#2b9cf6eb",
                            textAlign: "left",
                            cursor: "pointer",
                            display: "inline-block",
                            width: "100%",
                        }}
                    >
                        <Typography style={{ fontSize: "0.87rem", textTransform: 'capitalize' }}>Custom Validation</Typography>
                    </Box>
                </Box>}
                {ConfigData && ConfigData?.costing_config == true &&
                    <Box sx={{ alignItems: "center", display: "flex" }}>
                        <Checkbox
                            style={{ padding: '3px' }}
                            checked={url.includes("costingconfiguration")}
                            onClick={() => history.push(`/costingconfiguration/${projectId}/${projectname}/guidelines/`,)}
                            inputProps={{ "aria-label": "controlled" }}
                            sx={{ "& .MuiSvgIcon-root": { fontSize: { lg: '24', xl: '30' } } }}
                        />
                        <Box
                            // key={index}
                            // onClick={() => formInputField('category_change', item?.value)}
                            onClick={() => {
                                sessionStorage.setItem('HideCommodity', 'false');
                                sessionStorage.setItem('HideProcess', 'false');
                                history.push(`/costingconfiguration-template/${projectId}/${projectname}/0/0/false/0/0/0/1/0/false`)
                            }}
                            // onClick={() => history.push(`/costingconfiguration/${projectId}/${projectname}/guidelines/`,)}
                            sx={{
                                padding: "0.2rem",
                                margin: '0.5rem,0rem',
                                backgroundColor: url.includes("costingconfiguration") ? 'primary.main' : 'primary.light',
                                color: url.includes("costingconfiguration") ? 'white' : 'primary.main',
                                textAlign: "left",
                                cursor: "pointer",
                                display: "inline-block",
                                width: "100%",
                            }}
                        >
                            <Typography style={{ fontSize: "0.87rem", textTransform: 'capitalize' }}>Costing Configuration</Typography>
                        </Box>
                    </Box>
                }
                {ConfigData && ConfigData?.feature_config == true &&
                    <Box sx={{ alignItems: "center", display: "flex" }}>
                        <Checkbox
                            style={{ padding: '3px' }}
                            checked={url.includes("feature-configuration")}
                            onClick={() => FeatureHandler()}
                            inputProps={{ "aria-label": "controlled" }}
                            sx={{ "& .MuiSvgIcon-root": { fontSize: { lg: '24', xl: '30' } } }}
                        />
                        <Box
                            // key={index}
                            // onClick={() => formInputField('category_change', item?.value)}
                            onClick={() => FeatureHandler()}
                            // onClick
                            sx={{
                                padding: "0.2rem",
                                margin: '0.5rem,0rem',
                                backgroundColor: url.includes("feature-configuration") ? 'primary.main' : 'primary.light',
                                color: url.includes("feature-configuration") ? 'white' : 'primary.main',
                                textAlign: "left",
                                cursor: "pointer",
                                display: "inline-block",
                                width: "100%",
                            }}
                        >
                            <Typography style={{ fontSize: "0.87rem", textTransform: 'capitalize' }}>Feature Configuration</Typography>
                        </Box>
                    </Box>
                }
                {ConfigData && ConfigData?.idea_config == true &&
                    <Box sx={{ alignItems: "center", display: "flex" }}>
                        <Checkbox
                            style={{ padding: '3px' }}
                            checked={url.includes("ideaconfiguration")}
                            onClick={() => IdeaHandler()}
                            inputProps={{ "aria-label": "controlled" }}
                            sx={{ "& .MuiSvgIcon-root": { fontSize: { lg: '24', xl: '30' } } }}
                        />
                        <Box
                            // key={index}
                            onClick={() => IdeaHandler()}
                            sx={{
                                padding: "0.2rem",
                                margin: '0.5rem,0rem',
                                backgroundColor: url.includes("ideaconfiguration") ? 'primary.main' : 'primary.light',
                                color: url.includes("ideaconfiguration") ? 'white' : 'primary.main',
                                textAlign: "left",
                                cursor: "pointer",
                                display: "inline-block",
                                width: "100%",
                            }}
                        >
                            <Typography style={{ fontSize: "0.87rem", textTransform: 'capitalize' }}>Ideas Configuration</Typography>
                        </Box>
                    </Box>
                }{
                    ConfigData && ConfigData?.companion_config == true &&
                    <Box sx={{ alignItems: "center", display: "flex" }}>
                        <Checkbox
                            style={{ padding: '3px' }}
                            checked={url.includes("companionConfig")}
                            onClick={() => comapnionHandle()}
                            inputProps={{ "aria-label": "controlled" }}
                            sx={{ "& .MuiSvgIcon-root": { fontSize: { lg: '24', xl: '30' } } }}
                        />
                        <Box
                            // key={index}
                            onClick={() => comapnionHandle()}
                            sx={{
                                padding: "0.2rem",
                                margin: '0.5rem,0rem',
                                backgroundColor: url.includes("companionConfig") ? 'primary.main' : 'primary.light',
                                color: url.includes("companionConfig") ? 'white' : 'primary.main',
                                textAlign: "left",
                                cursor: "pointer",
                                display: "inline-block",
                                width: "100%",
                            }}
                        >
                            <Typography style={{ fontSize: "0.87rem", textTransform: 'capitalize' }}>Companion App Configuration</Typography>
                        </Box>
                    </Box>
                }
                {ConfigData && ConfigData?.costing_config == false
                    && ConfigData && ConfigData?.idea_config == false
                    && ConfigData && ConfigData?.feature_config == false
                    && ConfigData && ConfigData?.companion_config == false && <Box sx={{ textAlign: 'center', width: '100%' }}>
                        <img src={warningImg} style={{ width: '5rem' }} />
                        <Typography sx={{ fontSize: '1rem', color: 'red' }}>Configuration not available for this project!.</Typography>
                    </Box>
                }
            </Box>
        </div>
    );
}
export default CostingConfigurationDrawer;

