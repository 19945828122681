import React, { useState } from "react";
import {
  Box,
  useTheme,
  Typography,
  InputBase,
  styled,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Skeleton,
  Divider,
  Avatar,
  Badge,
} from "@mui/material";
import SendOutlinedIcon from "@mui/icons-material/SendOutlined";
import AIChatIcon from "./AIChatIcon"; // Replace with your AI icon
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  LineChart,
  Line,
  PieChart,
  Pie,
  Cell,
} from "recharts";
import { API } from "../api-services";
import { useRouteParams } from "../ui-reusable-component/useRouteParams";
import {
  barGraphData,
  paretoData,
  tableData,
  pieData,
  COLORS,
} from "./DummyData";
import ResponseRenderer from "./ResponseRenderer";
import StopCircleIcon from "@mui/icons-material/StopCircle";
import { useSelector } from "react-redux";
import { userSelectors } from "../Redux/Reducers/user.reducer";
import Image from "../../Components/ViewModule/FeatureLandingPage/ArchitectureImageCard";
import loadingImage from "../Assets/images/loading-small.gif";
import AutoAwesomeMosaicIcon from "@mui/icons-material/AutoAwesomeMosaic";
import { useHistory } from "react-router-dom";
import editmail from "../Assets/images/mailEdit.png";
import axios, { CancelTokenSource } from "axios";

export interface IChatRightSectionProps {
  isEmptyGroup: any;
  setReCallGroup: any;
  setExpanded: any;
  expanded: any;
}

interface ChatMessage {
  id: number;
  type:
    | "text"
    | "image"
    | "graph"
    | "text+graph"
    | "pareto"
    | "multi-image"
    | "table";
  content: string | JSX.Element | any;
  sender: "user" | "ai";
  timestamp?: string | null;
  project?: string | null;
}

const StyledHeading = styled(Typography)(({ theme }) => ({
  fontSize: "1.5rem",
  fontWeight: 700,
  fontFamily: "'Poppins', sans-serif",
  background: "linear-gradient(90deg, #6a11cb, #2575fc, #00c6ff)",
  WebkitBackgroundClip: "text",
  WebkitTextFillColor: "transparent",
  textAlign: "center",
  textTransform: "uppercase",
  letterSpacing: "0.1rem",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  width: "100%",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(1)})`,
    transition: theme.transitions.create("width"),
    [theme.breakpoints.up("sm")]: {
      width: "100%",
      "&:focus": {
        width: "100%",
      },
    },
  },
}));

type ResponseDisplay = "table" | "normal";

interface ParsedRow {
  [key: string]: string;
}

/**
 * Dynamically determines if the response should be displayed as a table or normal response.
 * @param responseData - The string data from the AI response.
 * @returns "table" if the data should be displayed in a table, "normal" otherwise.
 */
export function getResponseDisplayType(input: string): {
  type: "table" | "normal";
  tableData?: Array<Record<string, string>>;
} {
  // Check if the input contains table-like data using a regex
  const tableRegex = /\|.*\|.*\|.*\|/; // Matches a line with '|' as column delimiters
  const tableLines = input
    ?.split("\n")
    ?.filter((line) => tableRegex.test(line));

  if (tableLines.length > 1) {
    // Parse table data if enough lines match the table format
    const headers = tableLines[0]
      ?.split("|")
      ?.map((header) => header.trim())
      ?.filter((header) => header); // Extract headers

    const rows = tableLines.slice(1).map((line) => {
      const cells = line
        ?.split("|")
        ?.map((cell) => cell.trim())
        ?.filter((cell) => cell); // Extract row data
      const rowData: Record<string, string> = {};
      headers.forEach((header, index) => {
        rowData[header] = cells[index] || ""; // Map each cell to its header
      });
      return rowData;
    });

    return { type: "table", tableData: rows };
  }

  // Fallback to normal if no table structure detected
  return { type: "normal" };
}

export default function ChatRightSection(props: IChatRightSectionProps) {
  const cancelTokenSource = React.useRef<CancelTokenSource | null>(null);
  const history = useHistory();
  const { isEmptyGroup, setReCallGroup, setExpanded, expanded } = props;
  const userProfileDetails = useSelector(userSelectors.selectAll);
  const { grpId, chatId, userid, tab, userId } = useRouteParams<any>();
  const theme = useTheme();
  const [messages, setMessages] = useState<ChatMessage[]>([]);
  const [userInput, setUserInput] = useState("");
  const [loading, setLoading] = useState(false);
  const [profileDetails, setProfileDetails] = React.useState<any>({});

  const getProfileDetails = () => {
    API.get(`/auth/user/${userId}/`, {}, 0)
      .then((res: any) => {
        setProfileDetails(res.data);
      })
      .catch((err: any) => {});
  };

  React.useEffect(() => {
    getProfileDetails();
  }, [userId]);

  React.useEffect(() => {
    if (chatId == 0) {
      setMessages([]);
    }
  }, [chatId]);

  const handleCancel = () => {
    if (cancelTokenSource.current) {
      cancelTokenSource.current.cancel("Request canceled by the user.");
      cancelTokenSource.current = null; // Reset the cancel token
    }
  };

  function hasMinimumTwoWords(input: string): boolean {
    // Trim the string to remove extra spaces at the beginning and end
    const trimmedInput = input?.trim();

    // Split the string by spaces and filter out empty strings (caused by multiple spaces)
    const words = trimmedInput?.split(/\s+/);

    // Check if the length of the resulting array is at least 2
    return words.length >= 2;
  }

  const getName = () => {
    return (
      userProfileDetails &&
      userProfileDetails[0] &&
      (userProfileDetails[0].first_name[0] || "") +
        (userProfileDetails[0].last_name[0] || "")
    );
  };
  const getnameOtherChat = (firstName: any, lastName: any) => {
    return (firstName[0] || "") + (lastName[0] || "");
  };
  const getfullnameOtherChat = (firstName: any, lastName: any) => {
    return (firstName || "") + " " + (lastName || "");
  };
  const getFullName = () => {
    return (
      userProfileDetails &&
      userProfileDetails[0] &&
      (userProfileDetails[0].first_name || "") +
        " " +
        (userProfileDetails[0].last_name || "")
    );
  };

  const getAllMessages = () => {
    API.get(
      "/xcpep/get_LLM_search_history/",
      {
        chat_id: chatId,
        user_id: userId != 0 ? userId : undefined,
      },
      0
    )
      .then((res: any) => {
        const apiMessages = res.data?.map((item: any, index: number) => ({
          id: item.id || index + 1, // Use `id` from the response or fallback to index
          type: item.type || "text", // Use `type` from the response, default to "text"
          content: item?.content, // Format content based on type
          sender: item.sender || "ai", // Use `sender` from the response or default to "ai"
          timestamp: item.timestamp || null, // Use `timestamp` from the response or default to null
          project: item.project || null, // Use `project` from the response or default to null
        }));

        // Set the fetched messages into state
        setMessages(apiMessages);
      })
      .catch((err: any) => {});
  };

  React.useEffect(() => {
    if (grpId != 0) getAllMessages();
  }, [chatId]);

  const handleAIResponse = (question: string, answer: any) => {
    if (answer !== 0) {
      if (question.toLowerCase().includes("bar graph")) {
        setMessages((prev) => [
          ...prev,
          {
            id: prev.length + 1,
            type: "graph",
            content: (
              <Box sx={{ width: "100%", height: 300 }}>
                <BarChart width={700} height={300} data={barGraphData}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip />
                  <Bar dataKey="uv" fill="#8884d8" />
                </BarChart>
              </Box>
            ),
            sender: "ai",
          },
        ]);
      } else if (question.toLowerCase().includes("pareto graph")) {
        setMessages((prev) => [
          ...prev,
          {
            id: prev.length + 1,
            type: "pareto",
            content: (
              <Box sx={{ width: "100%", height: 300 }}>
                <LineChart
                  width={700}
                  height={300}
                  data={paretoData}
                  margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip />
                  <Bar dataKey="value" fill="#8884d8" />
                  <Line
                    type="monotone"
                    dataKey="cumulative"
                    stroke="#ff7300"
                    strokeWidth={2}
                  />
                </LineChart>
              </Box>
            ),
            sender: "ai",
          },
        ]);
      } else if (question.toLowerCase().includes("jjj")) {
        setMessages((prev) => [
          ...prev,
          {
            id: prev.length + 1,
            type: "graph",
            content: (
              <Box sx={{ width: 600, height: 300 }}>
                <TableContainer sx={{ width: 600, height: 300 }}>
                  <Table>
                    <TableHead sx={{ backgroundColor: "primary.light" }}>
                      <TableRow>
                        <TableCell>ID</TableCell>
                        <TableCell>Name</TableCell>
                        <TableCell>Progress</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {tableData.map((row) => (
                        <TableRow key={row.id}>
                          <TableCell>{row.id}</TableCell>
                          <TableCell>{row.name}</TableCell>
                          <TableCell>{row.progress}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            ),
            sender: "ai",
          },
        ]);
      } else if (question.toLowerCase().includes("pie chart")) {
        setMessages((prev) => [
          ...prev,
          {
            id: prev.length + 1,
            type: "graph",
            content: (
              <Box sx={{ width: "100%", height: 300 }}>
                <PieChart width={300} height={300}>
                  <Pie
                    data={pieData}
                    cx="50%"
                    cy="50%"
                    outerRadius={100}
                    fill="#8884d8"
                    dataKey="value"
                    label
                  >
                    {pieData.map((entry, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={COLORS[index % COLORS.length]}
                      />
                    ))}
                  </Pie>
                </PieChart>
              </Box>
            ),
            sender: "ai",
          },
        ]);
      } else if (question.toLowerCase().includes("multiple images")) {
        setMessages((prev) => [
          ...prev,
          {
            id: prev.length + 1,
            type: "multi-image",
            content: (
              <Box
                sx={{
                  display: "flex",
                  gap: "0.5rem",
                  flexWrap: "wrap",
                  justifyContent: "center",
                }}
              >
                <img
                  src="https://via.placeholder.com/150"
                  alt="Sample 1"
                  style={{ maxWidth: "150px", borderRadius: "8px" }}
                />
                <img
                  src="https://via.placeholder.com/150"
                  alt="Sample 2"
                  style={{ maxWidth: "150px", borderRadius: "8px" }}
                />
                <img
                  src="https://via.placeholder.com/150"
                  alt="Sample 3"
                  style={{ maxWidth: "150px", borderRadius: "8px" }}
                />
              </Box>
            ),
            sender: "ai",
          },
        ]);
      } else {
        setMessages((prev) => [
          ...prev,
          {
            id: prev.length + 1,
            type: "multi-image",
            content: <ResponseRenderer responseData={answer} sender={"ai"} />,
            sender: "ai",
          },
        ]);
      }
    } else {
      setLoading(true);
      setTimeout(() => {
        setReCallGroup((prev: any) => prev + 1);
      }, 1000);

      cancelTokenSource.current = axios.CancelToken.source();
      API.get(
        "/xcpep/get_llm_response/",
        {
          query: userInput,
          chat: chatId != 0 ? chatId : undefined,
          group: grpId != 0 ? grpId : undefined,
        },
        0,
        cancelTokenSource.current.token
      )
        .then((res: any) => {
          setLoading(false);
          if (question.toLowerCase().includes("bar graph")) {
            setMessages((prev) => [
              ...prev,
              {
                id: prev.length + 1,
                type: "graph",
                content: (
                  <Box sx={{ width: "100%", height: 300 }}>
                    <BarChart width={700} height={300} data={barGraphData}>
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="name" />
                      <YAxis />
                      <Tooltip />
                      <Bar dataKey="uv" fill="#8884d8" />
                    </BarChart>
                  </Box>
                ),
                sender: "ai",
              },
            ]);
          } else if (question.toLowerCase().includes("pareto graph")) {
            setMessages((prev) => [
              ...prev,
              {
                id: prev.length + 1,
                type: "pareto",
                content: (
                  <Box sx={{ width: "100%", height: 300 }}>
                    <LineChart
                      width={700}
                      height={300}
                      data={paretoData}
                      margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="name" />
                      <YAxis />
                      <Tooltip />
                      <Bar dataKey="value" fill="#8884d8" />
                      <Line
                        type="monotone"
                        dataKey="cumulative"
                        stroke="#ff7300"
                        strokeWidth={2}
                      />
                    </LineChart>
                  </Box>
                ),
                sender: "ai",
              },
            ]);
          } else if (question.toLowerCase().includes("sddsfds")) {
            setMessages((prev) => [
              ...prev,
              {
                id: prev.length + 1,
                type: "table",
                content: (
                  <Box sx={{ width: 600, height: 300 }}>
                    <TableContainer sx={{ width: 600, height: 300 }}>
                      <Table>
                        <TableHead sx={{ backgroundColor: "primary.light" }}>
                          <TableRow>
                            <TableCell>ID</TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell>Progress</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {tableData.map((row) => (
                            <TableRow key={row.id}>
                              <TableCell>{row.id}</TableCell>
                              <TableCell>{row.name}</TableCell>
                              <TableCell>{row.progress}</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                ),
                sender: "ai",
              },
            ]);
          } else if (question.toLowerCase().includes("pie chart")) {
            setMessages((prev) => [
              ...prev,
              {
                id: prev.length + 1,
                type: "graph",
                content: (
                  <Box sx={{ width: "100%", height: 300 }}>
                    <PieChart width={300} height={300}>
                      <Pie
                        data={pieData}
                        cx="50%"
                        cy="50%"
                        outerRadius={100}
                        fill="#8884d8"
                        dataKey="value"
                        label
                      >
                        {pieData.map((entry, index) => (
                          <Cell
                            key={`cell-${index}`}
                            fill={COLORS[index % COLORS.length]}
                          />
                        ))}
                      </Pie>
                    </PieChart>
                  </Box>
                ),
                sender: "ai",
              },
            ]);
          } else if (question.toLowerCase().includes("multiple images")) {
            setMessages((prev) => [
              ...prev,
              {
                id: prev.length + 1,
                type: "multi-image",
                content: (
                  <Box
                    sx={{
                      display: "flex",
                      gap: "0.5rem",
                      flexWrap: "wrap",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      src="https://via.placeholder.com/150"
                      alt="Sample 1"
                      style={{ maxWidth: "150px", borderRadius: "8px" }}
                    />
                    <img
                      src="https://via.placeholder.com/150"
                      alt="Sample 2"
                      style={{ maxWidth: "150px", borderRadius: "8px" }}
                    />
                    <img
                      src="https://via.placeholder.com/150"
                      alt="Sample 3"
                      style={{ maxWidth: "150px", borderRadius: "8px" }}
                    />
                  </Box>
                ),
                sender: "ai",
              },
            ]);
          } else {
            setMessages((prev) => [
              ...prev,
              {
                id: prev.length + 1,
                type: "multi-image",
                content: (
                  <ResponseRenderer responseData={res.data} sender={"ai"} />
                ),
                sender: "ai",
              },
            ]);
          }
        })
        .catch((err: any) => {
          setLoading(false);
          setMessages((prev) => [
            ...prev,
            {
              id: prev.length + 1,
              type: "text",
              content: `Sorry, Unable to fetch the response due to Request canceled by the user or internet connection. Please check your connection and try again.`,
              sender: "ai",
            },
          ]);
        });
    }
  };

  const handleSendMessage = () => {
    if (!userInput.trim()) return;

    const newMessage: ChatMessage = {
      id: messages?.length + 1,
      type: "text",
      content: userInput.trim(),
      sender: "user",
    };
    setMessages((prev) => [...prev, newMessage]);
    setUserInput("");

    setTimeout(() => handleAIResponse(newMessage.content, 0), 1000);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "0.5rem",
        height: "100%",
        overflow: "hidden",
        // backgroundColor: "#e6e6fa",
        backgroundColor: "white",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: "0.5rem",
          backgroundColor: "white",
          // borderBottom: `0.5px solid ${theme.palette.divider}`,
        }}
      >
        {/* Header */}
        <Box sx={{ display: "flex", marginRight: "0.2rem", padding: "3px" }}>
          {expanded === false && (
            <Badge
              variant="dot"
              color="success"
              sx={{
                "& .MuiBadge-dot": {
                  width: "12px",
                  height: "12px",
                  borderRadius: "50%",
                  backgroundColor: "red",
                  top: "5px",
                  right: "5px",
                },
              }}
            >
              <AutoAwesomeMosaicIcon
                titleAccess="Expand window"
                sx={{ fontSize: "2.5rem", cursor: "pointer" }}
                onClick={() => setExpanded(true)}
              />
            </Badge>
          )}
        </Box>
        <Box
          sx={{
            padding: "0.5rem",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <StyledHeading>Chat With xcPEP AI</StyledHeading>
          {tab === "mychat" && (
            <Box>
              {!isEmptyGroup && tab !== "otherchat" && (
                <img
                  src={editmail}
                  onClick={() => {
                    history.push(`/chat/${grpId}/0/${userId}/${tab}`);
                  }}
                  title="Create new chat"
                  style={{ width: "2rem", height: "2rem", cursor: "pointer" }}
                />
              )}
            </Box>
          )}
        </Box>
      </Box>
      {/* Messages */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
          padding: "1rem",
          overflowY: "auto",
          flexGrow: 1,
        }}
      >
        {messages &&
          Array.isArray(messages) &&
          messages?.map((msg) => (
            <Box
              key={msg.id}
              sx={{
                display: "flex",
                flexDirection: msg.sender === "user" ? "row-reverse" : "row",
                alignItems: "center",
                gap: "0.5rem",
              }}
            >
              {msg.sender === "ai" ? (
                <AIChatIcon
                  style={{ color: "red", width: "3rem", height: "3rem" }}
                />
              ) : // <AccountCircleIcon sx={{ fontSize: "3rem" }} />
              userProfileDetails[0]?.user_info ? (
                <Image
                  loading="lazy"
                  alt="User Profile"
                  placeholderImg={loadingImage}
                  title={
                    tab === "mychat"
                      ? getFullName()
                      : getfullnameOtherChat(
                          profileDetails?.first_name,
                          profileDetails?.last_name
                        )
                  }
                  src={
                    tab === "mychat"
                      ? userProfileDetails[0]?.user_info
                      : profileDetails?.user_info
                  }
                  style={{
                    width: "3rem",
                    height: "3rem",
                    // fontSize: "2rem !important",
                    borderRadius: "50%",
                    objectFit: "contain",
                  }}
                />
              ) : (
                <Avatar
                  src="/broken-image.jpg"
                  sx={{
                    width: "3rem",
                    height: "3rem",
                    fontSize: "2rem !important",
                    // objectFit: "contain",
                    backgroundColor: "#007fff !important",
                    textTransform: "uppercase",
                    // margin:'auto',
                  }}
                >
                  {tab === "mychat" &&
                  userProfileDetails &&
                  userProfileDetails[0]
                    ? getName()
                    : getnameOtherChat(
                        profileDetails?.first_name,
                        profileDetails?.last_name
                      )}
                  {/* {AvatarName(item?.name)} */}
                </Avatar>
              )}
              <Box
                sx={{
                  backgroundColor:
                    msg.sender === "user" ? "#515989" : "#f9f9f9",
                  color:
                    msg.sender === "user" ? "#fff" : theme.palette.text.primary,
                  padding: "0.2rem 0.4rem",
                  borderRadius: "1rem",
                  maxWidth: "70%",
                  boxShadow: theme.shadows[1],
                  textAlign: "left",
                }}
              >
                <Typography
                  variant="body2"
                  sx={{ color: "GrayText", margin: "0.5rem 0" }}
                >
                  {msg.project && msg.project}
                </Typography>
                {msg.project && <Divider sx={{ marginBottom: "0.5rem" }} />}
                {msg.type === "text" && (
                  <ResponseRenderer
                    responseData={msg.content}
                    sender={msg.sender}
                  />
                )}
                {msg.type === "image" && msg.content}
                {msg.type === "multi-image" && msg.content}
                {msg.type === "graph" && msg.content}
                {msg.type === "pareto" && msg.content}
                {msg.type === "table" && msg.content}
              </Box>
            </Box>
          ))}

        {/* Skeleton loader */}
        {loading && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: "0.5rem",
            }}
          >
            <AIChatIcon
              style={{ color: "red", width: "3rem", height: "3rem" }}
            />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "0.5rem",
                padding: "1rem",
                borderRadius: "1rem",
                width: "60%",
                boxShadow: theme.shadows[1],
              }}
            >
              <Skeleton
                variant="text"
                width="80%"
                sx={{ fontSize: "1rem", marginBottom: "0.5rem" }}
              />
              <Skeleton
                variant="text"
                width="60%"
                sx={{ fontSize: "1rem", marginBottom: "0.5rem" }}
              />
              <Skeleton variant="text" width="90%" sx={{ fontSize: "1rem" }} />
            </Box>
          </Box>
        )}
      </Box>

      {/* Input */}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "0.5rem",
          padding: "0.5rem",
          borderTop: `1px solid ${theme.palette.divider}`,
        }}
      >
        {tab !== "otherchat" && (
          <StyledInputBase
            sx={{ backgroundColor: "white", borderRadius: "5px" }}
            placeholder="Type your question here..."
            value={userInput}
            onChange={(e) => setUserInput(e.target.value)}
            onKeyDown={(e) => {
              if (
                e.key === "Enter" &&
                hasMinimumTwoWords(userInput) &&
                !loading
              )
                handleSendMessage();
            }}
          />
        )}
        {!loading
          ? tab !== "otherchat" && (
              <SendOutlinedIcon
                sx={{
                  color: "#515989",
                  cursor: "pointer",
                  opacity: hasMinimumTwoWords(userInput) ? 1 : 0.5,
                }}
                onClick={() => {
                  if (hasMinimumTwoWords(userInput)) {
                    handleSendMessage();
                  }
                }}
              />
            )
          : tab !== "otherchat" && (
              <StopCircleIcon
                sx={{ cursor: "pointer", color: "#515989" }}
                onClick={handleCancel}
              />
            )}
      </Box>
    </Box>
  );
}
