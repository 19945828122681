import { AsyncThunk } from "@reduxjs/toolkit";
import {
  CALCULATOR_CATEGORY,
  CALCULATOR_TYPE,
} from "../../../../Configuration/Costing/calculator.constant";
import { updateRawMaterialCardCost } from "../../../../Redux/Actions/rawMaterialCard.actions";
import { rawMaterialCardSelectors } from "../../../../Redux/Reducers/rawMaterialCard.reducer";
import { useHistory, useRouteMatch } from "react-router-dom";
import { useAppDispatch } from "../../../../../AppRouter";
import { Fragment, useContext, useEffect, useState } from "react";
import { ID } from "../../../../utlis/commonInterfaces";
import { useSelector } from "react-redux";
import { RootState } from "../../../../Redux/Store/storeConfigurations";
import { userInputSelectors } from "../../../../Redux/Reducers/userInput.reducer";
import { useQueryDispatch } from "../../../../CustomHook/useQueryDispatch";
import swal from "sweetalert";
import {
  getUserInputs,
  resetUserInputs,
} from "../../../../Redux/Actions/userInput.actions";
import successIcon from "../../../../Assets/images/success.png";
import { Box, Skeleton, TableCell, TableRow, TextField } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import { useRouteParams } from "../../../../ui-reusable-component/useRouteParams";
import { API } from "../../../../api-services";
import { updateProcessCardCost } from "../../../../Redux/Actions/processCard.action";
import { updateOverheadCardCost } from "../../../../Redux/Actions/overheadCard.action";
import { processCardSelectors } from "../../../../Redux/Reducers/processCard.reducer";
import { overheadCardSelectors } from "../../../../Redux/Reducers/overheadCard.reducer";
import { AddCostModal } from "./Calculator/CostModal/AddCostModal";
import { useBaseModal } from "../../../SearchPage/useBaseModal";
import React from "react";
import Swal from "sweetalert2";
import { userPermission } from "../CostigngCalculatorSection";
import { AddCostModelProcess } from "./Calculator/CostModal/AddCostModelProcess";
import { useBaseParamsModal } from "../../../../CustomHook/useBaseParamsModal";
import CopyCostModal from "./CopyCostModal";
import { toExponential } from "../../../../utlis/toExponential";
import CommentIcon from "@mui/icons-material/Comment";
import CommentModal from "./CommentModal";
import { ADMIN } from "../../../../Redux/Services/admin.service";
import CostModelMenuItemActive from "./Calculator/CostModelMenuItemActive";
import CostModelMenuItem from "./Calculator/CostModelMenuItem";
import CalculatorConfig from "./Calculator/CalculatorConfig";

// ----------------------------------------------------------------------------------
// constants

const selectorsMap = {
  [CALCULATOR_TYPE.RAW_MATERIAL]: rawMaterialCardSelectors,
  [CALCULATOR_TYPE.PROCESS]: processCardSelectors,
  [CALCULATOR_TYPE.OVERHEAD]: overheadCardSelectors,
};

const actionsMap: Record<number, AsyncThunk<any, any, any>> = {
  [CALCULATOR_TYPE.RAW_MATERIAL]: updateRawMaterialCardCost,
  [CALCULATOR_TYPE.PROCESS]: updateProcessCardCost,
  [CALCULATOR_TYPE.OVERHEAD]: updateOverheadCardCost,
};

const resultKeyMap: Record<number, any> = {
  [CALCULATOR_CATEGORY.RAW_MATERIAL]: "material_result",
  [CALCULATOR_CATEGORY.LABOUR]: "labour_result",
  [CALCULATOR_CATEGORY.MACHINE]: "machine_result",
  [CALCULATOR_CATEGORY.PURCHASE_ITEM]: "bop_result",
  [CALCULATOR_CATEGORY.SET_UP]: "setup_result",
  [CALCULATOR_CATEGORY.TOOL]: "tool_result",
};

const getType = (type: any) => {
  switch (type) {
    case 1:
      return 1;
    case 2:
      return 1;
    case 3:
      return 2;
    case 4:
      return 2;
    case 5:
      return 2;
    case 6:
      return 2;
    case 0:
      return 3;

    default:
      return 1;
  }
};

// ----------------------------------------------------------------------------------

interface CalculatorCardProps {
  setCurrentCalc?: any;
  status?: any;
  setUpdateCosting?:any;
  updateCosting?:any
}

const CalculatorCardConfig: React.FC<CalculatorCardProps> = React.memo(
  (props) => {
    const { setCurrentCalc, status,  setUpdateCosting,
    updateCosting } = props;
    // const params = useParams<Params>();
    // const { cardCostId, calculatorCategory } = mapValues( params, Number );
    const {
      vault,
      topVault,
      projectId,
      projectname,
      productId,
      abbrebation,
      cardtype,
      tableId,
      costEstimationId,
      tableCounter,
      condition,
      calctype,
      calcultorId,
      scenarioId,
      conditionPopup,
      newUI,
    } = useRouteParams<any>();
    const dispatch = useAppDispatch();
    const history = useHistory();
    const { url } = useRouteMatch();
    const addcostmodaldata = useBaseParamsModal();
    const addCostModalProcess = useBaseParamsModal();
    const copyCostModal = useBaseModal();
    const commentModal = useBaseModal();
    const [CostDetailsData, setCostDetailsData] = useState<any>();
    const [Loader, setLoader] = useState<any>(false);
    const [isWeightInput, setIsWeightInput] = useState<any>(false);
    const [costCalculatorId, setCostCalculatorId] = useState<any>(0);
    // const readonly = useCostingReadonly();

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    // ----------------------------------------------------------------------------------
    // state

    /** active calculator id */
    const [active, setActive] = useState<ID>(0);
    const [result, setResult] = useState("");
    const [copyCMInfo, setCopyCMInfo] = useState<any>();
    const [editedItems, setEditedItems] = useState<any>();
    const [oldEditedItems, setOldEditedItems] = useState<any>();
    const [orderedCalculators, setOrderedCalculators] = useState<any>([]);
    const textRef = React.useRef<HTMLDivElement>(null);
    // ----------------------------------------------------------------------------------
    // selectors

    const calculators = useSelector((state: RootState) => {
      return userInputSelectors.selectCalculatorByCategory(
        state,
        calctype ? calctype : 0
      );
    });

    const cardCost = useSelector((state: RootState) => {
      const selector = selectorsMap[getType(calctype ? calctype : 0)];
      if (!selector) {
        console.error("Unhandled 'calculatorType' provided");
        return;
      }

      return selector.selectById(state, tableId);
    });

    const useraction = useContext<any>(userPermission);
    const [statustracker, setStatustracker] = useState<any>(false);

    const getResultKey = () =>
      (resultKeyMap[calctype] || "result") as keyof typeof cardCost;

    // ----------------------------------------------------------------------------------
    // handlers

    const handleChange = (e: any) => {
      setStatustracker(true);
      setResult(e?.target?.value);
    };

    const CopyAliasHandler = (item: any) => {
      var data = `${result}${item}`;
      setStatustracker(true);
      setResult(data);
    };

    // const handleCategoryChange = ( e: React.MouseEvent, ed: MenuItemProps ) => {
    //     const link = url.split( "/calc/" ).filter( Boolean )?.[0];
    //     if ( !link ) return;
    //     history.push( `${link}/calc/${ed.category}` );
    // };

    // const handleActiveCalculatorChange = (item: any) => {
    //   setIsWeightInput(
    //     orderedCalculators.filter((element: any) => {
    //       // @ts-ignore
    //       return element?.calculator?.id == item?.id;
    //       //@ts-ignore
    //     })[0]?.is_weight_input
    //   );
    //   setCostCalculatorId(
    //     orderedCalculators.filter((element: any) => {
    //       // @ts-ignore
    //       return element?.calculator?.id == item?.id;
    //       //@ts-ignore
    //     })[0]?.id
    //   );
    //   setActive(item?.id);
    //   history.push(
    //     `/createcostingpart/${projectId}/${projectname}/${productId}/${topVault}/${vault}/${abbrebation}/${cardtype}/${tableId}/${costEstimationId}/${tableCounter}/${condition}/${calctype}/${item?.id}/${scenarioId}/${conditionPopup}/${newUI}`
    //   );
    // };

    const handleSave = () => {
      const update = {
        id: tableId,
        updates: { [getResultKey()]: result },
      };

      const action = actionsMap[getType(calctype ? calctype : 0)];

      if (!action) return console.error("Unhandled 'calculatorType' provided");

      // else, if process card calculator
      const resultData: any = dispatch(action(update));
      var datavalue = Math.random();
      if (resultData) {
        setTimeout(() => {
          history.push(
            `/createcostingpart/${projectId}/${projectname}/${productId}/${topVault}/${vault}/${abbrebation}/${cardtype}/${tableId}/${costEstimationId}/${tableCounter}/${datavalue}/${calctype}/${calcultorId}/${scenarioId}/${conditionPopup}/${newUI}`
          );
        }, 500);
      }
    };
    // ----------------------------------------------------------------------------------
    // side effects

    // fetch costCalculations (where user inputs are saved)

    const userInputsFetched = useQueryDispatch({
      query: {
        action: getUserInputs,
        arg: { category: calctype, cardCostId: tableId },
      },
      cleanup: { action: resetUserInputs, args: [] },
      dependency: [tableId, costEstimationId, calctype, calcultorId, condition],
    });

    // make the first calculator as default
    useEffect(() => {
      // TODO: skip if there is no change in category
      if (calcultorId == 0) {
        if (calculators.length > 0) {
          setActive(calculators[0].id);
          // @ts-ignore

          setCostCalculatorId(orderedCalculators[0]?.id);
          // @ts-ignore
          setIsWeightInput(orderedCalculators[0]?.is_weight_input);
        } else {
          setActive(0);
        }
      } else {
        setActive(calcultorId);
      }
    }, [calculators]);

    // const handleDoubleClick = () => {
    //   if (textRef.current) {
    //     const textToCopy: any = textRef.current.textContent;
    //     navigator.clipboard
    //       .writeText(textToCopy)
    //       .then(() => {
    //         ADMIN.toast.info("Copied to clipboard");
    //       })
    //       .catch(() => {
    //         ADMIN.toast.error("Unable to Copy");
    //       });
    //   }
    // };

    // sync result
    useEffect(() => {
      if (!cardCost) return;

      const key = getResultKey(),
        result = cardCost[key];

      if (typeof result === "string") setResult(result);
      else setResult("");
    }, [cardCost, calctype]);

    const handleCopied = (e: any, calc: any) => {
      e.stopPropagation();
      setCopyCMInfo(calc);
      copyCostModal.open();
    };

    const handleCommentEdit = (e: any, calc: any) => {
      e.stopPropagation();
      setCopyCMInfo(calc);
      commentModal.open();
    };
    // ----------------------------------------------------------------------------------
    // jsx abstraction

    // const renderCategoryItem = ( item: Record<string, any> ) => {
    //     return (
    //         <Menu.Item key={ item.key }
    //             name={ item.text }
    //             category={ item.value }
    //             active={ item.value === calculatorCategory }
    //             onClick={ handleCategoryChange }
    //         />
    //     );
    // };

    useEffect(() => {
      const calculatorIds: any = orderedCalculators?.map((item: any) => {
        return item?.sequence;
      });

      setEditedItems(calculatorIds);
      setOldEditedItems(calculatorIds);
    }, [orderedCalculators]);
    const handleDelete = (id: any, e: any) => {
      e?.stopPropagation();

      const SelectedId: any =
        orderedCalculators &&
        orderedCalculators?.filter((item: any) => {
          return item?.calculator?.id == id;
        })[0];

      var data = Math?.random();
      swal({
        text: " Update the final formula and click apply! Are you sure you want to delete this cost model?",

        icon: "warning",
        buttons: ["No", "Yes"],
      })
        .then((isData: any) => {
          if (isData) {
            API.delete(
              `cost/calculation/${SelectedId?.id}/`,
              { project: projectId },
              0
            )
              .then(() => {
                history.push(
                  `/createcostingpart/${projectId}/${projectname}/${productId}/${topVault}/${vault}/${abbrebation}/${cardtype}/${tableId}/${costEstimationId}/${tableCounter}/${data}/${calctype}/${0}/${scenarioId}/${conditionPopup}/${newUI}`
                );
              })
              .catch(() => {
                console.log("Server Error");
              });
          }
        })
        .catch(() => {});
    };

    const SwalAlertHandlerImages = (type: any) => {
      if (type == true) {
        var data = Math?.random();
        history.push(
          `/createcostingpart/${projectId}/${projectname}/${productId}/${topVault}/${vault}/${abbrebation}/${cardtype}/${tableId}/${costEstimationId}/${tableCounter}/${data}/${calctype}/${0}/${scenarioId}/${conditionPopup}/${newUI}`
        );
        addcostmodaldata.close();

        {
          Swal.fire({
            html: `<div><img src="${successIcon}" alt="Custom Image" style="width: 7rem; height: auto;" />
        <br />
        <p style="color:#007fff;"> Cost model added  successfully
        </p>   
         </div>`,
          });
        }
      }

      if (type == false) {
        {
          addcostmodaldata.close();
          Swal.fire({
            icon: "error",
            html: `<div>
        <br />
        <p style="color:#007fff;"> Server Error
        </p>   
         </div>`,
          });
        }
      }
    };

    const getCalculators = () => {
      API.get(
        "cost/calculation/",
        {
          category: calctype,
          /** RawMaterialCost/ProcessCost */
          object_id: tableId,
        },
        0
      )
        .then((res: any) => {
          setOrderedCalculators(res.data);
        })
        .catch((err: any) => {
          console.log(err);
        });
    };

    useEffect(() => {
      // if(alldatacalculators?.length > 0){
      getCalculators();
      // }
    }, [tableId]);

    // ----------------------------------------------------------------------------------
    // jsx

    return (
      <Fragment>
        {/* <CalculatorInputsColorsLegend /> */}
        <Box
          data-testid="calculator"
          // className={styles.container}
          sx={{ width: "100%", height: "100%" }}
        >
          {addcostmodaldata.isOpen && (
            <AddCostModal
              onCloseModal={addcostmodaldata.close}
              isOpen={addcostmodaldata.isOpen}
              costDetailsData={addcostmodaldata.propsId}
              SwalAlertHandlerImages={SwalAlertHandlerImages}
              // parentClose={parentClose}
            />
          )}
          {addCostModalProcess.isOpen && (
            <AddCostModelProcess
              onCloseModal={addCostModalProcess.close}
              isOpen={addCostModalProcess.isOpen}
              costDetailsData={addCostModalProcess.propsId}
              SwalAlertHandlerImages={SwalAlertHandlerImages}
             
              passingObj={JSON.parse(
                 //@ts-ignore
                sessionStorage?.getItem("selectedProcess")
              )}
            />
          )}
          {copyCostModal.isOpen && (
            <CopyCostModal
              onCloseModal={copyCostModal.close}
              isOpen={copyCostModal.isOpen}
              copyCMInfo={copyCMInfo}
              SwalAlertHandlerImages={SwalAlertHandlerImages}
            />
          )}

          {commentModal.isOpen && (
            <CommentModal
              onCloseModal={commentModal.close}
              isOpen={commentModal.isOpen}
              commentInfo={copyCMInfo}
              SwalAlertHandlerImages={SwalAlertHandlerImages}
            />
          )}

          {userInputsFetched.match("FALSE") ? (
            <Skeleton
              variant="rectangular"
              height="72vh"
              width="100%"
              sx={{ marginBottom: "1rem" }}
            />
          ) : (
            <>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  // gap: "1rem",
                  // columnGap: "0.5rem",
                  zIndex: 1,
                  position: "relative",
                  width: "100%",
                  height: "74vh",
                  // overflowY:'auto',
                  // border: "1px solid #e0e0e0",
                }}
              >
                <Box sx={{ width: "100%" }}>
                  <CalculatorConfig
                    CopyAliasHandler={CopyAliasHandler}
                    setActive={setActive}
                    calculatorId={active}
                    handleSave={handleSave}
                    status={statustracker}
                    calculators={calculators}
                    costModelCalculatorId={costCalculatorId}
                    handleChangeFinalformula={handleChange}
                    isWeightInput={isWeightInput}
                    result={result}
                    setCurrentCalc={setCurrentCalc}
                    itemCategory={orderedCalculators?.[0]?.item_category}
                    statuss={status}
                    setUpdateCosting={setUpdateCosting}
                    updateCosting={updateCosting}
                  />
                </Box>
              </Box>
            </>
          )}
        </Box>
      </Fragment>
    );
  }
);

// ----------------------------------------------------------------------------------

export default CalculatorCardConfig;
