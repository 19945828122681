import { createEntityAdapter, createReducer, EntityState } from "@reduxjs/toolkit";
import { ID } from "../../utlis/commonInterfaces";
import { RootState } from "../Store/storeConfigurations";


// -----------------------------------------------------------------------------------
// entity adaptor

// id
// name, alias, parameter, content_type, attribute, constant, user_input, formula

export interface CalculatorInput {
    result?: number;
    id: ID;
    type: number|any;
    name: string;
    /** string used to refere the input in the formula */
    alias: string;

    /** for type 1,2 */
    parameter?: ID;
    table_element?: ID;

    calculator_category?: number;

    database?: number;
    attribute?: string | any;

    formula?: string;
    constant?: number;
    user_input?: string;
    image_url?: string;
    test_input?: number;
    flag?: any;
    calculator: any;
    volume?:number;
    sequence?:any;
    bucket?:any;
    bucket_info?:any
    bucket_operation?:any;
    pcb_item_name?:any;
    pcb_item_type?:any;
    table_column_parameter?:any;
    table_element_type?:any;
    parameter_name?:any;

}

const adaptor = createEntityAdapter<CalculatorInput>( {
    selectId: ( calculatorInput ) => calculatorInput.id,
    sortComparer: ( a, b ) => Number( a.sequence ) - Number( b.sequence )
} );

// -----------------------------------------------------------------------------------
// interfaces & initialState

type AdditionalFields = {};

type ICalculatorInputReducer = EntityState<CalculatorInput> & AdditionalFields;

const additionalFields: AdditionalFields = {};

const initialState: ICalculatorInputReducer = Object.assign( {}, adaptor.getInitialState(), additionalFields );

// -----------------------------------------------------------------------------------
// reducer

const CalculatorInputReducer = createReducer( initialState, builder => {

} );

// -----------------------------------------------------------------------------------
// selectorsHeaderCell

const calculatorInputSelectors = adaptor.getSelectors<RootState>( ( state ) => state.calculatorInputs );

// -----------------------------------------------------------------------------------
// exports

export {
    CalculatorInputReducer,
    initialState as CalculatorInputReducerInit,
    calculatorInputSelectors
};