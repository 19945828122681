



// -------------------------------------------------------------------------------------

import { API } from "../../api-services";
import { ID } from "../../utlis/commonInterfaces";
import { GetUserInputsArg, GetUserInputsTriangulationArg, UpdateUserInputArg, UpdateUserInputTriangulationArg } from "../Actions/userInput.actions";

const url = "cost/calculation/";

const triangulationUrl = "/cost/triangulation_calculation/";

// -------------------------------------------------------------------------------------

export default {
    get ( arg: GetUserInputsArg ) {
        return API.get( url, {
            category: arg.category,
            /** RawMaterialCost/ProcessCost */
            object_id: arg.cardCostId
        },0 );
    },

    getTriangulation ( arg: GetUserInputsTriangulationArg ) {
        return API.get( triangulationUrl, {
            calculator: arg.cardCostId
        } );
    },

    updateSelection ( category: number, cardCostId: ID, calculators: any ) {
        return API.post( url, {
            object_id: cardCostId,
            calculators,
            category
        } );
    },
    update ( arg: UpdateUserInputArg ) {
        return API.put( url + arg.id + "/", arg.updates,{},0 );
    },
    updateTriangulation ( arg: UpdateUserInputTriangulationArg ) {
        return API.put( triangulationUrl + arg.id + "/", arg.updates, { calculator: arg.calculator } );
    }
};