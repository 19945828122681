import * as React from "react";
import Box from "@mui/material/Box";

import {
  Backdrop,
  ButtonProps,
  Divider,
  IconButton,
  Modal,
  Skeleton,
  Tooltip,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import CancelIcon from "@mui/icons-material/Cancel";
import {
  DataGrid,
  GridCsvExportMenuItem,
  GridCsvExportOptions,
  GridToolbarContainer,
  GridToolbarExportContainer,
} from "@mui/x-data-grid";
import { useRouteParams } from "../../ui-reusable-component/useRouteParams";
import { API } from "../../api-services";
import useWindowDimensions from "../../ui-reusable-component/useWindowDimensions";
import Swal from "sweetalert2";
import DeleteIcon from "@mui/icons-material/Delete";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: "60vw",
  maxWidth: "80vw",
  // height: '80vh',
  bgcolor: "background.paper",
  borderRadius: "10px",
  p: 1,
};
const csvOptions: GridCsvExportOptions = {
  fileName: "Table In CSV",
  delimiter: ",",
  utf8WithBom: true,
};

const CustomExportButton = (props: ButtonProps) => (
  <GridToolbarExportContainer {...props}>
    <GridCsvExportMenuItem options={csvOptions} />
  </GridToolbarExportContainer>
);

const CustomToolbar = () => (
  <GridToolbarContainer style={{ height: "1.5rem", marginBottom: "0.5rem" }}>
    <span style={{ position: "absolute", right: "0rem" }}>
      {/* <GridToolbarColumnsButton /> */}
      {/* <GridToolbarDensitySelector /> */}
      <CustomExportButton />
    </span>
  </GridToolbarContainer>
);

interface IWatermarkModelProps {
  isOpen: any;
  onCloseModal: any;
  itemId: any;
  useraction?: any;
  getMaterialData?: any;
  setAnchorEl?: any;
  categoryName?: any;
}

function InfoRawMaterialModal(props: IWatermarkModelProps) {
  const {
    isOpen,
    onCloseModal,
    itemId,
    useraction,
    getMaterialData,
    setAnchorEl,
  } = props;
  const { revisionId, categoryName } = useRouteParams<any>();
  const { height, width } = useWindowDimensions();
  const [infoData, setInfoData] = React.useState<any>([]);
  const [isLoading, setIsLoading] = React.useState<any>(false);
  const [error, setError] = React.useState<any>(false);
  const getInfoData = () => {
    setError(false);
    setIsLoading(true);
    API.get(
      "/api/db/check_variant/",
      {
        type: "materialcost",
        id: itemId?.id,
        revision: revisionId,
      },
      0
    )
      .then((res: any) => {
        setIsLoading(false);
        let rows: any = [];
        const dataGridDataGenerator: any = res.data?.map(
          (infoItem: any, index: any) => {
            let objShared: any = {
              id: index + 1,
              ...infoItem,
            };
            rows.push(objShared);
          }
        );
        setInfoData(rows);
      })
      .catch((err: any) => {
        setError(true);
        setIsLoading(false);
      });
  };
  React.useEffect(() => {
    getInfoData();
  }, []);

  var Columns: any = [
    // {
    //   field: "id", headerName: 'Name', hide: true,
    //   minWidth: 50,
    //   flex: 1,
    // },
    {
      field: "project",
      headerName: "Project Name",
      width: width > 1400 ? 250 : 220,
    },

    {
      field: "part_name",
      headerName: "Part Name",
      minWidth: width > 1400 ? 300 : 220,
      flex: 1,
      renderCell: (cellValues: any) => {
        return (
          <>
            <Tooltip
              sx={{ bacgroundColor: "primary.light", color: "primary.main" }}
              title={cellValues?.row?.part_name}
            >
              <p>{cellValues?.row?.part_name}</p>
            </Tooltip>
          </>
        );
      },
    },

    {
      field: "part_no",
      headerName: "Part No",
      minWidth: width > 1400 ? 300 : 220,
      flex: 1,
      rendeCell: (cellvalues: any) => {
        return (
          <>
            <Tooltip
              sx={{ bacgroundColor: "primary.light", color: "primary.main" }}
              title={cellvalues?.row?.part_no}
            >
              <p>{cellvalues?.row?.part_no}</p>
            </Tooltip>
          </>
        );
      },
    },
    {
      field: "costing_name",
      headerName: "Costing/Scenario",
      minWidth: width > 1400 ? 300 : 220,
      flex: 1,
    },
  ];

  return (
    <div>
      <Modal
        open={isOpen}
        onClose={onCloseModal}
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Box sx={style}>
          <Box sx={{ display: "flex", alignItems: "center", height: "2.5rem" }}>
            <Typography
              style={{
                fontSize: "1rem",
                fontWeight: "600",
                width: "100%",
                textAlign: "center",
                marginRight: "-2rem",
              }}
            >
              Raw Material Information ( {itemId?.material_code} )
            </Typography>
            <CancelIcon
              titleAccess="Close"
              sx={{ cursor: "pointer", margin: "0.5rem" }}
              onClick={onCloseModal}
            />
          </Box>
          <Divider
            sx={{
              borderColor: "primary.light",
              margin: "0 1rem",
              marginBottom: "1rem",
            }}
          />
          {
            <Box
              sx={{ height: "65vh", width: "100%" }}
              adl-scrollbar="true"
              adl-scrollbar-width="0.3"
            >
              {!isLoading && infoData ? (
                infoData?.length > 0 ? (
                  <DataGrid
                    headerHeight={48}
                    rowHeight={36}
                    rows={infoData && infoData}
                    columns={Columns}
                    density="compact"
                    // autoHeight={true}
                    components={{
                      Toolbar: () => {
                        return CustomToolbar();
                      },
                    }}
                    hideFooter={true}
                    hideFooterPagination={true}
                    sx={{
                      "&.MuiDataGrid-root .MuiDataGrid-cell": {
                        borderBottomColor: "primary.light",
                      },
                      "& ::-webkit-scrollbar": {
                        width: "0.4rem",
                        height: "0.4rem",
                      },
                      "& ::-webkit-scrollbar-thumb": {
                        // background: $thumb-color;
                        backgroundImage: "linear-gradient(#09C6F9, #045DE9)",
                        borderRadius: "10px",
                      },
                      "& ::- webkit-scrollbar-thumb: hover ": {
                        // width: "4px",
                        backgroundColor: "#045DE9 !important",
                      },
                      "::-webkit-scrollbar-track": {
                        backgroundColor: "#e1e1f3 !important",
                      },
                    }}
                  />
                ) : !error ? (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100%",
                    }}
                  >
                    This is currently unused and can be removed if deemed
                    unnecessary.
                    {useraction && useraction?.includes("D") && (
                      <IconButton
                        title="Delete"
                        sx={{
                          padding: "0.5rem",
                          cursor: "pointer",
                          color: "red",
                        }}
                        onClick={() => {
                          Swal.fire({
                            title: "Delete material",
                            text: "Are you sure you want to delete ?",
                            icon: "error",
                            confirmButtonText: "Yes, delete it!",
                            showCancelButton: true,
                            customClass: {
                              container: "swal2Container",
                            },
                          }).then((confirm) => {
                            if (confirm) {
                              API.delete(
                                `/api/db/material_cost/${itemId?.id}/`,
                                {},
                                0
                              )
                                .then((res: any) => {
                                  setAnchorEl(null);
                                  getMaterialData(categoryName);
                                  onCloseModal();
                                })
                                .catch((err: any) => {});
                            }
                          });
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    )}
                  </Box>
                ) : (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      gap:'0.6rem',
                      height:'100%'
                    }}
                  >
                    Something went wrong, please{" "}
                    <span onClick={()=>getInfoData()} style={{ textDecoration: "underline",cursor:'pointer' }}>retry</span>
                  </Box>
                )
              ) : (
                <Box>
                  <Skeleton animation="wave" />
                  <Skeleton animation="wave" />
                  <Skeleton animation="wave" />
                  <Skeleton animation="wave" />
                  <Skeleton animation="wave" />
                  <Skeleton animation="wave" />
                  <Skeleton animation="wave" />
                  <Skeleton animation="wave" />
                  <Skeleton animation="wave" />
                  <Skeleton animation="wave" />
                  <Skeleton animation="wave" />
                  <Skeleton animation="wave" />
                  <Skeleton animation="wave" />
                  <Skeleton animation="wave" />
                  <Skeleton animation="wave" />
                  <Skeleton animation="wave" />
                  <Skeleton animation="wave" />
                  <Skeleton animation="wave" />
                  <Skeleton animation="wave" />
                  <Skeleton animation="wave" />
                  <Skeleton animation="wave" />
                  <Skeleton animation="wave" />
                </Box>
              )}
            </Box>
          }
        </Box>
      </Modal>
    </div>
  );
}
export default InfoRawMaterialModal;
