import React from "react";
import { getResponseDisplayType } from "./ChatRightSection";
import { Box, Typography } from "@mui/material";
import {
  DataGrid,
  GridColDef,
  GridToolbar,
  GridToolbarContainer,
} from "@mui/x-data-grid";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DoneIcon from "@mui/icons-material/Done";
import { ADMIN } from "../Redux/Services/admin.service";

// Define types
type ResponseDisplay = "table" | "normal";
interface ParsedRow {
  [key: string]: string;
}

interface ResponseProps {
  responseData: string; // The raw response
  sender: string; // The sender of the response
}

function CustomToolbar() {
  return (
    <GridToolbarContainer
      sx={{
        display: "flex", // Use flexbox layout
        justifyContent: "flex-end", // Align items to the right
        //   padding: "8px 16px", // Add some padding
        backgroundColor: "#f5f5f5", // Optional: Add a background color
        borderBottom: "1px solid #ccc", // Optional: Add a bottom border
      }}
    >
      <GridToolbar />
    </GridToolbarContainer>
  );
}
// Utility function to format the response text
function formatResponse(response: string): string[] {
  // Clean the response by replacing escaped characters
  const cleanedResponse = response?.replace(/\\n/g, "\n")?.replace(/\\'/g, "'");

  // Split the response into paragraphs by double newlines
  return cleanedResponse?.split("\n\n")?.map((paragraph) => paragraph?.trim());
}

const ResponseRenderer: React.FC<ResponseProps> = ({
  responseData,
  sender,
}) => {
  const { type, tableData } = getResponseDisplayType(responseData);
  const [isCopied, setIsCopied] = React.useState<boolean>(false);

  const handleCopySuccess = () => {
    setIsCopied(true); // Show tick icon
    setTimeout(() => setIsCopied(false), 3000); // Revert back to copy icon after 3 seconds
  };

  const copyToClipboard = (content: string) => {
    navigator.clipboard
      .writeText(content)
      .then(() => handleCopySuccess())
      .catch(() => ADMIN.toast.error("Failed to copy text to clipboard"));
  };

  const handleTextCopy = () => {
    const content = formatResponse(responseData)?.join("\n\n");
    copyToClipboard(content);
  };

  if (type === "table" && tableData) {
    // Check if tableData is valid and contains rows
    if (!Array.isArray(tableData) || tableData.length === 0) {
      return <Typography variant="body2">No table data available.</Typography>;
    }

    // Dynamically generate columns based on tableData keys
    const columns: GridColDef[] = Object.keys(tableData[0])?.map((key) => ({
      field: key,
      headerName: key?.charAt(0)?.toUpperCase() + key?.slice(1), // Capitalize headers
      flex: 1, // Adjust column width
    }));

    // Ensure tableData does not accidentally include headers as rows
    const rows = tableData
      .filter((row) => {
        // Exclude rows where all values match the column headers
        return !Object.keys(row).every((key) => row[key] === key);
      })
      .map((row, index) => ({
        id: index, // Add a unique id for Data Grid row rendering
        ...row,
      }));

    return (
      <Box
        sx={{ height: 600, width: "49vw" }}
        adl-scrollbar="true"
        adl-scrollbar-width="0.3"
      >
        <DataGrid
          rows={rows}
          columns={columns}
          components={{
            Toolbar: CustomToolbar, // Add the toolbar for built-in actions like export
          }}
          //@ts-ignore

          density="compact"
          sx={{ mt: 1.5 }}
          autoHeight
        />
      </Box>
    );
  }

  // Default rendering for plain text
  return (
    <Box>
      {sender !== "user" && (
        <Box>
          {isCopied ? (
            <DoneIcon
              fontSize="small"
              sx={{
                color: "gray",
                float: "right",
                fontSize: "1.3rem",
              }}
            />
          ) : (
            <ContentCopyIcon
              fontSize="small"
              sx={{
                cursor: "pointer",
                color: "gray",
                float: "right",
                fontSize: "1.3rem",
              }}
              onClick={handleTextCopy}
            />
          )}
        </Box>
      )}
      {formatResponse(responseData)?.map((paragraph, index) => (
        <Typography key={index} variant="body2" sx={{ margin: "0.5rem" }}>
          {paragraph?.split("\n")?.map((line, i) => (
            <React.Fragment key={i}>
              {line}
              <br />
            </React.Fragment>
          ))}
        </Typography>
      ))}
    </Box>
  );
};

export default ResponseRenderer;
