import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { API } from "../api-services";
import ArchitectureModule from "./ArchiteactureModule";
import BomModule from "./BomModule";
import CostingModule from "./CostingModule";
import FeatureModule from "./FeatureModule";
import IdeaModule from "./IdeaModule";
import MediaModule from "./MediaModule";
import styles from "./ViewComponents.module.scss";
import ReplyIcon from "@mui/icons-material/Reply";
import { Button, Menu, MenuItem, Skeleton } from "@mui/material";
import CostingTopLevelTable from "./CostingTopVoultlevel";
import ValidationImgs from "../Assets/images/validationImg.png";
import { useBaseModal } from "../ComponentUI/SearchPage/useBaseModal";
import { DialogComponent } from "../utlis/DialogComponent";
import DownloadIcon from "@mui/icons-material/Download";
import { CostingScenarioChartModal } from "./CostingScenario/CostingScenarioChartModal";

import DependsScenariographIcon from "../Assets/images/svgs/chartdependent.svg";
import IndependsScenariographIcon from "../Assets/images/svgs/chartindependent.svg";

export interface AllmodulecomponentsProps {}
const Allmodulecomponents = (props: AllmodulecomponentsProps) => {
  const { projectId, topVault, vault, treeabbr, leafNode, Allmodule } =
    useParams<any>();
  const history = useHistory<any>();
  const [vaultToAbbr, setVaultToAbbr] = useState<any>();
  const [modulePermissionData, setmodulePermissionData] = useState<any>();
  const [allaData, setAllData] = useState<any>();
  const [ExistData, setExistData] = useState<any>();
  const [viewDataLoader, setviewDataLoader] = useState<any>(false);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const [contentText, setContentText] = React.useState<any>();
  const [iconText, setIconText] = React.useState<any>("success");

  const open = Boolean(anchorEl);
  const ModalOpen = useBaseModal();
  const openStatusContent = useBaseModal();
  const [anchorEl1, setAnchorEl1] = React.useState<null | HTMLElement>(null);
  const open1 = Boolean(anchorEl1);

  const handleClose1 = () => {
    setAnchorEl1(null);
  };
  const OpenExpendHanler = (
    e: React.MouseEvent<HTMLButtonElement>,
    module: any
  ) => {
    if (module === "BOM") {
      // @ts-ignore
      history.push(`/view/${projectId}/${topVault}/${vault}/${treeabbr}/true`);
    } else if (module === "Costing") {
      if (ExistData?.scenario_exists) {
        setAnchorEl1(e.currentTarget);
      } else {
        history.push(
          `/view/${projectId}/${topVault}/${vault}/${treeabbr}/costing`
        );
      }
    }
  };

  const [TopVaults, setTopVaults] = useState<any>([]);
  const [buttonHide, setButtonHide] = useState<any>(false);
  const [fetchedTopVaults, setFetchedTopVaults] = useState<any>(false);

  useEffect(() => {
    setButtonHide(false);
    setFetchedTopVaults(false);
    const apiForTable: any = API.get(`/circuit_diagram/arch_subsystem/`, {
      project: projectId,
      abbreviation: treeabbr == "product" ? 0 : treeabbr,
    }).then((res: any) => {
      const ProductGet: any = res.data?.filter((item: any) => {
        return item?.schematic != 0;
      });
      setFetchedTopVaults(true);
      if (ProductGet?.length > 0) {
        setTopVaults(ProductGet);
        setButtonHide(true);
      }
    });
  }, [projectId, treeabbr]);
  const topVaultKey: any =
    TopVaults &&
    TopVaults?.map((tvault: any) => {
      return tvault?.id;
    });
  const topVaults: any = topVaultKey?.slice(0, 5);
  const redirectToArch = () => {
    const apiForActivity: any = API.get(
      `/image_repository/ma_activity_mapping/`,
      {
        type: "architecture",
        project: projectId,
      }
    );
    sessionStorage.setItem("arch", window.location.href);
    if (fetchedTopVaults === true) {
      if (treeabbr == "product") {
        TopVaults &&
          history.push(
            `/architecture/${projectId}/0/0/${topVaults.toString()}`
          );
      } else {
        TopVaults &&
          history.push(
            `/architecture/${projectId}/${treeabbr}/0/${topVaults.toString()}`
          );
      }
    }
  };

  const toggleTopvault = () => {
    if (topVault !== 0) {
      if (treeabbr == "product") {
        return { top_vault: topVault };
      } else {
        return { vault: vault };
      }
    }
  };

  //******************************* Idea API call ************************************************************************************************* */

  useEffect(() => {
    setviewDataLoader(true);
    API.get("customer/view_data_module/", { ...toggleTopvault() }, 0)
      .then((res: any) => {
        // setModuleCount(res.data)
        setAllData(res.data);
        setviewDataLoader(false);
      })
      .catch((err: any) => {
        setviewDataLoader(false);
      });
  }, [topVault, vault]);

  useEffect(() => {
    API.get(
      "customer/view_data_module/",
      { ...toggleTopvault(), validated: true },
      0
    ).then((res: any) => {
      setExistData(res.data);
    });
  }, [topVault, vault]);

  useEffect(() => {
    if (topVault !== 0) {
      API.get("/customer/product_Module_mapping/", {
        top_vault_id: topVault,
      }).then((res: any) => {
        setmodulePermissionData(res.data);
      });
    }
  }, [topVault]);
  // ********************************************************************************Bom API Call***********************************************************

  // ********************************************************************Costing API Call ***********************************************************************

  useEffect(() => {
    if (treeabbr == "null") {
      API.get(
        `/customer/dashboard/`,
        { vault_id: vault, fetch_abbreviation: true },
        0
      ).then((response: any) => {
        setVaultToAbbr(response.data?.abbreviation);
      });
    }
  }, [vault]);

  const redirectToIdeas = () => {
    sessionStorage.setItem("idea", window.location.href);
    if (treeabbr == "product") {
      history.push(`/idea/${projectId}/${topVault}/VEH/${topVault}`);
    } else if (treeabbr == "null") {
      history.push(`/idea/${projectId}/${topVault}/${vaultToAbbr}/${vault}`);
    } else {
      history.push(`/idea/${projectId}/${topVault}/${treeabbr}/${vault}`);
    }
  };
  //   useEffect(()=>{
  // API.get("customer/view_data_module/",{ ...toggleTopvault()}).then((res:any)=>{
  //   setModuleCount(res.data)
  // })
  //   },[topVault,vault])

  const downLoadReport = (reportTypes: any) => {
    API.get("/customer/check_view_report/", {
      ...toggleTopvault(),
      type: reportTypes,
    }).then((res: any) => {
      if (res?.data?.status == "First Download") {
        openStatusContent.open();
        API.get(
          "/customer/view_data_report/",
          { ...toggleTopvault(), type: reportTypes },
          0
        )
          .then((res1: any) => {
            window.open(res1?.data, "_blank");
          })
          .catch((err) => {});

        setIconText("firstdownload");
        setContentText(res.data.message);
      }

      if (res?.data.status == "Completed") {
        window.open(res?.data?.url, "_blank");
      }
      if (res?.data.status == "In Progress") {
        setIconText("reportProgress");
        openStatusContent.open();
        setContentText(res.data.message);
      }
      if (res?.data.status == "Failed") {
        setIconText("failed");
        openStatusContent.open();
        setContentText(res.data.message);
      }
    });
    ModalOpen.open();
  };
  //*************************************************************** */ Rating API call /*********************************************************** */

  // useEffect(() => {

  //   function Getapicall() {
  //     API.get("/customer/customer_rating/", { get_rating_status: true, module: "viewdata" }).then((res: any) => {

  //       if (res?.data?.rating_exists == false) {
  //         ratingModal.open()
  //       }
  //     })

  //   }

  //   setTimeout(Getapicall, 3000)

  // }, [Counter])

  const ScenarioChartModal = useBaseModal();

  const ScenarioChartModalHandler = () => {
    ScenarioChartModal.open();
  };

  const Costingroute = () => {
    history.push(`/view/${projectId}/${topVault}/${vault}/${treeabbr}/costing`);
  };

  const scenarioHandler = () => {
    API.get("/cost/scenario_product/", {
      vault: vault,
      get_ancestors: true,
      top_vault: topVault,
    })
      .then((res: any) => {
        sessionStorage.removeItem("nodeCostingScenario");
        sessionStorage.setItem(
          "nodeCostingScenario",
          JSON.stringify(res?.data?.ancestors)
        );
        sessionStorage.setItem("addScenarioStatus", "3");
        window.open(
          `/#/costingscenario/${projectId}/${
            res?.data?.project_name
          }/${topVault}/${vault}/1/${
            treeabbr ? (treeabbr == "product" ? "PRODUCT" : treeabbr) : "none"
          }/0`
        );
      })
      .catch((err) => {});
  };

  return (
    <div className={styles.submodulesContainer}>
      <>
        {ScenarioChartModal.isOpen && (
          <CostingScenarioChartModal
            isOpen={ScenarioChartModal.isOpen}
            onCloseModal={ScenarioChartModal.close}
            query={toggleTopvault()}
            chartType={ExistData?.scenario_created}
          />
        )}

        <div className={styles.downloadBtn}>
          <Button
            size="small"
            // id="basic-button"
            aria-controls={open ? "basic-menu" : undefined}
            // aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            disabled={true}
            // onClick={handleClick}
            onClick={() => downLoadReport("excel")}
            endIcon={<DownloadIcon />}
          >
            <span style={{ marginRight: "-0.5rem", fontSize: "1rem" }}>
              Download
            </span>
          </Button>
        </div>
      </>
      <DialogComponent
        isOpen={openStatusContent.isOpen}
        onClose={openStatusContent.close}
        content={contentText}
        type={iconText}
      />

      {viewDataLoader ? (
        <>
          <Box
            sx={{
              display: "flex",
              columnGap: "1rem",
              flexDirection: "row",
              // height: "4rem",
              marginTop: "0 rem",
            }}
          >
            <Skeleton variant="rectangular" width={"20%"} height={"100%"} />
            <Skeleton variant="rectangular" width={"20%"} height={"100%"} />
            <Skeleton variant="rectangular" width={"20%"} height={"100%"} />
            <Skeleton variant="rectangular" width={"20%"} height={"100%"} />
            <Skeleton variant="rectangular" width={"20%"} height={"100%"} />
          </Box>
        </>
      ) : (
        <>
          {allaData?.architecture == false &&
          allaData?.bom == false &&
          allaData?.costing == false &&
          allaData?.features == false &&
          allaData?.ideas == false &&
          allaData?.media == false ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "92%",
                flexDirection: "row",
              }}
            >
              <div className={styles.validatinContainer}>
                <img src={ValidationImgs} style={{ width: "13rem" }} />
                <Typography
                  style={{ fontSize: "15px", textAlign: "center" }}
                  color="primary.main"
                >
                  We are working on it...
                </Typography>
              </div>
            </div>
          ) : (
            <>
              {treeabbr == "null" ? (
                <>
                  <Box className={styles.col3Div}>
                    {modulePermissionData &&
                      modulePermissionData?.includes("BOM") && (
                        <>
                          {allaData &&
                            allaData?.bom == true &&
                            (ExistData?.bom ? (
                              <div className={styles.bomContainer}>
                                <div className={styles.header}>
                                  <Typography
                                    style={{ fontSize: "12px" }}
                                    color="primary.main"
                                  >
                                    BOM
                                  </Typography>
                                  <span
                                    onClick={(e: any) =>
                                      OpenExpendHanler(e, "BOM")
                                    }
                                    className={styles.pageView}
                                  >
                                    <ReplyIcon
                                      titleAccess="Go to BOM"
                                      style={{
                                        cursor: "pointer",
                                        transform: "scaleX(-1)",
                                      }}
                                    />
                                  </span>
                                </div>
                                <BomModule />
                              </div>
                            ) : (
                              <div className={styles.bomContainer}>
                                <div className={styles.header}>
                                  <Typography
                                    style={{ fontSize: "12px" }}
                                    color="primary.main"
                                  >
                                    BOM
                                  </Typography>
                                </div>
                                <div className={styles.validatinContainer}>
                                  <img
                                    src={ValidationImgs}
                                    style={{ width: "10rem" }}
                                  />
                                  <Typography
                                    style={{
                                      fontSize: "15px",
                                      textAlign: "center",
                                    }}
                                    color="primary.main"
                                  >
                                    We are working on it...
                                  </Typography>
                                </div>
                              </div>
                            ))}
                        </>
                      )}
                    {
                      <>
                        {allaData &&
                          allaData?.costing == true &&
                          (ExistData?.costing ? (
                            <div className={styles.costingContainer}>
                              <div className={styles.header}>
                                <Typography
                                  style={{ fontSize: "12px" }}
                                  color="primary.main"
                                >
                                  Costing
                                </Typography>
                                <span
                                  style={{
                                    width: "5rem",
                                    display: "flex",
                                    alignItems: "center",
                                    columnGap: "1rem",
                                  }}
                                ></span>
                                <span className={styles.pageView}>
                                  {ExistData?.scenario_exists &&
                                    (ExistData?.scenario_created ? (
                                      <img
                                        src={IndependsScenariographIcon}
                                        title="Scenario Independent Chart"
                                        style={{
                                          cursor: "pointer",
                                          height: "2rem",
                                        }}
                                        onClick={() =>
                                          ScenarioChartModalHandler()
                                        }
                                      />
                                    ) : (
                                      <img
                                        src={DependsScenariographIcon}
                                        title="Scenario Dependent Chart"
                                        style={{
                                          cursor: "pointer",
                                          height: "2rem",
                                        }}
                                        onClick={() =>
                                          ScenarioChartModalHandler()
                                        }
                                      />
                                    ))}

                                  <ReplyIcon
                                    aria-controls={
                                      open1 ? "basic-menu" : undefined
                                    }
                                    aria-haspopup="true"
                                    aria-expanded={open1 ? "true" : undefined}
                                    onClick={(e: any) =>
                                      OpenExpendHanler(e, "Costing")
                                    }
                                    titleAccess="Go to Costing"
                                    style={{
                                      cursor: "pointer",
                                      transform: "scaleX(-1)",
                                    }}
                                  />
                                  {ExistData?.scenario_exists && (
                                    <Menu
                                      id="basic-menu"
                                      anchorEl={anchorEl1}
                                      open={open1}
                                      onClose={handleClose1}
                                      MenuListProps={{
                                        "aria-labelledby": "basic-button",
                                      }}
                                    >
                                      <MenuItem onClick={() => Costingroute()}>
                                        Main Costing
                                      </MenuItem>
                                      <MenuItem
                                        onClick={() => scenarioHandler()}
                                      >
                                        Scenario
                                      </MenuItem>
                                    </Menu>
                                  )}
                                </span>
                              </div>
                              <CostingModule />
                            </div>
                          ) : (
                            <div className={styles.costingContainer}>
                              <div className={styles.header}>
                                <Typography
                                  style={{ fontSize: "12px" }}
                                  color="primary.main"
                                >
                                  Costing
                                </Typography>
                                {/* Hariharan added reply redirect*/}
                                {ExistData?.scenario_exists &&<ReplyIcon
                                    aria-controls={
                                      open1 ? "basic-menu" : undefined
                                    }
                                    aria-haspopup="true"
                                    aria-expanded={open1 ? "true" : undefined}
                                    onClick={(e: any) =>
                                      OpenExpendHanler(e, "Costing")
                                    }
                                    titleAccess="Go to Costing"
                                    style={{
                                      cursor: "pointer",
                                      transform: "scaleX(-1)",
                                    }}
                                  />}
                                  {ExistData?.scenario_exists && (
                                      <Menu
                                        id="basic-menu"
                                        anchorEl={anchorEl1}
                                        open={open1}
                                        onClose={handleClose1}
                                        MenuListProps={{
                                          "aria-labelledby": "basic-button",
                                        }}
                                      >
                                        {/* <MenuItem
                                          onClick={() => Costingroute()}
                                        >
                                          Main Costing
                                        </MenuItem> */}
                                        <MenuItem
                                          onClick={() => scenarioHandler()}
                                        >
                                          Scenario
                                        </MenuItem>
                                      </Menu>
                                    )}
                              </div>

                              <div>
                                <div className={styles.validatinContainer}>
                                  <img
                                    src={ValidationImgs}
                                    style={{ width: "10rem" }}
                                  />
                                  <Typography
                                    style={{
                                      fontSize: "15px",
                                      textAlign: "center",
                                    }}
                                    color="primary.main"
                                  >
                                    We are working on it...
                                  </Typography>
                                </div>
                              </div>
                            </div>
                          ))}
                      </>
                    }
                    {modulePermissionData &&
                      modulePermissionData?.includes("Ideas") && (
                        <>
                          {allaData &&
                            allaData?.ideas == true &&
                            (ExistData?.ideas ? (
                              <div className={styles.ideasContainer}>
                                <div className={styles.header}>
                                  <Typography
                                    style={{ fontSize: "12px" }}
                                    color="primary.main"
                                  >
                                    Ideas
                                  </Typography>
                                  <span
                                    onClick={() => redirectToIdeas()}
                                    className={styles.pageView}
                                  >
                                    <ReplyIcon
                                      titleAccess="Go to Ideas"
                                      style={{
                                        cursor: "pointer",
                                        transform: "scaleX(-1)",
                                      }}
                                    />
                                  </span>
                                </div>
                                <IdeaModule />
                              </div>
                            ) : (
                              <div className={styles.ideasContainer}>
                                <div className={styles.header}>
                                  <Typography
                                    style={{ fontSize: "12px" }}
                                    color="primary.main"
                                  >
                                    Ideas
                                  </Typography>
                                </div>
                                <div>
                                  <div className={styles.validatinContainer}>
                                    <img
                                      src={ValidationImgs}
                                      style={{ width: "10rem" }}
                                    />
                                    <Typography
                                      style={{
                                        fontSize: "15px",
                                        textAlign: "center",
                                      }}
                                      color="primary.main"
                                    >
                                      We are working on it...
                                    </Typography>
                                  </div>
                                </div>
                              </div>
                            ))}
                        </>
                      )}
                  </Box>
                </>
              ) : treeabbr == "product" ? (
                <>
                  <Box className={styles.col6Div}>
                    {modulePermissionData &&
                      modulePermissionData?.includes("Media") &&
                      allaData &&
                      allaData?.media == true &&
                      (ExistData?.media ? (
                        <div className={styles.mediaContainer}>
                          <MediaModule />
                        </div>
                      ) : (
                        <div className={styles.mediaContainer}>
                          <div className={styles.header}>
                            <Typography
                              style={{ fontSize: "12px" }}
                              color="primary.main"
                            >
                              Media
                            </Typography>
                          </div>
                          <div>
                            <div className={styles.validatinContainer}>
                              <img
                                src={ValidationImgs}
                                style={{ width: "10rem" }}
                              />
                              <Typography
                                style={{
                                  fontSize: "15px",
                                  textAlign: "center",
                                }}
                                color="primary.main"
                              >
                                We are working on it...
                              </Typography>
                            </div>
                          </div>
                        </div>
                      ))}
                    {modulePermissionData &&
                      modulePermissionData?.includes("Architecture") && (
                        <>
                          {allaData &&
                            allaData?.architecture == true &&
                            (ExistData?.architecture ? (
                              <div className={styles.archContainer}>
                                <>
                                  <div className={styles.header}>
                                    <Typography
                                      style={{ fontSize: "12px" }}
                                      color="primary.main"
                                    >
                                      Architecture
                                    </Typography>
                                    {buttonHide && (
                                      <span className={styles.pageView}>
                                        <ReplyIcon
                                          titleAccess="Go to Architecture"
                                          onClick={redirectToArch}
                                          style={{
                                            cursor: "pointer",
                                            transform: "scaleX(-1)",
                                          }}
                                        />
                                      </span>
                                    )}
                                  </div>
                                  <ArchitectureModule />
                                </>
                              </div>
                            ) : (
                              <div className={styles.archContainer}>
                                <div className={styles.header}>
                                  <Typography
                                    style={{ fontSize: "12px" }}
                                    color="primary.main"
                                  >
                                    Architecture
                                  </Typography>
                                </div>
                                <div>
                                  <div className={styles.validatinContainer}>
                                    <img
                                      src={ValidationImgs}
                                      style={{ width: "10rem" }}
                                    />
                                    <Typography
                                      style={{
                                        fontSize: "15px",
                                        textAlign: "center",
                                      }}
                                      color="primary.main"
                                    >
                                      We are working on it...
                                    </Typography>
                                  </div>
                                </div>
                              </div>
                            ))}
                        </>
                      )}
                    {modulePermissionData &&
                      modulePermissionData?.includes("Features") &&
                      allaData &&
                      allaData?.features == true &&
                      (ExistData?.features ? (
                        <div className={styles.featureContainer}>
                          <FeatureModule />
                        </div>
                      ) : (
                        <div className={styles.featureContainer}>
                          <div className={styles.header}>
                            <Typography
                              style={{ fontSize: "12px" }}
                              color="primary.main"
                            >
                              Features
                            </Typography>
                          </div>
                          <div>
                            <div className={styles.validatinContainer}>
                              <img
                                src={ValidationImgs}
                                style={{ width: "10rem" }}
                              />
                              <Typography
                                style={{
                                  fontSize: "15px",
                                  textAlign: "center",
                                }}
                                color="primary.main"
                              >
                                We are working on it...
                              </Typography>
                            </div>
                          </div>
                        </div>
                      ))}

                    {modulePermissionData &&
                      modulePermissionData?.includes("BOM") && (
                        <>
                          {allaData &&
                            allaData?.bom == true &&
                            (ExistData?.bom ? (
                              <div className={styles.bomContainer}>
                                <div className={styles.header}>
                                  <Typography
                                    style={{ fontSize: "12px" }}
                                    color="primary.main"
                                  >
                                    BOM
                                  </Typography>
                                  <span
                                    onClick={(e: any) =>
                                      OpenExpendHanler(e, "BOM")
                                    }
                                    className={styles.pageView}
                                  >
                                    <ReplyIcon
                                      titleAccess="Go to BOM"
                                      style={{
                                        cursor: "pointer",
                                        transform: "scaleX(-1)",
                                      }}
                                    />
                                  </span>
                                </div>
                                <BomModule />
                              </div>
                            ) : (
                              <div className={styles.bomContainer}>
                                <div className={styles.header}>
                                  <Typography
                                    style={{ fontSize: "11px" }}
                                    color="primary.main"
                                  >
                                    BOM
                                  </Typography>
                                </div>
                                <div className={styles.validatinContainer}>
                                  <img
                                    src={ValidationImgs}
                                    style={{ width: "10rem" }}
                                  />
                                  <Typography
                                    style={{
                                      fontSize: "15px",
                                      textAlign: "center",
                                    }}
                                    color="primary.main"
                                  >
                                    We are working on it...
                                  </Typography>
                                </div>
                              </div>
                            ))}
                        </>
                      )}

                    {modulePermissionData &&
                      modulePermissionData?.includes("Costing") && (
                        <>
                          {allaData &&
                            allaData?.costing == true &&
                            (ExistData?.costing ? (
                              <div className={styles.costingContainer}>
                                <div className={styles.header}>
                                  <Typography
                                    style={{ fontSize: "12px" }}
                                    color="primary.main"
                                  >
                                    Costing
                                  </Typography>
                                  <span
                                    style={{
                                      width: "5rem",
                                      display: "flex",
                                      alignItems: "center",
                                      columnGap: "1rem",
                                    }}
                                  ></span>
                                  <span className={styles.pageView}>
                                    {ExistData?.scenario_exists &&
                                      (ExistData?.scenario_created ? (
                                        <img
                                          src={IndependsScenariographIcon}
                                          title="Scenario Independent Chart"
                                          style={{
                                            cursor: "pointer",
                                            height: "2rem",
                                          }}
                                          onClick={() =>
                                            ScenarioChartModalHandler()
                                          }
                                        />
                                      ) : (
                                        <img
                                          src={DependsScenariographIcon}
                                          title="Scenario dependent Chart"
                                          style={{
                                            cursor: "pointer",
                                            height: "2rem",
                                          }}
                                          onClick={() =>
                                            ScenarioChartModalHandler()
                                          }
                                        />
                                      ))}
                                    <ReplyIcon
                                      aria-controls={
                                        open1 ? "basic-menu" : undefined
                                      }
                                      aria-haspopup="true"
                                      aria-expanded={open1 ? "true" : undefined}
                                      onClick={(e: any) =>
                                        OpenExpendHanler(e, "Costing")
                                      }
                                      titleAccess="Go to Costing"
                                      style={{
                                        cursor: "pointer",
                                        transform: "scaleX(-1)",
                                      }}
                                    />
                                    {ExistData?.scenario_exists && (
                                      <Menu
                                        id="basic-menu"
                                        anchorEl={anchorEl1}
                                        open={open1}
                                        onClose={handleClose1}
                                        MenuListProps={{
                                          "aria-labelledby": "basic-button",
                                        }}
                                      >
                                        <MenuItem
                                          onClick={() => Costingroute()}
                                        >
                                          Main Costing
                                        </MenuItem>
                                        <MenuItem
                                          onClick={() => scenarioHandler()}
                                        >
                                          Scenario
                                        </MenuItem>
                                      </Menu>
                                    )}
                                  </span>
                                </div>
                                <CostingTopLevelTable />
                              </div>
                            ) : (
                              <div className={styles.costingContainer}>
                                <div className={styles.header}>
                                  <Typography
                                    style={{ fontSize: "12px" }}
                                    color="primary.main"
                                  >
                                    Costing
                                  </Typography>
                                  {/* Hariharan added reply redirect*/}
                                 { ExistData?.scenario_exists &&<ReplyIcon
                                      aria-controls={
                                        open1 ? "basic-menu" : undefined
                                      }
                                      aria-haspopup="true"
                                      aria-expanded={open1 ? "true" : undefined}
                                      onClick={(e: any) =>
                                        OpenExpendHanler(e, "Costing")
                                      }
                                      titleAccess="Go to Costing"
                                      style={{
                                        cursor: "pointer",
                                        transform: "scaleX(-1)",
                                      }}
                                    />}
                                    {ExistData?.scenario_exists && (
                                      <Menu
                                        id="basic-menu"
                                        anchorEl={anchorEl1}
                                        open={open1}
                                        onClose={handleClose1}
                                        MenuListProps={{
                                          "aria-labelledby": "basic-button",
                                        }}
                                      >
                                        {/* <MenuItem
                                          onClick={() => Costingroute()}
                                        >
                                          Main Costing
                                        </MenuItem> */}
                                        <MenuItem
                                          onClick={() => scenarioHandler()}
                                        >
                                          Scenario
                                        </MenuItem>
                                      </Menu>
                                    )}
                                </div>
                                <div>
                                  <div className={styles.validatinContainer}>
                                    <img
                                      src={ValidationImgs}
                                      style={{ width: "10rem" }}
                                    />
                                    <Typography
                                      style={{
                                        fontSize: "15px",
                                        textAlign: "center",
                                      }}
                                      color="primary.main"
                                    >
                                      We are working on it...
                                    </Typography>
                                  </div>
                                </div>
                              </div>
                            ))}
                        </>
                      )}

                    {modulePermissionData &&
                      modulePermissionData?.includes("Ideas") && (
                        <>
                          {allaData &&
                            allaData?.ideas == true &&
                            (ExistData?.ideas ? (
                              <div className={styles.ideasContainer}>
                                <div className={styles.header}>
                                  <Typography
                                    style={{ fontSize: "12px" }}
                                    color="primary.main"
                                  >
                                    Ideas
                                  </Typography>
                                  <span
                                    onClick={() => redirectToIdeas()}
                                    className={styles.pageView}
                                  >
                                    <ReplyIcon
                                      style={{
                                        cursor: "pointer",
                                        transform: "scaleX(-1)",
                                      }}
                                      titleAccess="GO to Ideas"
                                    />
                                  </span>
                                </div>
                                <IdeaModule />
                              </div>
                            ) : (
                              <div className={styles.ideasContainer}>
                                <div className={styles.header}>
                                  <Typography
                                    style={{ fontSize: "12px" }}
                                    color="primary.main"
                                  >
                                    Ideas
                                  </Typography>
                                  {/* <Typography
                          onClick={() => redirectToIdeas()}
                          className={styles.pageView}
                        >
                          <ReplyIcon
                            style={{
                              fontSize: "16px",
                              transform: "scaleX(-1)",
                            }}
                          />
                        </Typography> */}
                                </div>
                                <div>
                                  <div className={styles.validatinContainer}>
                                    <img
                                      src={ValidationImgs}
                                      style={{ width: "10rem" }}
                                    />
                                    <Typography
                                      style={{
                                        fontSize: "15px",
                                        textAlign: "center",
                                      }}
                                      color="primary.main"
                                    >
                                      We are working on it...
                                    </Typography>
                                  </div>
                                </div>
                              </div>
                            ))}
                        </>
                      )}
                  </Box>
                </>
              ) : (
                <>
                  <Box className={styles.col6Div}>
                    {modulePermissionData &&
                      modulePermissionData?.includes("Media") &&
                      allaData &&
                      allaData?.media == true &&
                      (ExistData?.media ? (
                        <div className={styles.mediaContainer}>
                          <MediaModule />
                        </div>
                      ) : (
                        <div className={styles.mediaContainer}>
                          <div className={styles.header}>
                            <Typography
                              style={{ fontSize: "12px" }}
                              color="primary.main"
                            >
                              Media
                            </Typography>
                          </div>
                          <div>
                            <div className={styles.validatinContainer}>
                              <img
                                src={ValidationImgs}
                                style={{ width: "10rem" }}
                              />
                              <Typography
                                style={{
                                  fontSize: "15px",
                                  textAlign: "center",
                                }}
                                color="primary.main"
                              >
                                We are working on it...
                              </Typography>
                            </div>
                          </div>
                        </div>
                      ))}
                    {modulePermissionData &&
                      modulePermissionData?.includes("Architecture") && (
                        <>
                          {allaData &&
                            allaData?.architecture == true &&
                            (ExistData?.architecture ? (
                              <div className={styles.archContainer}>
                                <>
                                  <div className={styles.header}>
                                    <Typography
                                      style={{ fontSize: "12px" }}
                                      color="primary.main"
                                    >
                                      Architecture
                                    </Typography>
                                    {buttonHide && (
                                      <span className={styles.pageView}>
                                        <ReplyIcon
                                          titleAccess="GO to Architecture"
                                          onClick={redirectToArch}
                                          style={{
                                            cursor: "pointer",
                                            transform: "scaleX(-1)",
                                          }}
                                        />
                                      </span>
                                    )}
                                  </div>
                                  <ArchitectureModule />
                                </>
                              </div>
                            ) : (
                              <div className={styles.archContainer}>
                                <div className={styles.header}>
                                  <Typography
                                    style={{ fontSize: "12px" }}
                                    color="primary.main"
                                  >
                                    Architecture
                                  </Typography>
                                </div>
                                <div>
                                  <div className={styles.validatinContainer}>
                                    <img
                                      src={ValidationImgs}
                                      style={{ width: "10rem" }}
                                    />
                                    <Typography
                                      style={{
                                        fontSize: "15px",
                                        textAlign: "center",
                                      }}
                                      color="primary.main"
                                    >
                                      We are working on it...
                                    </Typography>
                                  </div>
                                </div>
                              </div>
                            ))}
                        </>
                      )}
                    {modulePermissionData &&
                      modulePermissionData?.includes("Features") &&
                      allaData &&
                      allaData?.features == true &&
                      (ExistData?.features ? (
                        <div className={styles.featureContainer}>
                          <FeatureModule />
                        </div>
                      ) : (
                        <div className={styles.featureContainer}>
                          <div className={styles.header}>
                            <Typography
                              style={{ fontSize: "12px" }}
                              color="primary.main"
                            >
                              Features
                            </Typography>
                          </div>
                          <div>
                            <div className={styles.validatinContainer}>
                              <img
                                src={ValidationImgs}
                                style={{ width: "10rem" }}
                              />
                              <Typography
                                style={{
                                  fontSize: "15px",
                                  textAlign: "center",
                                }}
                                color="primary.main"
                              >
                                We are working on it...
                              </Typography>
                            </div>
                          </div>
                        </div>
                      ))}

                    {modulePermissionData &&
                      modulePermissionData?.includes("BOM") && (
                        <>
                          {allaData &&
                            allaData?.bom == true &&
                            (ExistData?.bom ? (
                              <div className={styles.bomContainer}>
                                <div className={styles.header}>
                                  <Typography
                                    style={{ fontSize: "12px" }}
                                    color="primary.main"
                                  >
                                    BOM
                                  </Typography>
                                  <span
                                    onClick={(e: any) =>
                                      OpenExpendHanler(e, "BOM")
                                    }
                                    className={styles.pageView}
                                  >
                                    <ReplyIcon
                                      titleAccess="GO to BOM"
                                      style={{
                                        cursor: "pointer",
                                        transform: "scaleX(-1)",
                                      }}
                                    />
                                  </span>
                                </div>
                                <BomModule />
                              </div>
                            ) : (
                              <div className={styles.bomContainer}>
                                <div className={styles.header}>
                                  <Typography
                                    style={{ fontSize: "11px" }}
                                    color="primary.main"
                                  >
                                    BOM
                                  </Typography>
                                </div>
                                <div>
                                  <div className={styles.validatinContainer}>
                                    <img
                                      src={ValidationImgs}
                                      style={{ width: "10rem" }}
                                    />
                                    <Typography
                                      style={{
                                        fontSize: "15px",
                                        textAlign: "center",
                                      }}
                                      color="primary.main"
                                    >
                                      We are working on it...
                                    </Typography>
                                  </div>
                                </div>
                              </div>
                            ))}
                        </>
                      )}
                    {
                      <>
                        {allaData &&
                          allaData?.costing == true &&
                          (ExistData?.costing ? (
                            <div className={styles.costingContainer}>
                              <div className={styles.header}>
                                <Typography
                                  style={{ fontSize: "12px" }}
                                  color="primary.main"
                                >
                                  Costing
                                </Typography>
                                <span
                                  style={{
                                    width: "5rem",
                                    display: "flex",
                                    alignItems: "center",
                                    columnGap: "1rem",
                                  }}
                                ></span>
                                <span className={styles.pageView}>
                                  {ExistData?.scenario_exists &&
                                    (ExistData?.scenario_created ? (
                                      <img
                                        src={IndependsScenariographIcon}
                                        title="Scenario Independent Chart"
                                        style={{
                                          cursor: "pointer",
                                          height: "2rem",
                                        }}
                                        onClick={() =>
                                          ScenarioChartModalHandler()
                                        }
                                      />
                                    ) : (
                                      <img
                                        src={DependsScenariographIcon}
                                        title="Scenario dependent Chart"
                                        style={{
                                          cursor: "pointer",
                                          height: "2rem",
                                        }}
                                        onClick={() =>
                                          ScenarioChartModalHandler()
                                        }
                                      />
                                    ))}
                                  <ReplyIcon
                                    aria-controls={
                                      open1 ? "basic-menu" : undefined
                                    }
                                    aria-haspopup="true"
                                    aria-expanded={open1 ? "true" : undefined}
                                    onClick={(e: any) =>
                                      OpenExpendHanler(e, "Costing")
                                    }
                                    titleAccess="Go to Costing"
                                    style={{
                                      cursor: "pointer",
                                      transform: "scaleX(-1)",
                                    }}
                                  />
                                  {ExistData?.scenario_exists && (
                                    <Menu
                                      id="basic-menu"
                                      anchorEl={anchorEl1}
                                      open={open1}
                                      onClose={handleClose1}
                                      MenuListProps={{
                                        "aria-labelledby": "basic-button",
                                      }}
                                    >
                                      <MenuItem onClick={() => Costingroute()}>
                                        Main Costing
                                      </MenuItem>
                                      <MenuItem
                                        onClick={() => scenarioHandler()}
                                      >
                                        Scenario
                                      </MenuItem>
                                    </Menu>
                                  )}
                                </span>
                              </div>
                              <CostingModule />
                            </div>
                          ) : (
                            <div className={styles.costingContainer}>
                              <div className={styles.header}>
                                <Typography
                                  style={{ fontSize: "12px" }}
                                  color="primary.main"
                                >
                                  Costing
                                </Typography>
                                {/* Hariharan added reply redirect*/}
                                {ExistData?.scenario_exists &&<ReplyIcon
                                    aria-controls={
                                      open1 ? "basic-menu" : undefined
                                    }
                                    aria-haspopup="true"
                                    aria-expanded={open1 ? "true" : undefined}
                                    onClick={(e: any) =>
                                      OpenExpendHanler(e, "Costing")
                                    }
                                    titleAccess="Go to Costing"
                                    style={{
                                      cursor: "pointer",
                                      transform: "scaleX(-1)",
                                    }}
                                  />}
                                  {ExistData?.scenario_exists && (
                                      <Menu
                                        id="basic-menu"
                                        anchorEl={anchorEl1}
                                        open={open1}
                                        onClose={handleClose1}
                                        MenuListProps={{
                                          "aria-labelledby": "basic-button",
                                        }}
                                      >
                                        {/* <MenuItem
                                          onClick={() => Costingroute()}
                                        >
                                          Main Costing
                                        </MenuItem> */}
                                        <MenuItem
                                          onClick={() => scenarioHandler()}
                                        >
                                          Scenario
                                        </MenuItem>
                                      </Menu>
                                    )}
                              </div>

                              <div>
                                <div className={styles.validatinContainer}>
                                  <img
                                    src={ValidationImgs}
                                    style={{ width: "10rem" }}
                                  />
                                  <Typography
                                    style={{
                                      fontSize: "15px",
                                      textAlign: "center",
                                    }}
                                    color="primary.main"
                                  >
                                    We are working on it...
                                  </Typography>
                                </div>
                              </div>
                            </div>
                          ))}
                      </>
                    }
                    {modulePermissionData &&
                      modulePermissionData?.includes("Ideas") && (
                        <>
                          {allaData &&
                            allaData?.ideas == true &&
                            (ExistData?.ideas ? (
                              <div className={styles.ideasContainer}>
                                <div className={styles.header}>
                                  <Typography
                                    style={{ fontSize: "12px" }}
                                    color="primary.main"
                                  >
                                    Ideas
                                  </Typography>
                                  <span
                                    onClick={() => redirectToIdeas()}
                                    className={styles.pageView}
                                  >
                                    <ReplyIcon
                                      titleAccess="Go to Ideas"
                                      style={{
                                        cursor: "pointer",
                                        transform: "scaleX(-1)",
                                      }}
                                    />
                                  </span>
                                </div>
                                <IdeaModule />
                              </div>
                            ) : (
                              <div className={styles.ideasContainer}>
                                <div className={styles.header}>
                                  <Typography
                                    style={{ fontSize: "12px" }}
                                    color="primary.main"
                                  >
                                    Ideas
                                  </Typography>
                                </div>
                                <div>
                                  <div className={styles.validatinContainer}>
                                    <img
                                      src={ValidationImgs}
                                      style={{ width: "10rem" }}
                                    />
                                    <Typography
                                      style={{
                                        fontSize: "15px",
                                        textAlign: "center",
                                      }}
                                      color="primary.main"
                                    >
                                      We are working on it...
                                    </Typography>
                                  </div>
                                </div>
                              </div>
                            ))}
                        </>
                      )}
                  </Box>
                </>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};
export default Allmodulecomponents;
