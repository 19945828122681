import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import CancelIcon from '@mui/icons-material/Cancel';
import { Divider, TextField } from '@mui/material';
import { TextareaAutosize  } from '@mui/material';
import { styled } from '@mui/system';

import { API } from '../../../../api-services';
import { LoadingButton } from '@mui/lab';
import { useDispatch, useSelector } from "react-redux";
import { selectedCalculators } from '../../../../Redux/Actions/compareopen.action';

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: "40vw",
    maxWidth: '50vw',
    maxHeight: { lg: '63vh', xl: '48vh' },
    bgcolor: 'background.paper',
    borderRadius: '10px',
    p: 1,
};
interface CommentModalProps {

    onCloseModal: any;
    isOpen: any;
    commentInfo: any;
    SwalAlertHandlerImages: any;

}

const CommentModal = (props: CommentModalProps) => {
    const { onCloseModal, isOpen, commentInfo, SwalAlertHandlerImages } = props;
    const dispatch = useDispatch();

   const [comment, setComment] = useState<any>(commentInfo?.message);
    const [loading, setLoading] = useState<boolean>(false);

    const SubmitHandler = () => {
        setLoading(true);
        API.put(`cost/calculation/${commentInfo?.id}/`, {
            message : comment
        },{},0)
        .then((res: any) => {
            SwalAlertHandlerImages(true);
            dispatch(selectedCalculators([]));
            setLoading(false);
            onCloseModal();
        })
        .catch((err: any) => {
            console.log(err)
            setLoading(false);
            onCloseModal();
        })
    }

    const handlechange = (e: any) => {
        setComment(e.target.value);
    }

    React.useEffect(() => {
        if(commentInfo?.message != undefined){
            setComment(commentInfo?.message)
        }
        
    }, [commentInfo])

    




    const closeHandaler = () => {
        onCloseModal()

    }
    return (
        <div>

            <Modal
                open={isOpen}
                //   onClose={onCloseModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    {
                        <Box sx={{ display: 'flex', alignItems: 'center', height: '2.5rem' }}>

                            <Typography
                                style={{
                                    fontSize: "1rem",
                                    fontWeight: "600",
                                    width: "100%",
                                    textAlign: "center",
                                    marginRight: '-2rem'
                                }}
                            >
                                Comments for Cost Model
                            </Typography>

                            <CancelIcon titleAccess="Close" sx={{ cursor: 'pointer', margin: '0.5rem' }} onClick={closeHandaler} />
                        </Box>
                    }
                    <Divider
                        sx={{
                            borderColor: "primary.light",
                            margin: "0 1rem",
                            marginBottom: "1rem",
                        }}
                    />


                    <Box sx={{ padding: '0.5rem' }}>                       
                        <Box sx={{ width: "100%"}}>
                            <TextareaAutosize 
                                aria-label="Write Comment"
                                minRows={3}                                
                                placeholder="Write Comment"                                
                                onChange={(e: any) => handlechange(e)}
                                value={comment}
                                style={{
                                    color:'#007fff',
                                    marginBottom: '0.5rem',
                                    width: '100%',
                                    fontSize: '1rem',
                                    margin: '0 auto',
                                    padding: '0.5rem',
                                    border: '1px solid #007fff14',                                   
                                }}
                            />
                        </Box>
                    </Box>
                    <Box sx={{ width: "100%", display: "flex", justifyContent: "flex-end", alignItems: "center", paddingRight:"0.5rem" }}>
                        <LoadingButton  size='small' loading={loading} variant='contained' onClick={SubmitHandler}>ADD</LoadingButton>
                    </Box>

                </Box>
            </Modal>
        </div>
    )
}
export default CommentModal;