// react
import React, { useEffect, useRef, useState } from 'react';
import { QrReader } from "react-qr-reader";
import Modal from "@mui/material/Modal";
import { Autocomplete, Box, Dialog, DialogContent, Divider, IconButton, Slide, TextField, Typography, debounce } from '@mui/material';
// styles
// import styles from "./ScanQRModal.module.scss";
import styles from "./BOMCostScanQRModal.module.scss";
import { TransitionProps } from '@mui/material/transitions';
import { useHistory, useRouteMatch } from "react-router-dom";
import { API } from "../../api-services";
import { Cancel, Countertops } from "@mui/icons-material";
import CancelIcon from "@mui/icons-material/Cancel";
import { useScroll } from "@react-spring/web";
import ClearIcon from '@mui/icons-material/Clear';
import { AxiosError, AxiosResponse } from "axios";
import Swal from "sweetalert2";
import ErrorModalFilesMail from "../ProjectMailModule/ErrorModalFilesMail";
import { useBaseModal } from '../SearchPage/useBaseModal';
import SearchIcon from '@mui/icons-material/Search';

// interfaces and types

// lazy

// ----------------------------------------------------------------------------------

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});


interface Params {
  projectId: any;
  projectName: any;
}
interface CostingQRModalProps {
  isOpen: boolean;
  onClose: () => void;

  changeRootId?: any;
}

const CostingQRModal: React.FC<CostingQRModalProps> = (props) => {
  const { isOpen, onClose, changeRootId } = props;
  const { url } = useRouteMatch<any>();
  const [CounterData, setCounterData] = useState<any>(0);
  var Module = localStorage.getItem("ModuleName");
  const history = useHistory();

  const [searchVaultsOptions, setSearchVaultsOptions] = useState<any>([]);
  const [searchVaultValue, setSearchVaultValue] = useState<any>();
  const OuterRef = React.useRef<any>();


  const [selectedValue, setSelectedValue] = React.useState<any>(null)
  const [SearchValue, setSearchValue] = React.useState<any>([])
  const inputRef = React.useRef<any>(null);
  const descRef = React.useRef<any>();
  const timeout = useRef<any>(null);
  const [ErrorModalFileMsg, setErrorModalFileMsg] = React.useState<any>();
  const ErrorModalFiles = useBaseModal();
  // console.log(Module, "serachUrl");
  // ----------------------------------------------------------------------------------
  // handlers

  const handleQRScannerClose = (e: any) => {
    e?.stopPropagation();
    onClose()
  }

  const handleScan = async (vaultId: any) => {
    if (!vaultId) return;
    API.get(`/xcpep/vault_info/`, { vault: vaultId?.text, type: 'costing', cost_exist: true }, 0)
      .then((res: AxiosResponse) => {
        // console.log(res?.data)
        sessionStorage.setItem("CostingCreateCalc", JSON.stringify(res?.data?.[0]?.ancestors));

        history.push(`/createcostingpart/${res?.data?.[0]?.project}/${res?.data?.[0]?.project_name}/${res?.data?.[0]?.top_vault_name}/${res?.data?.[0]?.top_vault}/${res?.data?.[0]?.id}/${res?.data?.[0]?.abbreviation == null ? "none" : res?.data?.[0]?.abbreviation}/0/0/0/0/0/0/0/0/0/true`);
        onClose()
      }).catch((error: AxiosError) => {
        onClose()
        const { data }: any = error?.response;
        Swal.fire({
          icon: "error",
          html: `<div>
                <br />
                <p style="color:"red">${data[0]}</p>   
                 </div>`,
          customClass: {
            container: 'swal2Container'
          }
        });
      });
  };

  // ----------------------------------------------------------------------------------
  // jsx
  const videoframestyle = {
    height: { xs: "60vh", lg: "100vh" },
    width: { xs: "100vw", lg: "40vw" },
  }

  const ExactDebounce = React.useRef(

    debounce(async (criteria: any) => {
      setSearchVaultsOptions([])
      const vault = API.get(`/xcpep/vault_descendants/`, {
        search: criteria,
        cost:true,
        cost_exist: true
      }, 0)
        .then((res) => {
          setSearchVaultsOptions(res.data);
        }).catch((err: AxiosError) => {
          setSearchVaultsOptions([]);
          const { data }: any = err?.response;
          setErrorModalFileMsg(err.response?.data[0]);
          ErrorModalFiles.open();
          // Swal.fire({
          //   icon: "error",
          //   html: `<div>
          //           <br />
          //           <p style="color:"red">${data[0]}</p>   
          //         </div>`,
          // });
        })
    }, 500)
  ).current;
  const handleClickOutside = (event: any) => {
    // if (!isUndefined(projectCount) && projectCount > 0)

    {

      {


        inputRef.current.style.display = "none";
      }
    }
  };

  React.useEffect(() => {

    document.addEventListener("click", handleClickOutside, false);
    return () => {
      document.removeEventListener("click", handleClickOutside, false);
    };
  }, []);

  const handleOnChangeSearch = async (event: any) => {
    setSelectedValue(event.target.value);

    var DataSplit = event?.target?.value?.split("(")?.shift();
    inputRef.current.style.display = "block";
    if (event?.target?.value?.length > 0) {
      ExactDebounce(DataSplit);
    }



  };

  return (
    <>
      <Modal
        open={isOpen}
        onClose={onClose}
      >
        <Box className={styles.child} onClick={(e)=>handleClickOutside(e)}>
          <ErrorModalFilesMail
            isOpen={ErrorModalFiles.isOpen}
            onCloseModal={ErrorModalFiles.close}
            ErrorMsg={ErrorModalFileMsg}
          />
          <Box sx={{ width: '100%', height: '100%' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', height: '2.5rem' }}>
              <Typography
                style={{
                  fontSize: "1rem",
                  fontWeight: "600",
                  width: "100%",
                  textAlign: "center",
                  marginRight: '-2rem'
                }}
              >
              Costing QR Scanner
              </Typography>
              <CancelIcon titleAccess="Close" sx={{ cursor: 'pointer', margin: '0.5rem' }} onClick={(e: any) => handleQRScannerClose(e)} />
            </Box>
            <Divider
              sx={{
                borderColor: "primary.light",
                margin: "0 1rem",
                marginBottom: "1rem",
              }}
            />
            <Box sx={{ width: '100% !important',  overflow:'hidden', height:'auto',minHeight: {lg:'27rem',xl:'35rem'}}}>
              {/* @ts-ignore */}
              <QrReader
                className={styles.scannerSection}
                containerStyle={videoframestyle}
                constraints={{ facingMode: "environment" }}
                // scanDelay={300}
                onResult={handleScan}
              />
            </Box>


            <Box sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              margin: "1rem"
            }}>
              <div
                className={styles.inputbox}
                ref={OuterRef}
              // tabIndex={1} onKeyDown={(event:any)=>HandleKeyDown(event)}
              >
                {
                  <><p>Search Part Number/Name</p><input
                    type="search"
                    placeholder="Search Part Number/Name"
                    value={selectedValue}
                    className={styles.searchInput}
                    // autoFocus

                    style={{ color: "#007fff" }}
                    // @ts-expect-
                    ref={descRef}
                    onKeyPress={(event: any) => handleOnChangeSearch(event)}
                    onChange={(event: any) => handleOnChangeSearch(event)} /></>
                }

                <div
                  style={{ display: "none", zIndex: 900, position: 'absolute', top: "1rem" }}
                  className={styles.AutoSearchBox}
                  ref={inputRef}>
                  <>
                    <div className={styles.searchSubText}>
                      {searchVaultsOptions.map((item: any, index: any) => {
                        return (
                          <div className={styles.searchRow}>
                            <div
                              key={index}
                              //  ref={focusedIndex==index?resItConatiner:null} style={{backgroundColor:index==focusedIndex?"red":""}}
                              className={styles.innserSearchSubText}
                              onClick={() => {
                                setSelectedValue(item.name);
                                inputRef.current.style.display = "none";
                                if (item != null) {
                                  API.get(`/xcpep/vault_descendants/`, { vault: item?.id }, 0)
                                    .then((res) => {
                                      window.open(`/#/createcostingpart/${item?.project}/${item?.project_name}/${item?.top_vault_name}/${item?.top_vault}/${item?.id}/${item?.abbreviation == null ? "none" : item?.abbreviation}/0/0/0/0/0/0/0/0/0/true`);

                                    }).catch((err: AxiosError) => {
                                      setSearchVaultsOptions([]);
                                      const { data }: any = err?.response;
                                      setErrorModalFileMsg(err.response?.data[0]);
                                      ErrorModalFiles.open();

                                    })
                                }
                              }}
                            >
                              <span>

                                <>
                                  {/* <span>{item?.value} </span> */}
                                  <span>

                                    <span>
                                      <span>(</span>
                                      {item?.part_no}
                                      <span>)</span>
                                    </span>
                                    | <span>{item?.name}</span>
                                  </span>
                                </>
                              </span>
                              <span
                              style={{paddingTop: "5px",}}>
                              <SearchIcon />
                            </span>
                            </div>
                          </div>
                        );
                      })}
                      {searchVaultsOptions && searchVaultsOptions?.length == 0 && (
                        <p
                          style={{
                            paddingLeft: "1rem",
                            fontSize: "0.9rem",
                          }}>
                          No Data
                        </p>
                      )}
                    </div>
                  </>
                </div>
              </div>
              {/* <div style={{cursor:"pointer"}} ><SearchIcon />

</div> */}
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default CostingQRModal;
