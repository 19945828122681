import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import CancelIcon from '@mui/icons-material/Cancel';
import { Divider, TextField } from '@mui/material';
import { API } from '../../../../../api-services';
import { LoadingButton } from '@mui/lab';
const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: "30vw",
  maxWidth: '40vw',
  maxHeight: {lg:'63vh',xl:'48vh'},  
  bgcolor: 'background.paper',
  borderRadius: '10px',
  p: 1,
};
interface ConstantmsgModalProps {

  onCloseModal: any;
  isOpen: any;
  Item: any;
  type: any;
  values: any;
  ToggleSwitch?:any
}

const ConstantmsgModal = (props: ConstantmsgModalProps) => {
  const { onCloseModal, isOpen, Item, values, type, ToggleSwitch } = props;

  const [value, setValue] = useState<any>()
  const [masgdata, setmasgdata] = useState<any>(null)
  const [Loader, setLoader] = useState<any>(false)

  const [Ids, setIds] = useState<any>()


  useEffect(() => {
    if (type !== "msg") {
      API.get("/cost/cost_constant_message/", { calculation: Item }).then((res: any) => {
        var data = res?.data?.filter((item: any, index: any) => { return item?.input_field == values })

        console.log(data, "cbcccncncncnnccnnccn")
        setmasgdata(data[0]?.message)
        setIds(data[0])
        setValue(data[0]?.user_name)
      }).catch((err: any) => {
        console.log("Sever error")
      })
    }

  }, [Item])



  const handlechange = (e: any) => {

    setmasgdata(e?.target?.value)


  }

  const SubmitHandler = () => {
    if (Ids?.message?.length > 0) {
      if (type !== "msg") {
        setLoader(true)
        API.put(`/cost/cost_constant_message/${Ids?.id}/`, { message: masgdata }).then((res: any) => {
          setLoader(false)
          onCloseModal()
          //   API.get("/cost/cost_constant_message/",{calculation:Item}).then((res:any)=>{
          //     var data=res?.data?.filter((item:any,index:any)=>{return item?.input_field==values})
          //     setmasgdata(data[0]?.message)
          //     setIds(data[0])
          //     setValue(data[0]?.user_name) 
          // }).catch((err:any)=>{
          //     console.log("Sever error")
          // })


        }).catch((err: any) => {
          setLoader(false)
        })
      }

    } else {
      setLoader(true)
      API.post("/cost/cost_constant_message/", { calculation: Item, input_field: values, message: masgdata }).then((res: any) => {
        setLoader(false)
        onCloseModal()
        //   API.get("/cost/cost_constant_message/",{calculation:Item}).then((res:any)=>{
        //     var data=res?.data?.filter((item:any,index:any)=>{return item?.input_field==values})
        //     setmasgdata(data[0]?.message)
        //     setIds(data[0])
        //     setValue(data[0]?.user_name) 

        // }).catch((err:any)=>{
        //     console.log("Sever error")
        // })


      }).catch((err: any) => {
        setLoader(false)
      })
    }
  }

  const closeHandaler = () => {
    if (ToggleSwitch) {
      ToggleSwitch();
     }
    onCloseModal();
 
    //     if(type=="msg"){
    //       onCloseModal()
    //       if(Ids?.message?.length>0){
    //         API.put(`/cost/cost_constant_message/${Ids?.id}/`,{message:masgdata}).then((res:any)=>{

    //           API.get("/cost/cost_constant_message/",{calculation:Item}).then((res:any)=>{
    //             var data=res?.data?.filter((item:any,index:any)=>{return item?.input_field==values})
    //             setmasgdata(data[0]?.message)
    //             setIds(data[0])
    //             setValue(data[0]?.user_name) 
    //         }).catch((err:any)=>{
    //             console.log("Sever error")
    //         })


    //         })
    //     }else{
    //     API.post("/cost/cost_constant_message/",{calculation:Item,input_field:values,message:masgdata}).then((res:any)=>{
    //       API.get("/cost/cost_constant_message/",{calculation:Item}).then((res:any)=>{
    //         var data=res?.data?.filter((item:any,index:any)=>{return item?.input_field==values})
    //         setmasgdata(data[0]?.message)
    //         setIds(data[0])
    //         setValue(data[0]?.user_name) 
    //     }).catch((err:any)=>{
    //         console.log("Sever error")
    //     })


    //     })
    // }
    //     }else{
    //       onCloseModal()
    //     }
  }
  return (
    <div>

      <Modal
        open={isOpen}
        //   onClose={onCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {
            <Box sx={{ display: 'flex', alignItems: 'center', height: '2.5rem' }}>
              {type == "msg" ?
                <Typography
                  style={{
                    fontSize: "1rem",
                    fontWeight: "600",
                    width: "100%",
                    textAlign: "center",
                    marginRight: '-2rem'
                  }}
                >
                  Add Comment
                </Typography> :
                <Typography
                  style={{
                    fontSize: "1rem",
                    fontWeight: "600",
                    width: "100%",
                    textAlign: "center",
                    marginRight: '-2rem'
                  }}
                >
                  Update Comment
                </Typography>
              }
              <CancelIcon titleAccess="Close" sx={{ cursor: 'pointer', margin: '0.5rem' }} onClick={closeHandaler} />
            </Box>
          }
          <Divider
            sx={{
              borderColor: "primary.light",
              margin: "0 1rem",
              marginBottom: "1rem",
            }}
          />
          {type == "msg" ?
            <Box sx={{ height: '100%', width: '100%' }}>
              <Box sx={{ padding: '0.5rem' }}>
                <textarea style={{
                  paddingLeft: "0.3rem",
                  width: "100%",
                  maxWidth: '38vw',
                  maxHeight: '25rem',
                  height: "9rem",
                  border: '1px solid #ddefff',
                  borderRadius: '5px',
                }} autoFocus value={masgdata} placeholder='write comments here......' onChange={(e: any) => handlechange(e)} />
                <Box style={{
                  textAlign: "end", cursor: "pointer"
                }}>
                  <LoadingButton size='small'
                    sx={{
                      "&:hover": {
                        transform: 'Scale(1.05)',
                        transition: 'transform 0.5s ease',
                      },
                      '.MuiCircularProgress-root': {
                        color: 'primary.main',
                      },
                    }} loading={Loader} variant='contained' onClick={() => SubmitHandler()}>Submit</LoadingButton>
                </Box>
              </Box>

            </Box> :
            <Box sx={{ padding: '0.5rem' }}>
              <textarea style={{
                paddingLeft: "0.3rem",
                width: "100%",
                height: "9rem",
                border: '1px solid #ddefff',
                borderRadius: '5px',
              }} autoFocus value={masgdata} placeholder='write comments here......' onChange={(e: any) => handlechange(e)} />
              <Box style={{
                textAlign: "end", cursor: "pointer"
              }}>
                <LoadingButton
                  size='small'
                  sx={{
                    "&:hover": {
                      transform: 'Scale(1.05)',
                      transition: 'transform 0.5s ease',
                    },
                    '.MuiCircularProgress-root': {
                      color: 'primary.main',
                    },
                  }}
                  loading={Loader} variant='contained' onClick={() => SubmitHandler()}>Update</LoadingButton>
              </Box>
            </Box>

          }
        </Box>
      </Modal>
    </div>
  )
}
export default ConstantmsgModal;