import { Checkbox } from "@mui/material";
import * as React from "react";
import { API } from "../../api-services";
import Swal from "sweetalert2";
import { useRouteParams } from "../../ui-reusable-component/useRouteParams";
import { useHistory } from "react-router-dom";

export interface ICheckBoxValidationProps {
  item: any;
  permission: any;
  loadingg?: any;
}

export default function CheckBoxValidation(props: ICheckBoxValidationProps) {
  const { item, permission, loadingg } = props;
  const history = useHistory();
  const {
    projectId,
    projectname,
    categoryId,
    productId,
    datatype,
    validationId,
    categoryGroup,
    calcId,
    calcSequence,
    config_id,
    costingType,
    costingSubType,
    configMirror,
    isDelete
  } = useRouteParams<any>();
  const [checked, setChecked] = React.useState(item.is_validated);
  const [loading, setLoading] = React.useState(false);

  const ValidateCheckBox = async (item: any) => {
    // Toggle loading state to disable checkbox during API call
    setLoading(true);

    try {
      const res = await API.put(`/api/db/costing_category/${item?.id}/`, {
        is_validated: !checked, // Send the opposite of current state to the API
      });
      // Update state based on the server response
      setChecked(res?.data?.is_validated);
    } catch (err: any) {
      // Handle errors and show message if the API call fails
      Swal.fire({
        title: "Error!",
        text: err?.response?.data[0] || "An error occurred",
        icon: "error",
        confirmButtonText: "Ok",
      });
    } finally {
      // Re-enable checkbox for the next click
      setLoading(false);
    }
  };

  return (
    <>
      <Checkbox
        checked={checked}
        disabled={loadingg ? loadingg : !permission?.includes("U") || loading}
        onChange={(e: any) => {
          // e.stopPropagation();
          // e.preventDefault();
          history.push(
            `/costingconfiguration-template/${projectId}/${projectname}/${item?.id}/${categoryGroup}/${configMirror}/${calcId}/${calcSequence}/${config_id}/${costingType}/${costingSubType}/${isDelete}`
          );
          ValidateCheckBox(item);
        }}
        sx={{
          padding: "0.2rem",
          color: item?.id === categoryId ? "white" : "text.primary",
          "&.Mui-checked": {
            color: item?.id === categoryId ? "#7cff42" : "#7cff42",
          },
        }}
      />
    </>
  );
}
