// // react
// import React, { useMemo } from 'react';

import {
  Box,
  Divider,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { CALCULATOR_DATABASES_MAP } from "../../../../../../../Configuration/Costing/calculator.constant";
import { ID } from "../../../../../../../utlis/commonInterfaces";
import { useAssumptioCard } from "../../../../../useAssumptioCard";
import Modal from "@mui/material/Modal";
import CancelIcon from "@mui/icons-material/Cancel";
import { useContext, useEffect, useState } from "react";
import { API } from "../../../../../../../api-services";
import { useRouteParams } from "../../../../../../../ui-reusable-component/useRouteParams";
import { OtherAssumptionIdContext } from "../../../../CreateCostingPart";
import arrowRight from "../../../../../../../Assets/images/rightArrows.svg";
import { useBaseParamsModal } from "../../../../../../../CustomHook/useBaseParamsModal";
import FormulalistModal from "./FormulalistModal";
import calcGreen from "../../../../../../../Assets/images/calculator_green.svg";
import DatabaseCalculatorConfig from "../../../../../../../DataBaseModule/ParameterLibrary/DatabasecalculatorConfig";

// // vendors
// import { Button, Icon, Modal, Table } from 'semantic-ui-react';

// // services
// import labourHourlyRateService from 'app/services/labourHourlyRate.service';
// import machineHourlyRateService from 'app/services/machineHourlyRate.service';
// import FASTENERDB from 'app/services/fastenersDB.service';
// import TOOLSDB from 'app/services/tools.service';
// import purchasingItemsDatabaseService from 'app/services/purchasingItemsDatabase.service';
// import materialCostService from 'app/services/materialCost.service';

// // utils
// // constants
// import { calculatorDatabaseOptions, CALCULATOR_DATABASES_MAP } from 'app/constants/calculator.constants';

// // factories
// // core
// import useFetch from 'app/Core/CustomHooks/useFetch';
// import LetSuspense from 'app/Core/LetSuspense';
// import Retry from 'app/Core/Retry';

// // redux
// import { transformToMachineHourlyRate } from 'app/Redux/Actions/machineHourlyRate.actions';
// import { transformToLabourHourlyRate } from 'app/Redux/Actions/labourHourlyRate.actions';
// import { transformToMaterialCost } from 'app/Redux/Actions/materialCost.actions';

// // ui-components
// import { BouncingLoader } from 'app/ui-components';

// // others
// import { databaseColumns } from '../DatabaseSelectionModal/databaseSelectionModal.utils';
// import { useAssumptioCard } from '../../../useAssumptioCard';

// // styles
// import styles from "./SelectedDatabaseRecordInfoModal.module.scss";

// // interfaces and types
// import { ID } from 'app/commonInterfaces';

// lazy

// ----------------------------------------------------------------------------------
// constants

// const databaseService = {
//     [CALCULATOR_DATABASES_MAP.LABOUR]: labourHourlyRateService.getByLabourId,
//     [CALCULATOR_DATABASES_MAP.MACHINE]: machineHourlyRateService.getByMachineId,
//     [CALCULATOR_DATABASES_MAP.FASTENER]: FASTENERDB.getSpecificFastener,
//     [CALCULATOR_DATABASES_MAP.TOOL]: TOOLSDB.getSpecificTool,
//     [CALCULATOR_DATABASES_MAP.PURCHASEING_ITEM]: purchasingItemsDatabaseService.getById,
//     [CALCULATOR_DATABASES_MAP.MATERIAL]: materialCostService.getByMaterialId
// };

// const transformDatabaseRecord = {
//     [CALCULATOR_DATABASES_MAP.LABOUR]: ( data: any ) => transformToLabourHourlyRate( data[0] ),
//     [CALCULATOR_DATABASES_MAP.MACHINE]: ( data: any ) => transformToMachineHourlyRate( data[0] ),
//     [CALCULATOR_DATABASES_MAP.FASTENER]: ( data: any ) => data[0],
//     [CALCULATOR_DATABASES_MAP.TOOL]: ( data: any ) => data[0],
//     [CALCULATOR_DATABASES_MAP.PURCHASEING_ITEM]: ( data: any ) => data[0],
//     [CALCULATOR_DATABASES_MAP.MATERIAL]: ( data: any ) => transformToMaterialCost( data[0] )
// };

// ----------------------------------------------------------------------------------

interface SelectedDatabaseRecordInfoModalProps {
  isOpen: boolean;
  onClose: () => void;
  database: number;
  recordId: ID;
  userInput: any;
  assumptionn?: any;
  type?: any;
  reDirectToMainCosting?: any;
  revisionId?: any;
  assumptionList?: any;
  calculatorId?: any;
  viewtype?: any;
  mainAssumption?: any;
  assumptionListt?: any;
  costingcreate?: any;
  setUpdateCosting?:any
  updateCosting?:any
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: "30vw",
  maxWidth: "80vw",
  // height: '80vh',
  bgcolor: "background.paper",
  borderRadius: "10px",
  p: 1,
  zIndex: "1000",
};

const SelectedDatabaseRecordInfoModal: React.FC<
  SelectedDatabaseRecordInfoModalProps
> = (props) => {
  const {
    database,
    isOpen,
    onClose,
    recordId,
    userInput,
    assumptionn,
    type,
    reDirectToMainCosting,
    revisionId,
    viewtype,
    assumptionList,
    calculatorId,
    mainAssumption,
    assumptionListt,
    costingcreate,
    setUpdateCosting,
    updateCosting
  } = props;
  const openConfig = useBaseParamsModal();
  const { projectId, vault, topVault, scenarioId } = useRouteParams<any>();
  const [DataBaseList, setDataBaseList] = useState<any>([]);
  const [currencyInfo, setCurrencyInfo] = useState<any>([]);
  const [loding, setLoading] = useState<any>(false);
  const [Loader, setLoader] = useState<any>(false);
  const formulamodal: any = useBaseParamsModal();
  const [assumptionDetails, setAssumptionDetails] = useState<any>([]);
  const [useraction, setuseraction] = useState<any>(null);
  const [commodityID, setCommodityID] = useState<any>(null);
  const handleOpenLinkReference = (reference: string) => {
    window.open(reference);
  };
  const getAssumptionData = () => {
    setLoading(true);
    API.get(
      "/customer/costing_module/",
      {
        vault_id: vault,
        get_assumption_details: true,
        scenario: scenarioId ? scenarioId : undefined,
      },
      0
    )
      .then((res: any) => {
        setLoading(false);

        setAssumptionDetails(res.data);
      })
      .catch((err: any) => {
        setLoading(false);
      });
  };

  const checkUserPermession = () => {
    API.get(
      "/auth/calibration_user_permission/",
      {
        action: true,
        database: true,
        module: "DB-Costing",
      },
      0
    )
      .then((res: any) => {
        setuseraction(res?.data?.action);
      })
      .catch((err: any) => {});
  };

  //  console.log(getRevisionId(), typeId, "idd", typeName);

  const handleUserRedirection = (typeId: any, typeName: any) => {
    //  "/data-base/revision-database/:tab/:year/:month/:revisionId/:typeId/:databaseName/:categoryName/:status/:search/:lockCounter",

    // ${getRevisionId()}

    window.open(
      `/#/data-base/revision-database/${type === "Scenario" ? 2 : 1}/${
        assumptionDetails[0].tools_revision["Revised on"].split("-")[2]
      }/${assumptionDetails[0].tools_revision["Revised on"].split("-")[1]}/${
        typeId || 0
      }/${getRevisionId() || 0}/${typeName}/${commodityID}/false/0/0`
    );
  };

  // console.log(type, type && type == "Scenario", reDirectToMainCosting);

  // ----------------------------------------------------------------------------------
  // assumption card info

  const AssumtionIds = useContext<any>(OtherAssumptionIdContext);

  const { 1: assumption } = useAssumptioCard();
  const getRevisionId = () => {
    if (!assumption && !assumptionn) return 0;

    switch (database) {
      case CALCULATOR_DATABASES_MAP.LABOUR:
        return assumption
          ? assumption.labour_revision
          : assumptionn.labour_revision;
      case CALCULATOR_DATABASES_MAP.MACHINE:
        return assumption
          ? assumption.machine_revision
          : assumptionn.machine_revision;
      case CALCULATOR_DATABASES_MAP.MATERIAL:
        return assumption
          ? assumption.material_revision
          : assumptionn.material_revision;
      case CALCULATOR_DATABASES_MAP.TOOL:
        return assumption
          ? assumption.tools_revision
          : assumptionn.tools_revision;
      case CALCULATOR_DATABASES_MAP.STROKE_RATE:
        return assumption
          ? assumption.operations_revision
          : assumptionn.operations_revision;
      case CALCULATOR_DATABASES_MAP.PURCHASEING_ITEM:
        return assumption ? assumption.bop_revision : assumptionn.bop_revision;
      case CALCULATOR_DATABASES_MAP.CURRENCY:
        return assumption
          ? assumption.currency_revision
          : assumptionn.currency_revision;
      default:
        return 0;
    }
  };

  const getRevisionNameId: any = () => {
    if (!assumption && !mainAssumption) return 0;

    switch (database) {
      case 2:
        return "machine_id";
      case 3:
        return "labour_id";
      case 4:
        return "tools_id";
      case 7:
        return "stroke_rate_id";
    }
  };

  const tableHeading = (datatype: any) => {

    switch (datatype) {
      case 1:
        return (
          <Typography
            sx={{
              display: "flex",
              alignItems: "center",
              columnGap: "0.5rem",
              justifyContent: "center",
              padding: "0.2rem 0.5rem",
              fontWeight: "500",
            }}
          >
            {" "}
            Material Info
            {((useraction && useraction?.includes("C")) ||
              (useraction && useraction?.includes("U")) ||
              (useraction && useraction?.includes("R")) ||
              (useraction && useraction?.includes("D"))) &&
              !viewtype && (
                <img
                  src={arrowRight}
                  onClick={() => {
                    if (!viewtype) {
                      handleUserRedirection(
                        assumption?.material_revision
                          ? assumption?.material_revision
                          : assumptionn?.material_revision,
                        "rm"
                      );
                    }

                    // if (type && type == "Scenario") {
                    //   reDirectToMainCosting();

                    // } else{
                    //   handleUserRedirection(assumption?.material_revision, "rm");
                    // }
                  }}
                  title="Go to Revision Page"
                  style={{
                    fontSize: "1.5rem",
                    // transform: "scaleX(-1)",
                    width: "1.5rem",
                    justifySelf: "center",
                    alignSelf: "center",
                    cursor: "pointer",
                  }}
                />
              )}
          </Typography>
        );
      case 2:
        return (
          <Typography
            sx={{
              display: "flex",
              alignItems: "center",
              columnGap: "0.5rem",
              justifyContent: "center",
              padding: "0.2rem 0.5rem",
              fontWeight: "500",
            }}
          >
            {" "}
            {viewtype == "costingType" || costingcreate == true
              ? "LHR"
              : "MHR"}{" "}
            Info
            {((useraction && useraction?.includes("C")) ||
              (useraction && useraction?.includes("U")) ||
              (useraction && useraction?.includes("R")) ||
              (useraction && useraction?.includes("D"))) &&
              !viewtype && (
                <img
                  src={arrowRight}
                  onClick={() => {
                    if (!viewtype) {
                      handleUserRedirection(
                        assumption?.material_revision
                          ? assumption?.material_revision
                          : assumptionn?.material_revision,
                        viewtype == "costingType" || costingcreate == true
                          ? "labor"
                          : "machine"
                      );
                    }

                    // if (type && type == "Scenario") {
                    //   reDirectToMainCosting();

                    // } else{
                    //   handleUserRedirection(assumption?.machine_revision, "machine")
                    // }
                  }}
                  title="Go to Revision Page"
                  style={{
                    fontSize: "1.5rem",
                    // transform: "scaleX(-1)",
                    width: "1.5rem",
                    justifySelf: "center",
                    alignSelf: "center",
                    cursor: "pointer",
                  }}
                />
              )}
          </Typography>
        );
      case 3:
        return (
          <Typography
            sx={{
              display: "flex",
              alignItems: "center",
              columnGap: "0.5rem",
              justifyContent: "center",
              padding: "0.2rem 0.5rem",
              fontWeight: "500",
            }}
          >
            {" "}
            {viewtype == "costingType" || costingcreate == true
              ? "MHR"
              : "LHR"}{" "}
            Info
            {((useraction && useraction?.includes("C")) ||
              (useraction && useraction?.includes("U")) ||
              (useraction && useraction?.includes("R")) ||
              (useraction && useraction?.includes("D"))) &&
              !viewtype && (
                <img
                  src={arrowRight}
                  onClick={() => {
                    // if (type && type == "Scenario") {
                    //   reDirectToMainCosting();

                    // } else{
                    //   handleUserRedirection(assumption?.labour_revision, "labor")
                    // }
                    handleUserRedirection(
                      assumption?.material_revision
                        ? assumption?.material_revision
                        : assumptionn?.material_revision,
                      viewtype == "costingType" || costingcreate == true
                        ? "machine"
                        : "labor"
                    );
                  }}
                  title="Go to Revision Page"
                  style={{
                    fontSize: "1.5rem",
                    // transform: "scaleX(-1)",
                    width: "1.5rem",
                    justifySelf: "center",
                    alignSelf: "center",
                    cursor: "pointer",
                  }}
                />
              )}
          </Typography>
        );
      case 4:
        return (
          <Typography
            sx={{
              display: "flex",
              alignItems: "center",
              columnGap: "0.5rem",
              justifyContent: "center",
              padding: "0.2rem 0.5rem",
              fontWeight: "500",
            }}
          >
            {" "}
            Tool Info
            {((useraction && useraction?.includes("C")) ||
              (useraction && useraction?.includes("U")) ||
              (useraction && useraction?.includes("R")) ||
              (useraction && useraction?.includes("D"))) &&
              !viewtype && (
                <img
                  src={arrowRight}
                  onClick={() => {
                    // if (type && type == "Scenario") {
                    //   reDirectToMainCosting();

                    // } else{
                    //   handleUserRedirection(assumption?.tools_revision, "tools")
                    // }
                    handleUserRedirection(
                      assumption?.material_revision
                        ? assumption?.material_revision
                        : assumptionn?.material_revision,
                      "tools"
                    );
                  }}
                  title="Go to Revision Page"
                  style={{
                    fontSize: "1.5rem",
                    // transform: "scaleX(-1)",
                    width: "1.5rem",
                    justifySelf: "center",
                    alignSelf: "center",
                    cursor: "pointer",
                  }}
                />
              )}
          </Typography>
        );
      case 5:
        return (
          <Typography
            sx={{
              display: "flex",
              alignItems: "center",
              columnGap: "0.5rem",
              justifyContent: "center",
              padding: "0.2rem 0.5rem",
              fontWeight: "500",
            }}
          >
            {" "}
            Fastener Info
            {((useraction && useraction?.includes("C")) ||
              (useraction && useraction?.includes("U")) ||
              (useraction && useraction?.includes("R")) ||
              (useraction && useraction?.includes("D"))) &&
              !viewtype && (
                <img
                  src={arrowRight}
                  onClick={() => {
                    // if (type && type == "Scenario") {
                    //   reDirectToMainCosting();

                    // } else{
                    //   handleUserRedirection(assumption?.bop_revision, "bop")
                    // }
                    handleUserRedirection(
                      assumption?.bop_revision
                        ? assumption?.bop_revision
                        : assumptionn?.bop_revision,
                      "bop"
                    );
                  }}
                  title="Go to Revision Page"
                  style={{
                    fontSize: "1.5rem",
                    // transform: "scaleX(-1)",
                    width: "1.5rem",
                    justifySelf: "center",
                    alignSelf: "center",
                    cursor: "pointer",
                  }}
                />
              )}
          </Typography>
        );
      case 6:
        return (
          <Typography
            sx={{
              display: "flex",
              alignItems: "center",
              columnGap: "0.5rem",
              justifyContent: "center",
              padding: "0.2rem 0.5rem",
              fontWeight: "500",
            }}
          >
            {" "}
            BOP Info
            {((useraction && useraction?.includes("C")) ||
              (useraction && useraction?.includes("U")) ||
              (useraction && useraction?.includes("R")) ||
              (useraction && useraction?.includes("D"))) &&
              !viewtype && (
                <img
                  src={arrowRight}
                  onClick={() => {
                    // if (type && type == "Scenario") {
                    //   reDirectToMainCosting();

                    // } else{
                    //   handleUserRedirection(assumption?.bop_revision, "bop")
                    // }
                    handleUserRedirection(
                      assumption?.material_revision
                        ? assumption?.material_revision
                        : assumptionn?.material_revision,

                      "bop"
                    );
                  }}
                  title="Go to Revision Page"
                  style={{
                    fontSize: "1.5rem",
                    // transform: "scaleX(-1)",
                    width: "1.5rem",
                    justifySelf: "center",
                    alignSelf: "center",
                    cursor: "pointer",
                  }}
                />
              )}
          </Typography>
        );
      case 7:
        return (
          <Typography
            sx={{
              display: "flex",
              alignItems: "center",
              columnGap: "0.5rem",
              justifyContent: "center",
              padding: "0.2rem 0.5rem",
              fontWeight: "500",
            }}
          >
            {" "}
            Operation Info
            {((useraction && useraction?.includes("C")) ||
              (useraction && useraction?.includes("U")) ||
              (useraction && useraction?.includes("R")) ||
              (useraction && useraction?.includes("D"))) &&
              !viewtype && (
                <img
                  src={arrowRight}
                  onClick={() => {
                    // if (type && type == "Scenario") {
                    //   reDirectToMainCosting();

                    // } else{
                    //   handleUserRedirection(assumption?.operations_revision, "operations")
                    // }
                    handleUserRedirection(
                      assumption?.material_revision
                        ? assumption?.material_revision
                        : assumptionn?.material_revision,
                      "operations"
                    );
                  }}
                  title="Go to Revision Page"
                  style={{
                    fontSize: "1.5rem",
                    // transform: "scaleX(-1)",
                    width: "1.5rem",
                    justifySelf: "center",
                    alignSelf: "center",
                    cursor: "pointer",
                  }}
                />
              )}
          </Typography>
        );
      case 8:
        return (
          <Typography
            sx={{
              display: "flex",
              alignItems: "center",
              columnGap: "0.5rem",
              justifyContent: "center",
              padding: "0.2rem 0.5rem",
              fontWeight: "500",
            }}
          >
            {" "}
            Currency Info
            {((useraction && useraction?.includes("C")) ||
              (useraction && useraction?.includes("U")) ||
              (useraction && useraction?.includes("R")) ||
              (useraction && useraction?.includes("D"))) &&
              !viewtype && (
                <img
                  src={arrowRight}
                  onClick={() => {
                    // if (type && type == "Scenario") {
                    //   reDirectToMainCosting();

                    // } else{
                    //   handleUserRedirection(assumption?.currency_revision, "currency")
                    // }
                    handleUserRedirection(
                      assumption?.material_revision
                        ? assumption?.material_revision
                        : assumptionn?.material_revision,
                      "currency"
                    );
                  }}
                  title="Go to Revision Page"
                  style={{
                    fontSize: "1.5rem",
                    // transform: "scaleX(-1)",
                    width: "1.5rem",
                    justifySelf: "center",
                    alignSelf: "center",
                    cursor: "pointer",
                  }}
                />
              )}
          </Typography>
        );
    }
  };

  // ----------------------------------------------------------------------------------
  // fetch

  // const { 0: fetched, 1: data, 3: fetchData } = useFetch( {
  //     method: databaseService[database],
  //     args: [recordId, getRevisionId()],
  //     condition: Boolean( database ) && Boolean( recordId ) && Boolean( assumption ),
  //     transformResData: transformDatabaseRecord[database],
  //     dependencies: [recordId, database, assumption]
  // } );

  // ----------------------------------------------------------------------------------
  // memorized

  // const columns = useMemo( () => {
  //     return databaseColumns[database] || [];
  // }, [database] );

  // ----------------------------------------------------------------------------------
  // jsx helpers

  // const getDatabaseName = () => {
  //     return calculatorDatabaseOptions.find( option => option.value === database )?.text;
  // };

  // ----------------------------------------------------------------------------------
  // jsx
  const isUrl = (url: any) => {
    try {
      new URL(url);
      return true;
    } catch (error) {
      return false;
    }
  };
  // material_id:recordId, revision:revisionId, calculation_id:assumptionList?.length > 0 ? calculatorId : null, assumption_list:assumptionList?.length > 0 ? assumptionList?.toString() : null
  useEffect(() => {
    getAssumptionData();
    checkUserPermession();
  }, []);
  let valuelist =
    AssumtionIds &&
    AssumtionIds?.map((item: any, index: any) => {
      return item?.id;
    });
  const materialQuery = () => {
    if (AssumtionIds && AssumtionIds?.length > 0) {
      return {
        revision: viewtype ? revisionId : getRevisionId(),
        material_id: recordId,
        calculation_id: viewtype
          ? assumptionList?.length > 0
            ? calculatorId
            : null
          : userInput?.id,
        assumption_list: viewtype
          ? assumptionList?.length > 0
            ? assumptionList?.toString()
            : null
          : valuelist?.toString(),
      };
    } else {
      return { revision: getRevisionId(), material_id: recordId };
    }
  };

  const MultipleAssumptionQuery = () => {
    if (AssumtionIds && AssumtionIds?.length > 0) {
      return {
        revision: getRevisionId(),
        [getRevisionNameId()]: recordId,
        calculation_id: userInput?.id,
        assumption_list: valuelist?.toString(),
      };
    } else {
      return { revision: getRevisionId(), [getRevisionNameId()]: recordId };
    }
  };

  const BOPQuery = () => {
    if (AssumtionIds && AssumtionIds?.length > 0) {
      return {
        revision: assumption?.bop_revision,
        id: recordId,
        calculation_id: userInput?.id,
        assumption_list: valuelist?.toString(),
      };
    } else {
      return { revision: assumption?.bop_revision, id: recordId };
    }
  };

  const CuurencyQuery = () => {
    if (AssumtionIds && AssumtionIds?.length > 0) {
      return {
        revision: getRevisionId(),
        id: recordId,
        calculation_id: userInput?.id,
        assumption_list: valuelist?.toString(),
      };
    } else {
      return { revision: getRevisionId(), id: recordId };
    }
  };

  useEffect(() => {
    if (getRevisionId() !== 0) {
      setLoader(true);
      if (database == 1) {
        API.get("api/db/material_cost/", { ...materialQuery() }, 0)
          .then((res: any) => {
            setCommodityID(res?.data?.[0]?.material?.category);
            setDataBaseList(res.data);
            setLoader(false);
          })
          .catch((err: any) => {
            console.log("Server Error");
            setLoader(false);
          });
      } else if (database == 2) {
        API.get("api/db/machine_cost/", { ...MultipleAssumptionQuery() }, 0)
          .then((res: any) => {
            // console.log(res?.data?.[0])
            setCommodityID(res?.data?.[0]?.machine?.commodity);
            setDataBaseList(res.data);
            setLoader(false);
          })
          .catch((err: any) => {
            console.log("Server Error");
            setLoader(false);
          });
      } else if (database == 3) {
        API.get("api/db/labour_cost/", { ...MultipleAssumptionQuery() }, 0)
          .then((res: any) => {
            // console.log(res?.data?.[0]);
            setCommodityID(res?.data?.[0]?.labour?.commodity);
            setDataBaseList(res.data);
            setLoader(false);
          })
          .catch((err: any) => {
            setLoader(false);
            console.log("Server Error");
          });
      }
      if (database == 4) {
        // console.log(database, "hrbvbfvbfvhbfvhb")
        API.get("api/db/tool_cost/", { ...MultipleAssumptionQuery() }, 0)
          .then((res: any) => {
            setCommodityID(res?.data?.[0]?.tools?.commodity);
            setDataBaseList(res.data);
            setLoader(false);
          })
          .catch((err: any) => {
            console.log("Server Error");
            setLoader(false);
          });
      }
      if (database == 7) {
        API.get(
          "api/db/stroke_rate_revision/",
          { ...MultipleAssumptionQuery() },
          0
        )
          .then((res: any) => {
            setDataBaseList(res.data);
            setCommodityID(res?.data?.[0]?.operation?.category);
            setLoader(false);
          })
          .catch((err: any) => {
            setLoader(false);
            console.log("Server Error");
          });
      }
      if (database == 8) {
        API.get(
          "/api/db/revision_currency/",
          { ...CuurencyQuery() },
          // { revision: getRevisionId(), id: recordId },
          0
        )
          .then((res: any) => {
            setCurrencyInfo(res.data);
            setLoader(false);
          })
          .catch((err: any) => {
            setLoader(false);
            console.log("Server Error");
          });
      }
      //   /api/db/revision_currency/
    } else if (revisionId !== null && revisionId !== 0) {
      setLoader(true);
      if (database == 1) {
        API.get(
          "api/db/material_cost/",
          {
            material_id: recordId,
            revision: revisionId,
            calculation_id: assumptionList?.length > 0 ? calculatorId : null,
            assumption_list:
              assumptionList?.length > 0 ? assumptionList?.toString() : null,
          },
          0
        )
          .then((res: any) => {
            setCommodityID(res?.data?.[0]?.material?.category);
            setDataBaseList(res.data);
            setLoader(false);
          })
          .catch((err: any) => {
            console.log("Server Error");
            setLoader(false);
          });
      } else if (database == 3) {
        API.get(
          "api/db/machine_cost/",
          {
            machine_id: recordId,
            revision: revisionId,
            calculation_id: assumptionList?.length > 0 ? calculatorId : null,
            assumption_list:
              assumptionList?.length > 0 ? assumptionList?.toString() : null,
          },
          0
        )
          .then((res: any) => {
            // console.log(res?.data?.[0])
            setCommodityID(res?.data?.[0]?.machine?.commodity);
            setDataBaseList(res.data);
            setLoader(false);
          })
          .catch((err: any) => {
            console.log("Server Error");
            setLoader(false);
          });
      } else if (database == 2) {
        API.get(
          "api/db/labour_cost/",
          {
            labour_id: recordId,
            revision: revisionId,
            calculation_id: assumptionList?.length > 0 ? calculatorId : null,
            assumption_list:
              assumptionList?.length > 0 ? assumptionList?.toString() : null,
          },

          0
        )
          .then((res: any) => {
            // console.log(res?.data?.[0]);
            setCommodityID(res?.data?.[0]?.labour?.commodity);
            setDataBaseList(res.data);
            setLoader(false);
          })
          .catch((err: any) => {
            setLoader(false);
            console.log("Server Error");
          });
      }
      if (database == 4) {
        // console.log(database, "hrbvbfvbfvhbfvhb")
        API.get(
          "api/db/tool_cost/",
          {
            tools_id: recordId,
            revision: revisionId,
            calculation_id: assumptionList?.length > 0 ? calculatorId : null,
            assumption_list:
              assumptionList?.length > 0 ? assumptionList?.toString() : null,
          },
          0
        )
          .then((res: any) => {
            setCommodityID(res?.data?.[0]?.tools?.commodity);
            setDataBaseList(res.data);
            setLoader(false);
          })
          .catch((err: any) => {
            console.log("Server Error");
            setLoader(false);
          });
      }
      if (database == 7) {
        API.get(
          "api/db/stroke_rate_revision/",
          {
            stroke_rate_id: recordId,
            revision: revisionId,
            calculation_id: assumptionList?.length > 0 ? calculatorId : null,
            assumption_list:
              assumptionList?.length > 0 ? assumptionList?.toString() : null,
          },
          0
        )
          .then((res: any) => {
            setDataBaseList(res.data);
            setLoader(false);
          })
          .catch((err: any) => {
            setLoader(false);
            console.log("Server Error");
          });
      }
      if (database == 8) {
        API.get(
          "/api/db/revision_currency/",
          { ...CuurencyQuery() },
          // { revision: getRevisionId(), id: recordId },
          0
        )
          .then((res: any) => {
            setCurrencyInfo(res.data);
            setLoader(false);
          })
          .catch((err: any) => {
            setLoader(false);
            console.log("Server Error");
          });
      }
    }
    setLoader(true);

    if (database == 6) {
      if (getRevisionId() !== 0) {
        API.get("api/db/purchasing_item/", { ...BOPQuery() }, 0)
          .then((res: any) => {
            setCommodityID(res?.data?.[0]?.commodity_name);

            setDataBaseList(res.data);
            setLoader(false);
          })
          .catch((err: any) => {
            setLoader(false);
            console.log("Server Error");
          });
      } else if (revisionId !== null && revisionId !== 0) {
        API.get(
          "api/db/purchasing_item/",
          {
            revision: revisionId,
            id: recordId,
            calculation_id: assumptionList?.length > 0 ? calculatorId : null,
            assumption_list:
              assumptionList?.length > 0 ? assumptionList?.toString() : null,
          },
          0
        )
          .then((res: any) => {
            setCommodityID(res?.data?.[0]?.commodity_name);

            setDataBaseList(res.data);
            setLoader(false);
          })
          .catch((err: any) => {
            setLoader(false);
            console.log("Server Error");
          });
      }
    }
  }, [database, recordId, isOpen, revisionId]);

  const databaseColumns1 = (type: any) => {
    switch (type) {
      case 3:
        return (
          <>
            {viewtype == "costingType" || costingcreate == true ? (
              <>
                <TableRow>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    Machine Code
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    {DataBaseList[0]?.machine?.machine_code}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    Name
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    {DataBaseList[0]?.machine?.name}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    Brand Name
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    {DataBaseList[0]?.machine?.brand_name}{" "}
                  </TableCell>
                </TableRow>

                {AssumtionIds && AssumtionIds?.length > 0 ? (
                  <>
                    <TableRow>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}
                      >
                        Machine Type
                      </TableCell>

                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}
                      >
                        {DataBaseList[0]?.machine_detail?.machine_type}
                      </TableCell>
                    </TableRow>
                    {/* <TableRow>
                    {/* <TableRow>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}
                      >
                        Machine Cost
                      </TableCell>

                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}
                      >
                        {DataBaseList[0]?.machine_detail?.machine_cost}
                      </TableCell>
                    </TableRow> */}
                    <TableRow>
                    <TableCell
                      sx={{
                        padding: "0.2rem 0.5rem",
                        borderBottomColor: "primary.light",
                      }}
                    >
                      Cost Per Hour(INR/Hour)
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "0.2rem 0.5rem",
                        borderBottomColor: "primary.light",
                      }}
                    >
                      {" "}
                      {DataBaseList[0]?.cost_per_hour}
                      <span
                        style={{
                          textDecoration: "underline",
                          fontStyle: "italic",
                          marginLeft: "2rem",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          formulamodal.open({
                            revision_id:
                              viewtype == "costingType"
                                ? revisionId
                                : getRevisionId(),
                            machine_id: recordId,
                            mhr_details: true,
                          });
                        }}
                      >
                        (View MHR Calculation)
                      </span>
                      {/* <InfoIcon
                        

                          sx={{
                            marginBottom:"-0.3rem",
                            marginLeft:"0.5rem",
                            fontSize: "1.3rem",
                            color: "#007fff",
                            cursor: "pointer",
                          }}
                        /> */}
                    </TableCell>
                  </TableRow>
                  </>
                ) : (
                  <TableRow>
                    <TableCell
                      sx={{
                        padding: "0.2rem 0.5rem",
                        borderBottomColor: "primary.light",
                      }}
                    >
                      Cost Per Hour(INR/Hour)
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "0.2rem 0.5rem",
                        borderBottomColor: "primary.light",
                      }}
                    >
                      {" "}
                      {DataBaseList[0]?.cost_per_hour}
                      <span
                        style={{
                          textDecoration: "underline",
                          fontStyle: "italic",
                          marginLeft: "2rem",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          formulamodal.open({
                            revision_id:
                              viewtype == "costingType"
                                ? revisionId
                                : getRevisionId(),
                            machine_id: recordId,
                            mhr_details: true,
                          });
                        }}
                      >
                        (View MHR Calculation)
                      </span>
                      {/* <InfoIcon
                        

                          sx={{
                            marginBottom:"-0.3rem",
                            marginLeft:"0.5rem",
                            fontSize: "1.3rem",
                            color: "#007fff",
                            cursor: "pointer",
                          }}
                        /> */}
                    </TableCell>
                  </TableRow>
                )}
                <TableRow>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    Commodity
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    {DataBaseList[0]?.machine?.commodity_name}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    Description
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    {DataBaseList[0]?.machine?.description}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    Reference
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    {isUrl(DataBaseList?.[0]?.reference) ? (
                      <span
                        style={{
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          handleOpenLinkReference(DataBaseList?.[0]?.reference)
                        }
                      >
                        Link
                      </span>
                    ) : (
                      <span
                        style={{
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                      >
                        {DataBaseList?.[0]?.reference}
                      </span>
                    )}
                  </TableCell>
                </TableRow>
              </>
            ) : (
              <>
                <TableRow>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    Labour Code
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    {DataBaseList?.[0]?.labour?.labour_code}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    Labour Type
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    {DataBaseList?.[0]?.labour?.labour_type}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    Minimum Wage (INR/Day)
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    {DataBaseList?.[0]?.wage_per_day}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    MiD.A (INR/Month)
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    {DataBaseList?.[0]?.dearness_allowance}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    Perks (%)
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    {DataBaseList?.[0]?.perks}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    Minimum Wage (INR/Month)
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    {DataBaseList?.[0]?.wage_per_month}
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    Description
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    {DataBaseList?.[0]?.labour?.description}{" "}
                  </TableCell>
                </TableRow>

                {AssumtionIds && AssumtionIds?.length > 0 ? (
                  <>
                    <TableRow>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}
                      >
                        Labour Type
                      </TableCell>

                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}
                      >
                        {DataBaseList[0]?.labour_detail?.labour_type}
                      </TableCell>
                    </TableRow>
                    {/* <TableRow>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}
                      >
                        Labour Cost
                      </TableCell>

                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}
                      >
                        {DataBaseList[0]?.labour_detail?.labour_cost}
                      </TableCell>
                    </TableRow> */}
                    

                    <TableRow>
                    <TableCell
                      sx={{
                        padding: "0.2rem 0.5rem",
                        borderBottomColor: "primary.light",
                      }}
                    >
                      LHR
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "0.2rem 0.5rem",
                        borderBottomColor: "primary.light",
                      }}
                    >
                      {DataBaseList?.[0]?.cost_per_hour}

                      <span
                        style={{
                          textDecoration: "underline",
                          fontStyle: "italic",
                          marginLeft: "2rem",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          formulamodal.open({
                            revision_id:
                              viewtype == "costingType" || costingcreate == true
                                ? revisionId
                                : getRevisionId(),
                            labour_id: recordId,
                            lhr_details: true,
                          });
                        }}
                      >
                        (View LHR Calculation)
                      </span>

                      {/* <InfoIcon
                        

                          sx={{
                            marginBottom:"-0.3rem",
                            marginLeft:"0.5rem",
                            fontSize: "1.3rem",
                            color: "#007fff",
                            cursor: "pointer",
                          }}
                        /> */}
                    </TableCell>
                  </TableRow>
                  </>
                ) : (
                  <TableRow>
                    <TableCell
                      sx={{
                        padding: "0.2rem 0.5rem",
                        borderBottomColor: "primary.light",
                      }}
                    >
                      LHR
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "0.2rem 0.5rem",
                        borderBottomColor: "primary.light",
                      }}
                    >
                      {DataBaseList?.[0]?.cost_per_hour}

                      <span
                        style={{
                          textDecoration: "underline",
                          fontStyle: "italic",
                          marginLeft: "2rem",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          formulamodal.open({
                            revision_id:
                              viewtype == "costingType" || costingcreate == true
                                ? revisionId
                                : getRevisionId(),
                            labour_id: recordId,
                            lhr_details: true,
                          });
                        }}
                      >
                        (View LHR Calculation)
                      </span>

                      {/* <InfoIcon
                        

                          sx={{
                            marginBottom:"-0.3rem",
                            marginLeft:"0.5rem",
                            fontSize: "1.3rem",
                            color: "#007fff",
                            cursor: "pointer",
                          }}
                        /> */}
                    </TableCell>
                  </TableRow>
                )}
                <TableRow>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    Commodity
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    {DataBaseList?.[0]?.labour?.commodity_name}{" "}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    Reference
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    {isUrl(DataBaseList?.[0]?.reference) ? (
                      <span
                        style={{
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          handleOpenLinkReference(DataBaseList?.[0]?.reference)
                        }
                      >
                        Link
                      </span>
                    ) : (
                      <span
                        style={{
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                      >
                        {DataBaseList?.[0]?.reference}
                      </span>
                    )}
                  </TableCell>
                </TableRow>
              </>
            )}
          </>
        );
      case 2:
        return (
          <>
            {viewtype || costingcreate == true ? (
              <>
                <TableRow>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    Labour Code
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    {DataBaseList?.[0]?.labour?.labour_code}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    Labour Type
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    {DataBaseList?.[0]?.labour?.labour_type}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    Minimum Wage (INR/Day)
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    {DataBaseList?.[0]?.wage_per_day}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    MiD.A (INR/Month)
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    {DataBaseList?.[0]?.dearness_allowance}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    Perks (%)
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    {DataBaseList?.[0]?.perks}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    Minimum Wage (INR/Month)
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    {DataBaseList?.[0]?.wage_per_month}
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    Description
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    {DataBaseList?.[0]?.labour?.description}{" "}
                  </TableCell>
                </TableRow>

                {AssumtionIds && AssumtionIds?.length > 0 ? (
                  <>
                    <TableRow>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}
                      >
                        Labour Type
                      </TableCell>

                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}
                      >
                        {DataBaseList[0]?.labour_detail?.labour_type}
                      </TableCell>
                    </TableRow>
                    {/* <TableRow>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}
                      >
                        Labour Cost
                      </TableCell>

                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}
                      >
                        {DataBaseList[0]?.labour_detail?.labour_cost}
                      </TableCell>
                    </TableRow> */}
                     <TableRow>
                    <TableCell
                      sx={{
                        padding: "0.2rem 0.5rem",
                        borderBottomColor: "primary.light",
                      }}
                    >
                      LHR
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "0.2rem 0.5rem",
                        borderBottomColor: "primary.light",
                      }}
                    >
                      {DataBaseList?.[0]?.cost_per_hour}

                      <span
                        style={{
                          textDecoration: "underline",
                          fontStyle: "italic",
                          marginLeft: "2rem",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          formulamodal.open({
                            revision_id:
                              viewtype == "costingType" || costingcreate == true
                                ? revisionId
                                : getRevisionId(),
                            labour_id: recordId,
                            lhr_details: true,
                          });
                        }}
                      >
                        (View LHR Calculation)
                      </span>

                      {/* <InfoIcon
                        
                        

                          sx={{
                            marginBottom:"-0.3rem",
                            marginLeft:"0.5rem",
                            fontSize: "1.3rem",
                            color: "#007fff",
                            cursor: "pointer",
                          }}
                        /> */}
                    </TableCell>
                  </TableRow>
                  </>
                ) : (
                  <TableRow>
                    <TableCell
                      sx={{
                        padding: "0.2rem 0.5rem",
                        borderBottomColor: "primary.light",
                      }}
                    >
                      LHR
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "0.2rem 0.5rem",
                        borderBottomColor: "primary.light",
                      }}
                    >
                      {DataBaseList?.[0]?.cost_per_hour}

                      <span
                        style={{
                          textDecoration: "underline",
                          fontStyle: "italic",
                          marginLeft: "2rem",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          formulamodal.open({
                            revision_id:
                              viewtype == "costingType" || costingcreate == true
                                ? revisionId
                                : getRevisionId(),
                            labour_id: recordId,
                            lhr_details: true,
                          });
                        }}
                      >
                        (View LHR Calculation)
                      </span>

                      {/* <InfoIcon
                        
                        

                          sx={{
                            marginBottom:"-0.3rem",
                            marginLeft:"0.5rem",
                            fontSize: "1.3rem",
                            color: "#007fff",
                            cursor: "pointer",
                          }}
                        /> */}
                    </TableCell>
                  </TableRow>
                )}
                <TableRow>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    Commodity
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    {DataBaseList?.[0]?.labour?.commodity_name}{" "}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    Reference
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    {isUrl(DataBaseList?.[0]?.reference) ? (
                      <span
                        style={{
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          handleOpenLinkReference(DataBaseList?.[0]?.reference)
                        }
                      >
                        Link
                      </span>
                    ) : (
                      <span
                        style={{
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                      >
                        {DataBaseList?.[0]?.reference}
                      </span>
                    )}
                  </TableCell>
                </TableRow>
              </>
            ) : (
              <>
                <>
                  <TableRow>
                    <TableCell
                      sx={{
                        padding: "0.2rem 0.5rem",
                        borderBottomColor: "primary.light",
                      }}
                    >
                      Machine Code
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "0.2rem 0.5rem",
                        borderBottomColor: "primary.light",
                      }}
                    >
                      {DataBaseList[0]?.machine?.machine_code}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      sx={{
                        padding: "0.2rem 0.5rem",
                        borderBottomColor: "primary.light",
                      }}
                    >
                      Name
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "0.2rem 0.5rem",
                        borderBottomColor: "primary.light",
                      }}
                    >
                      {DataBaseList[0]?.machine?.name}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      sx={{
                        padding: "0.2rem 0.5rem",
                        borderBottomColor: "primary.light",
                      }}
                    >
                      Brand Name
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "0.2rem 0.5rem",
                        borderBottomColor: "primary.light",
                      }}
                    >
                      {DataBaseList[0]?.machine?.brand_name}{" "}
                    </TableCell>
                  </TableRow>

                  {AssumtionIds && AssumtionIds?.length > 0 ? (
                    <>
                      <TableRow>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                          }}
                        >
                          Machine Type
                        </TableCell>

                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                          }}
                        >
                          {DataBaseList[0]?.machine_detail?.machine_type}
                        </TableCell>
                      </TableRow>
                      {/* <TableRow>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                          }}
                        >
                          Machine Cost
                        </TableCell>

                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                          }}
                        >
                          {DataBaseList[0]?.machine_detail?.machine_cost}
                        </TableCell>
                      </TableRow> */}
                       <TableRow>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}
                      >
                        Cost Per Hour(INR/Hour)
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}
                      >
                        {" "}
                        {DataBaseList[0]?.cost_per_hour}
                        <span
                          style={{
                            textDecoration: "underline",
                            fontStyle: "italic",
                            marginLeft: "2rem",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            formulamodal.open({
                              revision_id:
                                viewtype == "costingType" ||
                                costingcreate == true
                                  ? revisionId
                                  : getRevisionId(),
                              machine_id: recordId,
                              mhr_details: true,
                            });
                          }}
                        >
                          (View MHR Calculation)
                        </span>
                        {/* <InfoIcon
                         

                            sx={{
                              marginBottom:"-0.3rem",
                              marginLeft:"0.5rem",
                              fontSize: "1.3rem",
                              color: "#007fff",
                              cursor: "pointer",
                            }}
                          /> */}
                      </TableCell>
                    </TableRow>
                    </>
                  ) : (
                    <TableRow>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}
                      >
                        Cost Per Hour(INR/Hour)
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}
                      >
                        {" "}
                        {DataBaseList[0]?.cost_per_hour}
                        <span
                          style={{
                            textDecoration: "underline",
                            fontStyle: "italic",
                            marginLeft: "2rem",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            formulamodal.open({
                              revision_id:
                                viewtype == "costingType" ||
                                costingcreate == true
                                  ? revisionId
                                  : getRevisionId(),
                              machine_id: recordId,
                              mhr_details: true,
                            });
                          }}
                        >
                          (View MHR Calculation)
                        </span>
                        {/* <InfoIcon
                         

                            sx={{
                              marginBottom:"-0.3rem",
                              marginLeft:"0.5rem",
                              fontSize: "1.3rem",
                              color: "#007fff",
                              cursor: "pointer",
                            }}
                          /> */}
                      </TableCell>
                    </TableRow>
                  )}
                  <TableRow>
                    <TableCell
                      sx={{
                        padding: "0.2rem 0.5rem",
                        borderBottomColor: "primary.light",
                      }}
                    >
                      Commodity
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "0.2rem 0.5rem",
                        borderBottomColor: "primary.light",
                      }}
                    >
                      {DataBaseList[0]?.machine?.commodity_name}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      sx={{
                        padding: "0.2rem 0.5rem",
                        borderBottomColor: "primary.light",
                      }}
                    >
                      Description
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "0.2rem 0.5rem",
                        borderBottomColor: "primary.light",
                      }}
                    >
                      {DataBaseList[0]?.machine?.description}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      sx={{
                        padding: "0.2rem 0.5rem",
                        borderBottomColor: "primary.light",
                      }}
                    >
                      Reference
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "0.2rem 0.5rem",
                        borderBottomColor: "primary.light",
                      }}
                    >
                      {isUrl(DataBaseList?.[0]?.reference) ? (
                        <span
                          style={{
                            textDecoration: "underline",
                            cursor: "pointer",
                          }}
                          onClick={() =>
                            handleOpenLinkReference(
                              DataBaseList?.[0]?.reference
                            )
                          }
                        >
                          Link
                        </span>
                      ) : (
                        <span
                          style={{
                            textDecoration: "underline",
                            cursor: "pointer",
                          }}
                        >
                          {DataBaseList?.[0]?.reference}
                        </span>
                      )}
                    </TableCell>
                  </TableRow>
                </>
              </>
            )}
          </>
        );
      case 4:
        return (
          <>
            <TableRow>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}
              >
                Tool Code{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}
              >
                {DataBaseList[0]?.tools?.code}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}
              >
                Tool Name{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}
              >
                {DataBaseList[0]?.tools?.name}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}
              >
                Specification{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}
              >
                {DataBaseList[0]?.tools?.specification}{" "}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}
              >
                Catogory{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}
              >
                {DataBaseList[0]?.tools?.commodity_name}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}
              >
                Supplier{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}
              >
                {DataBaseList[0]?.tools?.supplier}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}
              >
                Material{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}
              >
                {DataBaseList[0]?.tools?.material}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}
              >
                Other{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}
              >
                {DataBaseList[0]?.tools?.other}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}
              >
                No. of Shots{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}
              >
                {DataBaseList[0]?.shots}
              </TableCell>
            </TableRow>

            {AssumtionIds && AssumtionIds?.length > 0 ? (
              <>
                <TableRow>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    Tool Type
                  </TableCell>

                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    {DataBaseList[0]?.tools_detail?.tools_type}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    Unit Rate (INR){" "}
                    {DataBaseList &&
                      DataBaseList[0]?.tool_cost_is_user_input ===
                        false && (
                        <img
                          src={calcGreen}
                          style={{
                            width: "1rem",
                            height: "1rem",
                            cursor:'pointer'
                          }}
                          onClick={()=>{openConfig.open("Tool")}}
                        />
                      )}
                  </TableCell>

                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    {DataBaseList[0]?.tools_detail?.tools_cost}
                  </TableCell>
                </TableRow>
              </>
            ) : (
              <TableRow>
                <TableCell
                  sx={{
                    padding: "0.2rem 0.5rem",
                    borderBottomColor: "primary.light",
                  }}
                >
                  Unit Rate (INR){" "}
                  {DataBaseList &&
                      DataBaseList[0]?.tool_cost_is_user_input ===
                        false && (
                        <img
                          src={calcGreen}
                          style={{
                            width: "1rem",
                            height: "1rem",
                            cursor:'pointer'
                          }}
                          onClick={()=>{openConfig.open("Tool")}}
                        />
                      )}
                </TableCell>
                <TableCell
                  sx={{
                    padding: "0.2rem 0.5rem",
                    borderBottomColor: "primary.light",
                  }}
                >
                  {DataBaseList[0]?.price}
                </TableCell>
              </TableRow>
            )}
            <TableRow>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}
              >
                Reference{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}
              >
                {isUrl(DataBaseList?.[0]?.details) ? (
                  <span
                    style={{ textDecoration: "underline", cursor: "pointer" }}
                    onClick={() =>
                      handleOpenLinkReference(DataBaseList?.[0]?.details)
                    }
                  >
                    Link
                  </span>
                ) : (
                  <span
                    style={{ textDecoration: "underline", cursor: "pointer" }}
                  >
                    {DataBaseList?.[0]?.details}
                  </span>
                )}
              </TableCell>
            </TableRow>
          </>
        );
      case 1:
        return (
          <>
            <TableRow>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}
              >
                Material Code
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}
              >
                {DataBaseList[0]?.material?.material_code}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}
              >
                Material Category
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}
              >
                {DataBaseList[0]?.material?.category}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}
              >
                Grade
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}
              >
                {DataBaseList[0]?.material?.material_grade}{" "}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}
              >
                Sub Grade
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}
              >
                {DataBaseList[0]?.material?.material_subgrade}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}
              >
                Shape
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}
              >
                {" "}
                {DataBaseList[0]?.material?.shape}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}
              >
                Density
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}
              >
                {DataBaseList[0]?.material?.density}
              </TableCell>
            </TableRow>

            {AssumtionIds && AssumtionIds?.length > 0 ? (
              <>
                <TableRow>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    Material Type
                  </TableCell>

                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    {DataBaseList[0]?.material_detail?.material_type}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                      display:'flex',
                      alignItems:'center',
                      gap:'1rem'
                    }}
                  >
                    Material Cost
                    {DataBaseList &&
                      DataBaseList[0]?.material_cost_is_user_input ===
                        false && (
                        <img
                          src={calcGreen}
                          style={{
                            width: "1rem",
                            height: "1rem",
                            cursor:'pointer'
                          }}
                          onClick={()=>{openConfig.open("material")}}
                        />
                      )}
                  </TableCell>

                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    {DataBaseList[0]?.material_detail?.material_cost}
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    Scrap Type
                  </TableCell>

                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    {DataBaseList[0]?.material_detail?.scrap_type}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    Scrap Cost
                  </TableCell>

                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    {DataBaseList[0]?.material_detail?.scrap_cost}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    Reference
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    {isUrl(DataBaseList[0]?.scrap_reference) ? (
                      <span
                        style={{
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          handleOpenLinkReference(
                            DataBaseList[0]?.scrap_reference
                          )
                        }
                      >
                        Link
                      </span>
                    ) : (
                      <span
                        style={{
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                      >
                        {DataBaseList[0]?.scrap_reference}
                      </span>
                    )}
                  </TableCell>
                </TableRow>
              </>
            ) : (
              <>
                <TableRow>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                      display:'flex',
                      alignItems:'center',
                      gap:'1rem'
                    }}
                  >
                    Material Cost
                    {DataBaseList &&
                      DataBaseList[0]?.material_cost_is_user_input ===
                        false && (
                        <img
                          src={calcGreen}
                          style={{
                            width: "1rem",
                            height: "1rem",
                            cursor:'pointer'
                          }}
                          onClick={()=>{openConfig.open("material")}}
                        />
                      )}
                  </TableCell>

                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    {DataBaseList[0]?.material_cost}
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    Scrap Cost
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    {DataBaseList[0]?.scrap_cost}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    Reference
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    {isUrl(DataBaseList[0]?.material_reference) ? (
                      <span
                        style={{
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          handleOpenLinkReference(
                            DataBaseList[0]?.material_reference
                          )
                        }
                      >
                        Link
                      </span>
                    ) : (
                      <span
                        style={{
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                      >
                        {DataBaseList[0]?.material_reference}
                      </span>
                    )}
                  </TableCell>
                </TableRow>
              </>
            )}
            <TableRow>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}
              >
                Remarks
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}
              >
                {DataBaseList[0]?.material?.description == ""
                  ? "-"
                  : DataBaseList[0]?.material?.description}
              </TableCell>
            </TableRow>
          </>
        );
      case 6:
        switch (DataBaseList[0]?.commodity_name) {
          case "Electronic Child Components":
            return (
              <>
                <TableRow>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    BOP Item Name
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    {DataBaseList[0]?.name}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    Item Description
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    {DataBaseList[0]?.description}{" "}
                  </TableCell>
                </TableRow>

                {AssumtionIds && AssumtionIds?.length > 0 ? (
                  <>
                    <TableRow>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}
                      >
                        Value Type
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}
                      >
                        {DataBaseList[0]?.purchase_detail?.bop_type}{" "}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}
                      >
                        Per Unit Cost
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}
                      >
                        {DataBaseList[0]?.purchase_detail?.bop_cost}{" "}
                      </TableCell>
                    </TableRow>
                  </>
                ) : (
                  <TableRow>
                    <TableCell
                      sx={{
                        padding: "0.2rem 0.5rem",
                        borderBottomColor: "primary.light",
                      }}
                    >
                      Per Unit Cost{" "}
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "0.2rem 0.5rem",
                        borderBottomColor: "primary.light",
                      }}
                    >
                      {DataBaseList[0]?.bop_cost}{" "}
                    </TableCell>
                  </TableRow>
                )}
                <TableRow>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    Commodity
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    {DataBaseList[0]?.commodity_name}{" "}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    Length (mm)
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    {DataBaseList[0]?.length}{" "}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    Width (mm)
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    {DataBaseList[0]?.width}{" "}
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    Currency/Unit
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    {DataBaseList[0]?.bop_currency || "-"}/
                    {DataBaseList[0]?.bop_unit || "-"}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    Reference
                  </TableCell>

                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    {isUrl(DataBaseList?.[0]?.reference) ? (
                      <span
                        style={{
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          handleOpenLinkReference(DataBaseList?.[0]?.reference)
                        }
                      >
                        Link
                      </span>
                    ) : (
                      <span
                        style={{
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                      >
                        {DataBaseList?.[0]?.reference}
                      </span>
                    )}
                  </TableCell>
                </TableRow>
              </>
            );
          case "Fasteners":
            return (
              <>
                <TableRow>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    BOP Item Code
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    {DataBaseList[0]?.code}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    BOP Item Name
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    {DataBaseList[0]?.name}
                  </TableCell>
                </TableRow>
                {AssumtionIds && AssumtionIds?.length > 0 ? (
                  <>
                    <TableRow>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}
                      >
                        Value Type
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}
                      >
                        {DataBaseList[0]?.purchase_detail?.bop_type}{" "}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}
                      >
                        Per Unit Cost
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}
                      >
                        {DataBaseList[0]?.purchase_detail?.bop_cost}{" "}
                      </TableCell>
                    </TableRow>
                  </>
                ) : (
                  <TableRow>
                    <TableCell
                      sx={{
                        padding: "0.2rem 0.5rem",
                        borderBottomColor: "primary.light",
                      }}
                    >
                      Per Unit Cost{" "}
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "0.2rem 0.5rem",
                        borderBottomColor: "primary.light",
                      }}
                    >
                      {DataBaseList[0]?.bop_cost}{" "}
                    </TableCell>
                  </TableRow>
                )}
                <TableRow>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    Commodity
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    {DataBaseList[0]?.commodity_name}{" "}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    Type of Fastener
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    {DataBaseList[0]?.type}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    Material
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    {DataBaseList[0]?.material}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    M-Size
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    {DataBaseList[0]?.m_size}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    Thread Length
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    {DataBaseList[0]?.thread_length}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    Grade
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    {DataBaseList[0]?.grade}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    Length (mm)
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    {DataBaseList[0]?.length}{" "}
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    Weight (kg)
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    {DataBaseList[0]?.weight}
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    Currency/Unit
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    {DataBaseList[0]?.bop_currency || "-"}/
                    {DataBaseList[0]?.bop_unit || "-"}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    Reference
                  </TableCell>

                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    {isUrl(DataBaseList?.[0]?.reference) ? (
                      <span
                        style={{
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          handleOpenLinkReference(DataBaseList?.[0]?.reference)
                        }
                      >
                        Link
                      </span>
                    ) : (
                      <span
                        style={{
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                      >
                        {DataBaseList?.[0]?.reference}
                      </span>
                    )}
                  </TableCell>
                </TableRow>
              </>
            );
          case "Bearings":
            return (
              <>
                <TableRow>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    BOP Item Code
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    {DataBaseList[0]?.code}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    BOP Item Name
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    {DataBaseList[0]?.name}
                  </TableCell>
                </TableRow>
                {AssumtionIds && AssumtionIds?.length > 0 ? (
                  <>
                    <TableRow>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}
                      >
                        Value Type
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}
                      >
                        {DataBaseList[0]?.purchase_detail?.bop_type}{" "}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}
                      >
                        Per Unit Cost
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}
                      >
                        {DataBaseList[0]?.purchase_detail?.bop_cost}{" "}
                      </TableCell>
                    </TableRow>
                  </>
                ) : (
                  <TableRow>
                    <TableCell
                      sx={{
                        padding: "0.2rem 0.5rem",
                        borderBottomColor: "primary.light",
                      }}
                    >
                      Per Unit Cost{" "}
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "0.2rem 0.5rem",
                        borderBottomColor: "primary.light",
                      }}
                    >
                      {DataBaseList[0]?.bop_cost}{" "}
                    </TableCell>
                  </TableRow>
                )}
                <TableRow>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    Commodity
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    {DataBaseList[0]?.commodity_name}{" "}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    Height (mm)
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    {DataBaseList[0]?.height}{" "}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    Weight (kg)
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    {DataBaseList[0]?.weight}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    ID(mm)
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    {DataBaseList[0]?.item_id}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    OD(mm)
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    {DataBaseList[0]?.item_od}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    Currency/Unit
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    {DataBaseList[0]?.bop_currency || "-"}/
                    {DataBaseList[0]?.bop_unit || "-"}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    Reference
                  </TableCell>

                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    {isUrl(DataBaseList?.[0]?.reference) ? (
                      <span
                        style={{
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          handleOpenLinkReference(DataBaseList?.[0]?.reference)
                        }
                      >
                        Link
                      </span>
                    ) : (
                      <span
                        style={{
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                      >
                        {DataBaseList?.[0]?.reference}
                      </span>
                    )}
                  </TableCell>
                </TableRow>
              </>
            );

          case "Connectors":
            return (
              <>
                <TableRow>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    BOP Item Name
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    {DataBaseList[0]?.name}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    No. of Pins
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    {DataBaseList[0]?.no_of_pins}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    Position Config(mm)
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    {DataBaseList[0]?.postion_config}{" "}
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    Shape
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    {DataBaseList[0]?.shape}{" "}
                  </TableCell>
                </TableRow>
                {AssumtionIds && AssumtionIds?.length > 0 ? (
                  <>
                    <TableRow>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}
                      >
                        Value Type
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}
                      >
                        {DataBaseList[0]?.purchase_detail?.bop_type}{" "}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}
                      >
                        Per Unit Cost
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}
                      >
                        {DataBaseList[0]?.purchase_detail?.bop_cost}{" "}
                      </TableCell>
                    </TableRow>
                  </>
                ) : (
                  <TableRow>
                    <TableCell
                      sx={{
                        padding: "0.2rem 0.5rem",
                        borderBottomColor: "primary.light",
                      }}
                    >
                      Per Unit Cost{" "}
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "0.2rem 0.5rem",
                        borderBottomColor: "primary.light",
                      }}
                    >
                      {DataBaseList[0]?.bop_cost}{" "}
                    </TableCell>
                  </TableRow>
                )}
                <TableRow>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    Commodity
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    {DataBaseList[0]?.commodity_name}{" "}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    Terminal pin pitch
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    {DataBaseList[0]?.pin_pitch}{" "}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    Male/Female
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    {DataBaseList[0]?.gender}{" "}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    Fit Type
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    {DataBaseList[0]?.fit_type}{" "}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    Material
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    {DataBaseList[0]?.material}
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    Currency/Unit
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    {DataBaseList[0]?.bop_currency || "-"}/
                    {DataBaseList[0]?.bop_unit || "-"}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    Reference
                  </TableCell>

                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    {isUrl(DataBaseList?.[0]?.reference) ? (
                      <span
                        style={{
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          handleOpenLinkReference(DataBaseList?.[0]?.reference)
                        }
                      >
                        Link
                      </span>
                    ) : (
                      <span
                        style={{
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                      >
                        {DataBaseList?.[0]?.reference}
                      </span>
                    )}
                  </TableCell>
                </TableRow>
              </>
            );
          case "Consumables":
            return (
              <>
                <TableRow>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    BOP Item Code
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    {DataBaseList[0]?.code}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    BOP Item Name
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    {DataBaseList[0]?.name}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    Item Description
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    {DataBaseList[0]?.description}{" "}
                  </TableCell>
                </TableRow>

                {AssumtionIds && AssumtionIds?.length > 0 ? (
                  <>
                    <TableRow>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}
                      >
                        Value Type
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}
                      >
                        {DataBaseList[0]?.purchase_detail?.bop_type}{" "}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}
                      >
                        Per Unit Cost
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}
                      >
                        {DataBaseList[0]?.purchase_detail?.bop_cost}{" "}
                      </TableCell>
                    </TableRow>
                  </>
                ) : (
                  <TableRow>
                    <TableCell
                      sx={{
                        padding: "0.2rem 0.5rem",
                        borderBottomColor: "primary.light",
                      }}
                    >
                      Per Unit Cost{" "}
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "0.2rem 0.5rem",
                        borderBottomColor: "primary.light",
                      }}
                    >
                      {DataBaseList[0]?.bop_cost}{" "}
                    </TableCell>
                  </TableRow>
                )}
                <TableRow>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    Commodity
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    {DataBaseList[0]?.commodity_name}{" "}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    Unit
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    {DataBaseList[0]?.unit}{" "}
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    Currency/Unit
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    {DataBaseList[0]?.bop_currency || "-"}/
                    {DataBaseList[0]?.bop_unit || "-"}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    Reference
                  </TableCell>

                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    {isUrl(DataBaseList?.[0]?.reference) ? (
                      <span
                        style={{
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          handleOpenLinkReference(DataBaseList?.[0]?.reference)
                        }
                      >
                        Link
                      </span>
                    ) : (
                      <span
                        style={{
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                      >
                        {DataBaseList?.[0]?.reference}
                      </span>
                    )}
                  </TableCell>
                </TableRow>
              </>
            );
          case "Uncategorized":
            return (
              <>
                <TableRow>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    BOP Item Code
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    {DataBaseList[0]?.code}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    BOP Item Name
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    {DataBaseList[0]?.name}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    Item Description
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    {DataBaseList[0]?.description}{" "}
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    Item Details
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    {DataBaseList[0]?.item_detail}{" "}
                  </TableCell>
                </TableRow>
                {AssumtionIds && AssumtionIds?.length > 0 ? (
                  <>
                    <TableRow>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}
                      >
                        Value Type
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}
                      >
                        {DataBaseList[0]?.purchase_detail?.bop_type}{" "}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}
                      >
                        Per Unit Cost
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}
                      >
                        {DataBaseList[0]?.purchase_detail?.bop_cost}{" "}
                      </TableCell>
                    </TableRow>
                  </>
                ) : (
                  <TableRow>
                    <TableCell
                      sx={{
                        padding: "0.2rem 0.5rem",
                        borderBottomColor: "primary.light",
                      }}
                    >
                      Per Unit Cost
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "0.2rem 0.5rem",
                        borderBottomColor: "primary.light",
                      }}
                    >
                      {DataBaseList[0]?.bop_cost}{" "}
                    </TableCell>
                  </TableRow>
                )}
                <TableRow>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    Commodity
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    {DataBaseList[0]?.commodity_name}{" "}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    Length (mm)
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    {DataBaseList[0]?.length}{" "}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    Width (mm)
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    {DataBaseList[0]?.width}{" "}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    Height (mm)
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    {DataBaseList[0]?.height}{" "}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    Weight (kg)
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    {DataBaseList[0]?.weight}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    Place of Mfg.
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    {DataBaseList[0]?.place}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    Currency/Unit
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    {DataBaseList[0]?.bop_currency || "-"}/
                    {DataBaseList[0]?.bop_unit || "-"}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    Reference
                  </TableCell>

                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    {isUrl(DataBaseList?.[0]?.reference) ? (
                      <span
                        style={{
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          handleOpenLinkReference(DataBaseList?.[0]?.reference)
                        }
                      >
                        Link
                      </span>
                    ) : (
                      <span
                        style={{
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                      >
                        {DataBaseList?.[0]?.reference}
                      </span>
                    )}
                  </TableCell>
                </TableRow>
              </>
            );
          case "Mechanical Proprietory Parts":
            return (
              <>
                <TableRow>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    BOP Item Code
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    {DataBaseList[0]?.code}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    BOP Item Name
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    {DataBaseList[0]?.name}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    Item Description
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    {DataBaseList[0]?.description}{" "}
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    Brand and Model
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    {DataBaseList[0]?.brand_name}{" "}
                  </TableCell>
                </TableRow>
                {AssumtionIds && AssumtionIds?.length > 0 ? (
                  <>
                    <TableRow>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}
                      >
                        Value Type
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}
                      >
                        {DataBaseList[0]?.purchase_detail?.bop_type}{" "}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}
                      >
                        Per Unit Cost
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}
                      >
                        {DataBaseList[0]?.purchase_detail?.bop_cost}{" "}
                      </TableCell>
                    </TableRow>
                  </>
                ) : (
                  <TableRow>
                    <TableCell
                      sx={{
                        padding: "0.2rem 0.5rem",
                        borderBottomColor: "primary.light",
                      }}
                    >
                      Per Unit Cost
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "0.2rem 0.5rem",
                        borderBottomColor: "primary.light",
                      }}
                    >
                      {DataBaseList[0]?.bop_cost}{" "}
                    </TableCell>
                  </TableRow>
                )}
                <TableRow>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    Commodity
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    {DataBaseList[0]?.commodity_name}{" "}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    Length (mm)
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    {DataBaseList[0]?.length}{" "}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    Width (mm)
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    {DataBaseList[0]?.width}{" "}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    Height (mm)
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    {DataBaseList[0]?.height}{" "}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    Weight (kg)
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    {DataBaseList[0]?.weight}
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    Currency/Unit
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    {DataBaseList[0]?.bop_currency || "-"}/
                    {DataBaseList[0]?.bop_unit || "-"}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    Reference
                  </TableCell>

                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    {isUrl(DataBaseList?.[0]?.reference) ? (
                      <span
                        style={{
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          handleOpenLinkReference(DataBaseList?.[0]?.reference)
                        }
                      >
                        Link
                      </span>
                    ) : (
                      <span
                        style={{
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                      >
                        {DataBaseList?.[0]?.reference}
                      </span>
                    )}
                  </TableCell>
                </TableRow>
              </>
            );
          case "Electrical & Electronics Proprietary Parts":
            switch (DataBaseList[0]?.module_type) {
              case "Bulb":
                return (
                  <>
                    <TableRow>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}
                      >
                        BOP Item Name
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}
                      >
                        {DataBaseList[0]?.name}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}
                      >
                        Type
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}
                      >
                        {DataBaseList[0]?.type}{" "}
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}
                      >
                        Rated Voltage
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}
                      >
                        {DataBaseList[0]?.rated_voltage}{" "}
                      </TableCell>
                    </TableRow>
                    {AssumtionIds && AssumtionIds?.length > 0 ? (
                      <>
                        <TableRow>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                            }}
                          >
                            Value Type
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                            }}
                          >
                            {DataBaseList[0]?.purchase_detail?.bop_type}{" "}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                            }}
                          >
                            Per Unit Cost
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                            }}
                          >
                            {DataBaseList[0]?.purchase_detail?.bop_cost}{" "}
                          </TableCell>
                        </TableRow>
                      </>
                    ) : (
                      <TableRow>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                          }}
                        >
                          Per Unit Cost{" "}
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                          }}
                        >
                          {DataBaseList[0]?.bop_cost}{" "}
                        </TableCell>
                      </TableRow>
                    )}
                    <TableRow>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}
                      >
                        Commodity
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}
                      >
                        {DataBaseList[0]?.commodity_name}{" "}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}
                      >
                        Base Code
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}
                      >
                        {DataBaseList[0]?.base_code}{" "}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}
                      >
                        No. of filaments
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}
                      >
                        {DataBaseList[0]?.no_of_filament}{" "}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}
                      >
                        Filament Wattage
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}
                      >
                        {DataBaseList[0]?.watttage}{" "}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}
                      >
                        Currency/Unit
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}
                      >
                        {DataBaseList[0]?.bop_currency || "-"}/
                        {DataBaseList[0]?.bop_unit || "-"}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}
                      >
                        Reference
                      </TableCell>

                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}
                      >
                        {isUrl(DataBaseList?.[0]?.reference) ? (
                          <span
                            style={{
                              textDecoration: "underline",
                              cursor: "pointer",
                            }}
                            onClick={() =>
                              handleOpenLinkReference(
                                DataBaseList?.[0]?.reference
                              )
                            }
                          >
                            Link
                          </span>
                        ) : (
                          <span
                            style={{
                              textDecoration: "underline",
                              cursor: "pointer",
                            }}
                          >
                            {DataBaseList?.[0]?.reference}
                          </span>
                        )}
                      </TableCell>
                    </TableRow>
                  </>
                );
              case "Horn":
                return (
                  <>
                    <TableRow>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}
                      >
                        BOP Item Name
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}
                      >
                        {DataBaseList[0]?.name}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}
                      >
                        Type
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}
                      >
                        {DataBaseList[0]?.type}{" "}
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}
                      >
                        Rated Voltage
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}
                      >
                        {DataBaseList[0]?.rated_voltage}{" "}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}
                      >
                        Current Rating
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}
                      >
                        {DataBaseList[0]?.current_rating}{" "}
                      </TableCell>
                    </TableRow>
                    {AssumtionIds && AssumtionIds?.length > 0 ? (
                      <>
                        <TableRow>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                            }}
                          >
                            Value Type
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                            }}
                          >
                            {DataBaseList[0]?.purchase_detail?.bop_type}{" "}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                            }}
                          >
                            Per Unit Cost
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                            }}
                          >
                            {DataBaseList[0]?.purchase_detail?.bop_cost}{" "}
                          </TableCell>
                        </TableRow>
                      </>
                    ) : (
                      <TableRow>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                          }}
                        >
                          Per Unit Cost{" "}
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                          }}
                        >
                          {DataBaseList[0]?.bop_cost}{" "}
                        </TableCell>
                      </TableRow>
                    )}
                    <TableRow>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}
                      >
                        Commodity
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}
                      >
                        {DataBaseList[0]?.commodity_name}{" "}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}
                      >
                        Power Rating
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}
                      >
                        {DataBaseList[0]?.power_rating}{" "}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}
                      >
                        Frequency
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}
                      >
                        {DataBaseList[0]?.frequency}{" "}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}
                      >
                        Sound Level
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}
                      >
                        {DataBaseList[0]?.sound_level}{" "}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}
                      >
                        Currency/Unit
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}
                      >
                        {DataBaseList[0]?.bop_currency || "-"}/
                        {DataBaseList[0]?.bop_unit || "-"}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}
                      >
                        Reference
                      </TableCell>

                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}
                      >
                        {isUrl(DataBaseList?.[0]?.reference) ? (
                          <span
                            style={{
                              textDecoration: "underline",
                              cursor: "pointer",
                            }}
                            onClick={() =>
                              handleOpenLinkReference(
                                DataBaseList?.[0]?.reference
                              )
                            }
                          >
                            Link
                          </span>
                        ) : (
                          <span
                            style={{
                              textDecoration: "underline",
                              cursor: "pointer",
                            }}
                          >
                            {DataBaseList?.[0]?.reference}
                          </span>
                        )}
                      </TableCell>
                    </TableRow>
                  </>
                );
              case "Ignition":
                return (
                  <>
                    <TableRow>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}
                      >
                        BOP Item Name
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}
                      >
                        {DataBaseList[0]?.name}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}
                      >
                        Type
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}
                      >
                        {DataBaseList[0]?.type}{" "}
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}
                      >
                        Rated Voltage
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}
                      >
                        {DataBaseList[0]?.rated_voltage}{" "}
                      </TableCell>
                    </TableRow>

                    {AssumtionIds && AssumtionIds?.length > 0 ? (
                      <>
                        <TableRow>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                            }}
                          >
                            Value Type
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                            }}
                          >
                            {DataBaseList[0]?.purchase_detail?.bop_type}{" "}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                            }}
                          >
                            Per Unit Cost
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                            }}
                          >
                            {DataBaseList[0]?.purchase_detail?.bop_cost}{" "}
                          </TableCell>
                        </TableRow>
                      </>
                    ) : (
                      <TableRow>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                          }}
                        >
                          Per Unit Cost{" "}
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                          }}
                        >
                          {DataBaseList[0]?.bop_cost}{" "}
                        </TableCell>
                      </TableRow>
                    )}
                    <TableRow>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}
                      >
                        Commodity
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}
                      >
                        {DataBaseList[0]?.commodity_name}{" "}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}
                      >
                        No. of Positions
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}
                      >
                        {DataBaseList[0]?.no_of_position}{" "}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}
                      >
                        No. of Terminals
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}
                      >
                        {DataBaseList[0]?.no_of_terminal}{" "}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}
                      >
                        With handle lock (Y/N):
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}
                      >
                        {DataBaseList[0]?.with_handle_lock}{" "}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}
                      >
                        Currency/Unit
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}
                      >
                        {DataBaseList[0]?.bop_currency || "-"}/
                        {DataBaseList[0]?.bop_unit || "-"}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}
                      >
                        Reference
                      </TableCell>

                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}
                      >
                        {isUrl(DataBaseList?.[0]?.reference) ? (
                          <span
                            style={{
                              textDecoration: "underline",
                              cursor: "pointer",
                            }}
                            onClick={() =>
                              handleOpenLinkReference(
                                DataBaseList?.[0]?.reference
                              )
                            }
                          >
                            Link
                          </span>
                        ) : (
                          <span
                            style={{
                              textDecoration: "underline",
                              cursor: "pointer",
                            }}
                          >
                            {DataBaseList?.[0]?.reference}
                          </span>
                        )}
                      </TableCell>
                    </TableRow>
                  </>
                );
              case "Flasher":
                return (
                  <>
                    <TableRow>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}
                      >
                        BOP Item Name
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}
                      >
                        {DataBaseList[0]?.name}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}
                      >
                        Type
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}
                      >
                        {DataBaseList[0]?.type}{" "}
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}
                      >
                        Rated Voltage
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}
                      >
                        {DataBaseList[0]?.rated_voltage}{" "}
                      </TableCell>
                    </TableRow>

                    {AssumtionIds && AssumtionIds?.length > 0 ? (
                      <>
                        <TableRow>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                            }}
                          >
                            Value Type
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                            }}
                          >
                            {DataBaseList[0]?.purchase_detail?.bop_type}{" "}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                            }}
                          >
                            Per Unit Cost
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                            }}
                          >
                            {DataBaseList[0]?.purchase_detail?.bop_cost}{" "}
                          </TableCell>
                        </TableRow>
                      </>
                    ) : (
                      <TableRow>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                          }}
                        >
                          Per Unit Cost{" "}
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                          }}
                        >
                          {DataBaseList[0]?.bop_cost}{" "}
                        </TableCell>
                      </TableRow>
                    )}
                    <TableRow>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}
                      >
                        Commodity
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}
                      >
                        {DataBaseList[0]?.commodity_name}{" "}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}
                      >
                        No. of Terminals
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}
                      >
                        {DataBaseList[0]?.no_of_terminal}{" "}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}
                      >
                        Flasher Frequency
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}
                      >
                        {DataBaseList[0]?.frequency}{" "}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}
                      >
                        Wattage
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}
                      >
                        {DataBaseList[0]?.watttage}{" "}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}
                      >
                        Currency/Unit
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}
                      >
                        {DataBaseList[0]?.bop_currency || "-"}/
                        {DataBaseList[0]?.bop_unit || "-"}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}
                      >
                        Reference
                      </TableCell>

                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}
                      >
                        {isUrl(DataBaseList?.[0]?.reference) ? (
                          <span
                            style={{
                              textDecoration: "underline",
                              cursor: "pointer",
                            }}
                            onClick={() =>
                              handleOpenLinkReference(
                                DataBaseList?.[0]?.reference
                              )
                            }
                          >
                            Link
                          </span>
                        ) : (
                          <span
                            style={{
                              textDecoration: "underline",
                              cursor: "pointer",
                            }}
                          >
                            {DataBaseList?.[0]?.reference}
                          </span>
                        )}
                      </TableCell>
                    </TableRow>
                  </>
                );
              case "Remote Key/Lock":
                return (
                  <>
                    <TableRow>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}
                      >
                        BOP Item Name
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}
                      >
                        {DataBaseList[0]?.name}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}
                      >
                        Type
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}
                      >
                        {DataBaseList[0]?.type}{" "}
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}
                      >
                        Sensing Technology
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}
                      >
                        {DataBaseList[0]?.sensing_technology}{" "}
                      </TableCell>
                    </TableRow>

                    {AssumtionIds && AssumtionIds?.length > 0 ? (
                      <>
                        <TableRow>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                            }}
                          >
                            Value Type
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                            }}
                          >
                            {DataBaseList[0]?.purchase_detail?.bop_type}{" "}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                            }}
                          >
                            Per Unit Cost
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                            }}
                          >
                            {DataBaseList[0]?.purchase_detail?.bop_cost}{" "}
                          </TableCell>
                        </TableRow>
                      </>
                    ) : (
                      <TableRow>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                          }}
                        >
                          Per Unit Cost
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                          }}
                        >
                          {DataBaseList[0]?.bop_cost}{" "}
                        </TableCell>
                      </TableRow>
                    )}
                    <TableRow>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}
                      >
                        Commodity
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}
                      >
                        {DataBaseList[0]?.commodity_name}{" "}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}
                      >
                        Type of Key Fob
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}
                      >
                        {DataBaseList[0]?.type_of_key_fob}{" "}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}
                      >
                        No. of Functions
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}
                      >
                        {DataBaseList[0]?.no_of_function}{" "}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}
                      >
                        Functions
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}
                      >
                        {DataBaseList[0]?.function}{" "}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}
                      >
                        Range
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}
                      >
                        {DataBaseList[0]?.range}{" "}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}
                      >
                        Currency/Unit
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}
                      >
                        {DataBaseList[0]?.bop_currency || "-"}/
                        {DataBaseList[0]?.bop_unit || "-"}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}
                      >
                        Reference
                      </TableCell>

                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}
                      >
                        {isUrl(DataBaseList?.[0]?.reference) ? (
                          <span
                            style={{
                              textDecoration: "underline",
                              cursor: "pointer",
                            }}
                            onClick={() =>
                              handleOpenLinkReference(
                                DataBaseList?.[0]?.reference
                              )
                            }
                          >
                            Link
                          </span>
                        ) : (
                          <span
                            style={{
                              textDecoration: "underline",
                              cursor: "pointer",
                            }}
                          >
                            {DataBaseList?.[0]?.reference}
                          </span>
                        )}
                      </TableCell>
                    </TableRow>
                  </>
                );
              default:
                return (
                  <>
                    <TableRow>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}
                      >
                        BOP Item Name
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}
                      >
                        {DataBaseList[0]?.name}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}
                      >
                        Type
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}
                      >
                        {DataBaseList[0]?.type}{" "}
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}
                      >
                        Rated Voltage
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}
                      >
                        {DataBaseList[0]?.rated_voltage}{" "}
                      </TableCell>
                    </TableRow>

                    {AssumtionIds && AssumtionIds?.length > 0 ? (
                      <>
                        <TableRow>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                            }}
                          >
                            Value Type
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                            }}
                          >
                            {DataBaseList[0]?.purchase_detail?.bop_type}{" "}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                            }}
                          >
                            Per Unit Cost
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                            }}
                          >
                            {DataBaseList[0]?.purchase_detail?.bop_cost}{" "}
                          </TableCell>
                        </TableRow>
                      </>
                    ) : (
                      <TableRow>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                          }}
                        >
                          Per Unit Cost{" "}
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                          }}
                        >
                          {DataBaseList[0]?.bop_cost}{" "}
                        </TableCell>
                      </TableRow>
                    )}
                    <TableRow>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}
                      >
                        Commodity
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}
                      >
                        {DataBaseList[0]?.commodity_name}{" "}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}
                      >
                        No. of Positions
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}
                      >
                        {DataBaseList[0]?.no_of_position}{" "}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}
                      >
                        No. of Terminals
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}
                      >
                        {DataBaseList[0]?.no_of_terminal}{" "}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}
                      >
                        With handle lock (Y/N):
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}
                      >
                        {DataBaseList[0]?.with_handle_lock}{" "}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}
                      >
                        Currency/Unit
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}
                      >
                        {DataBaseList[0]?.bop_currency || "-"}/
                        {DataBaseList[0]?.bop_unit || "-"}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}
                      >
                        Reference
                      </TableCell>

                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}
                      >
                        {isUrl(DataBaseList?.[0]?.reference) ? (
                          <span
                            style={{
                              textDecoration: "underline",
                              cursor: "pointer",
                            }}
                            onClick={() =>
                              handleOpenLinkReference(
                                DataBaseList?.[0]?.reference
                              )
                            }
                          >
                            Link
                          </span>
                        ) : (
                          <span
                            style={{
                              textDecoration: "underline",
                              cursor: "pointer",
                            }}
                          >
                            {DataBaseList?.[0]?.reference}
                          </span>
                        )}
                      </TableCell>
                    </TableRow>
                  </>
                );
            }
          default:
            return (
              <>
                <TableRow>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    BOP Item Code
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    {DataBaseList[0]?.code}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    BOP Item Name
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    {DataBaseList[0]?.name}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    Item Description
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    {DataBaseList[0]?.description}{" "}
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    Item Details
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    {DataBaseList[0]?.item_detail}{" "}
                  </TableCell>
                </TableRow>
                {AssumtionIds && AssumtionIds?.length > 0 ? (
                  <>
                    <TableRow>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}
                      >
                        Value Type
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}
                      >
                        {DataBaseList[0]?.purchase_detail?.bop_type}{" "}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}
                      >
                        BOP Cost
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}
                      >
                        {DataBaseList[0]?.purchase_detail?.bop_cost}{" "}
                      </TableCell>
                    </TableRow>
                  </>
                ) : (
                  <TableRow>
                    <TableCell
                      sx={{
                        padding: "0.2rem 0.5rem",
                        borderBottomColor: "primary.light",
                      }}
                    >
                      BOP Cost{" "}
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "0.2rem 0.5rem",
                        borderBottomColor: "primary.light",
                      }}
                    >
                      {DataBaseList[0]?.bop_cost}{" "}
                    </TableCell>
                  </TableRow>
                )}
                <TableRow>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    Commodity
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    {DataBaseList[0]?.commodity_name}{" "}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    Length (mm)
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    {DataBaseList[0]?.length}{" "}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    Width (mm)
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    {DataBaseList[0]?.width}{" "}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    Height (mm)
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    {DataBaseList[0]?.height}{" "}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    Weight (kg)
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    {DataBaseList[0]?.weight}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    Place of Mfg.
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    {DataBaseList[0]?.place}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    Currency/Unit
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    {DataBaseList[0]?.bop_currency || "-"}/
                    {DataBaseList[0]?.bop_unit || "-"}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    Reference
                  </TableCell>

                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    {isUrl(DataBaseList?.[0]?.reference) ? (
                      <span
                        style={{
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          handleOpenLinkReference(DataBaseList?.[0]?.reference)
                        }
                      >
                        Link
                      </span>
                    ) : (
                      <span
                        style={{
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                      >
                        {DataBaseList?.[0]?.reference}
                      </span>
                    )}
                  </TableCell>
                </TableRow>
              </>
            );
        }

      case 7:
        return (
          <>
            <TableRow>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}
              >
                Operation Code
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}
              >
                {DataBaseList[0]?.operation?.code}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}
              >
                Category
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}
              >
                {DataBaseList[0]?.operation?.category}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}
              >
                Remarks
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}
              >
                {DataBaseList[0]?.operation?.remark}{" "}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}
              >
                Unit
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}
              >
                {DataBaseList[0]?.operation?.unit}{" "}
              </TableCell>
            </TableRow>

            {AssumtionIds && AssumtionIds?.length > 0 ? (
              <>
                <TableRow>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    Operation Type
                  </TableCell>

                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    {DataBaseList[0]?.operation_detail?.operation_type}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    Operation Cost
                  </TableCell>

                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    {DataBaseList[0]?.operation_detail?.operation_cost}
                  </TableCell>
                </TableRow>
              </>
            ) : (
              <TableRow>
                <TableCell
                  sx={{
                    padding: "0.2rem 0.5rem",
                    borderBottomColor: "primary.light",
                  }}
                >
                  Rate/Unit
                </TableCell>
                <TableCell
                  sx={{
                    padding: "0.2rem 0.5rem",
                    borderBottomColor: "primary.light",
                  }}
                >
                  {DataBaseList[0]?.rate_per_unit}{" "}
                </TableCell>
              </TableRow>
            )}
            <TableRow>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}
              >
                Description
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}
              >
                {DataBaseList[0]?.operation?.description}{" "}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}
              >
                Reference
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}
              >
                {isUrl(DataBaseList?.[0]?.reference) ? (
                  <span
                    style={{ textDecoration: "underline", cursor: "pointer" }}
                    onClick={() =>
                      handleOpenLinkReference(DataBaseList?.[0]?.reference)
                    }
                  >
                    Link
                  </span>
                ) : (
                  <span
                    style={{ textDecoration: "underline", cursor: "pointer" }}
                  >
                    {DataBaseList?.[0]?.reference}
                  </span>
                )}
              </TableCell>
            </TableRow>
          </>
        );

      case 8:
        return (
          <>
            <TableRow>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}
              >
                Name
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}
              >
                {currencyInfo[0]?.name}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}
              >
                Currency of Country
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}
              >
                {currencyInfo[0]?.country_name}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}
              >
                Currency from
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}
              >
                {currencyInfo[0]?.currency_from}{" "}
              </TableCell>
            </TableRow>
            {AssumtionIds && AssumtionIds?.length > 0 ? (
              <>
                <TableRow>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    Value Type
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    {currencyInfo[0]?.currency_detail?.currency_type}{" "}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    Exchange rate
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    {currencyInfo[0]?.currency_detail?.manual_value}{" "}
                  </TableCell>
                </TableRow>
              </>
            ) : (
              <TableRow>
                <TableCell
                  sx={{
                    padding: "0.2rem 0.5rem",
                    borderBottomColor: "primary.light",
                  }}
                >
                  Exchange rate
                </TableCell>
                <TableCell
                  sx={{
                    padding: "0.2rem 0.5rem",
                    borderBottomColor: "primary.light",
                  }}
                >
                  {currencyInfo[0]?.manual_value}{" "}
                </TableCell>
              </TableRow>
            )}
          </>
        );
    }
  };

  
  

  return (
    <>
      {formulamodal.isOpen && (
        <FormulalistModal
          revisionId={formulamodal.propsId?.revision_id}
          isOpen={formulamodal.isOpen}
          onClose={() => formulamodal.close()}
          machine_id={formulamodal.propsId?.machine_id}
          mhr_details={formulamodal.propsId?.mhr_details}
          labour_id={formulamodal.propsId?.labour_id}
          lhr_details={formulamodal.propsId?.lhr_details}
        />
      )}
      <Modal open={isOpen} onClose={onClose}>
        <Box sx={style}>
          <Box sx={{ display: "flex", alignItems: "center", height: "2.5rem" }}>
            <Typography
              sx={{
                fontSize: "1rem",
                fontWeight: "600",
                width: "100%",
                textAlign: "center",
                marginRight: "-2rem",
              }}
            >
              {tableHeading(database)}
            </Typography>
            <CancelIcon
              titleAccess="Close"
              sx={{ cursor: "pointer", margin: "0.5rem" }}
              onClick={onClose}
            />
          </Box>
          <Divider
            sx={{
              borderColor: "primary.light",
              margin: "0 1rem",
              marginBottom: "1rem",
            }}
          />
          <Box
            sx={{ maxHeight: "65vh", width: "100%" }}
            adl-scrollbar="true"
            adl-scrollbar-width="0.3"
          >
            {Loader ? (
              <>
                <Skeleton />
                <Skeleton />
                <Skeleton />
                <Skeleton />
                <Skeleton />
                <Skeleton />
                <Skeleton />
                <Skeleton />
                <Skeleton />
                <Skeleton />
                <Skeleton />
                <Skeleton />
                <Skeleton />
                <Skeleton />
                <Skeleton />
              </>
            ) : (
              <Box>
                <Table>
                  <TableHead
                    sx={{
                      backgroundColor: "primary.light",
                      position: "sticky",
                      top: 0,
                      zIndex: 10,
                    }}
                  >
                    <TableRow>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                          width: "15rem",
                        }}
                      >
                        Name
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}
                      >
                        Value
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>{databaseColumns1(database)}</TableBody>
                </Table>
              </Box>
            )}
          </Box>
        </Box>
      </Modal>
      {openConfig.isOpen && (
        <DatabaseCalculatorConfig
          isOpen={openConfig.isOpen}
          onCloseModal={openConfig.close}
          id={DataBaseList[0].id}
          inputData={{...DataBaseList[0],...{idd:DataBaseList[0].id}}}
          setCounter={(cat:any)=>{}}
          parentClose={onClose}
          categoryName={"categoryName"}
          setResStatus={(abc:any)=>{}}
          typee={openConfig.propsId}
        />
      )}
    </>
  );
};

// ----------------------------------------------------------------------------------

export default SelectedDatabaseRecordInfoModal;
