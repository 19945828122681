import React, { useEffect, useRef, useState } from "react";
import styles from "./ArchitectureCreateImageSection.module.scss";
import Image from "../../ViewModule/FeatureLandingPage/ArchitectureImageCard";
import successIcon from '../../Assets/images/success.png';
import NoImgLoader from "../../Assets/images/loading-screen.gif";
import {
  Box,
  Button,
  Checkbox,
  Icon,
  IconButton,
  Skeleton,
  TextField,
  debounce,
} from "@mui/material";
import ArrowCircleUpTwoToneIcon from "@mui/icons-material/ArrowCircleUpTwoTone";
import ArrowCircleDownTwoToneIcon from "@mui/icons-material/ArrowCircleDownTwoTone";
import { API } from "../../api-services";
import { useAppDispatch } from "../../../AppRouter";
import { getArchImages } from "../../Redux/Actions/architecture.action";
import VerifiedIcon from "@mui/icons-material/Verified";
import { useRouteParams } from "../../ui-reusable-component/useRouteParams";
import { useImageRotate } from "../../Core/CustomHooks/useImageRotate";
import DeleteIcon from "@mui/icons-material/Delete";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import ArchitectureSingleUploadS3 from "./ArchitectureSingleUploadS3";
import CloseIcon from '@mui/icons-material/Close';
import swal from "sweetalert";
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import ArchImageEditModal from "./ArchImageEditModal";
import { useBaseModal } from "../SearchPage/useBaseModal";
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import EditIcon from '@mui/icons-material/Edit';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { LoadingButton } from "@mui/lab";
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import RotateRightIcon from '@mui/icons-material/RotateRight';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import CardMoveModal from "./CardMoveModal";
import { ArchCreateImageUploadModal } from "./ArchCreateImageUploadModal";
import ImageTaggingModal from "../ArchitectureModule/ArchLandingPage/ImageTagging/ImageTaggingModal";
import ArchImageTaggingModal from "./ArchImageTaggingModal";
import MoveDownIcon from '@mui/icons-material/MoveDown';
import MoveUpIcon from '@mui/icons-material/MoveUp';
import DriveFileMoveOutlinedIcon from '@mui/icons-material/DriveFileMoveOutlined';


import Swal from "sweetalert2";
interface ArchitectureImageCardProps {
  imageListdata: any;
  setCounter?: any;
  cardType: any;
  setSelectedType: any;
  setRecycleBinCounter: any;
  SequenceCount: any;
  setSequenceCount: any;
  ImageDataHandler: any;
  userPermissionTopvaluewise: any,
  indexCount?: any;
}

const ArchitectureImageCard = (props: ArchitectureImageCardProps) => {
  const {
    projectId,
    projectname,
    abbr,
    topvoult,
    subSystem,
    groupId,
    collapestatus,
    pageNumber,
    productpage,
    groupName
  } = useRouteParams<any>();
  const { imageListdata, setCounter, cardType, indexCount, 
    setSelectedType, setRecycleBinCounter, SequenceCount,
    setSequenceCount, ImageDataHandler, userPermissionTopvaluewise
  } = props;
  const [sequence, setsequences] = useState<any>();
  const [CounterPage, setCounterPage] = useState<any>(0)
  const [Loader, setLoader] = useState<any>(false);
  const [SelectedId, setSelectedId] = useState<any>();
  const SingleimageRef = useRef<any>(null)
  const ImageEditor = useBaseModal()
  const [validationdata, setvalidationdata] = useState<any>();
  const [squenceItem, setsequencesItem] = useState<any>()
  const [MoveCardData, setMoveCardData] = useState<any>({});
  const [GroupData, setGroupData] = useState<any>({})
  const movemodal = useBaseModal();
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const ImageModalupload = useBaseModal()
  const ItemAbbr = { abbreviation: abbr }
  const [tagModal, setTagModal] = useState<boolean>(false);
  const [top_vault, setTop_vault] = useState<any>(0);
  useEffect(() => {
    if (cardType == "fill") {
      setsequences(imageListdata?.sequence && imageListdata?.sequence);
      setvalidationdata(imageListdata?.validation && imageListdata?.validation);
    }
  }, [imageListdata, cardType]);

  const dispatch = useAppDispatch();

  const sequenceHandler = (e: any, item: any) => {
    {
      setsequences(e?.target?.value);
      ExactDebounce(item, e?.target?.value)
      setsequencesItem(item)
    }
  };

  const SingleUploadImageS3 = (e: any) => {
    var File = e?.target?.files[0]
    ArchitectureSingleUploadS3([File], squenceItem, projectId, abbr, dispatch, setLoader, pageNumber, productpage)
  }
  const singleImageUplaod = (e: any, sequenceItem: any) => {
    setsequencesItem(sequenceItem)
    return SingleimageRef.current.click()
  }


  const updateEditedImage = (file: any, id: any) => {
   
    ArchitectureSingleUploadS3([file], squenceItem, projectId, abbr, dispatch, setLoader, pageNumber, productpage, SwalAlertHandlerImages, ImageEditor)
  }
  const NewuploadImageHandler = (item: any) => {
    setGroupData({ ...item, name: groupName })
    ImageModalupload.open()
  }
  
  const ExactDebounce = React.useRef(
    debounce(async (criteria: any, sequence: any) => {
      await API.put(`/circuit_diagram/archs/${criteria?.id}/`, { new_sequence: parseInt(sequence) }).then((res: any) => {
        dispatch(
          getArchImages({
            project: projectId,

            abbreviation: abbr,

            page: pageNumber,
            col_page: productpage,
            group_id: groupId,
          })
        );
        API.put(`/circuit_diagram/archs/${criteria?.id}/`, {
          validation: false,
        }).then((res: any) => { })
      });
    }, 1000)
  ).current;
  var permissionValue = userPermissionTopvaluewise && userPermissionTopvaluewise?.filter((permissionItem: any, index1: any) => { return (permissionItem?.action !== null && permissionItem?.top_vault !== "all") && permissionItem?.top_vault == imageListdata?.top_vault })[0]?.action




  const openTagmodal = (top_vault: any) => {

    setTop_vault(top_vault)

    setTagModal(true);
  };
  const DeleteHandler = (e: any, Id: any, item: any, type?: any) => {


    if (type == "Delete") {

      swal({
        title: "Deleting this Diagrams will effect to the sequence.",
        text: "Are you sure you want to delete this Diagrams?",
        buttons: ["Cancel", "Yes"],

        icon: "error",
      }).then((confirm) => {
        if (confirm) {
          setsequencesItem(item)
          setLoader(true)
          API.delete(`/circuit_diagram/archs/${Id}/`, {
            reorder: true
          }, 0)
            .then((res: any) => {
              setRecycleBinCounter((prev: any) => prev + 1)
              setSequenceCount((prev: any) => prev + 1)
              dispatch(
                getArchImages({
                  project: projectId,

                  abbreviation: abbr,

                  page: pageNumber,
                  col_page: productpage,
                  group_id: groupId,
                })
              );
              setLoader(false)
            })
            .catch((err: any) => {
              setLoader(false)
              console.log("Sever Error");
            });
        }
      });
    } else {

      swal({
        title: "Clear Diagrams",
        text: "Are you sure you want to delete this Diagrams?",
        buttons: ["Cancel", "Yes"],
        icon: "error",
      }).then((confirm) => {
        if (confirm) {
          setsequencesItem(item)
          setLoader(true)
          API.delete(`/circuit_diagram/archs/${Id}/`, {

          }, 0)
            .then((res: any) => {
              setRecycleBinCounter((prev: any) => prev + 1)
              setSequenceCount((prev: any) => prev + 1)
              dispatch(
                getArchImages({
                  project: projectId,

                  abbreviation: abbr,

                  page: pageNumber,
                  group_id: groupId,
                  col_page: productpage
                })

              );
              setLoader(false)
            })
            .catch((err: any) => {
              console.log("Sever Error");
              setLoader(false)
            });
        }
      });
    }

  };


  const NAImageHandler = (item: any) => {
    setLoader(true)
    API.post("/circuit_diagram/archs/", { NA: true }, { project: projectId, abbreviation: abbr, group: item?.group, categories: item?.circuit_type, sequence: item?.sequence, top_vault: item?.top_vault }).then((res: any) => {


      setSequenceCount((prev: any) => prev + 1)
      dispatch(
        getArchImages({
          project: projectId,

          abbreviation: abbr,

          page: pageNumber,
          group_id: groupId,
          col_page: productpage
        })
      );
      setLoader(false);
    }).catch((err: any) => {
      setLoader(false);
    })
  }

  const MoveUpHandler = (item: any) => {
    setLoader(true);
    setSelectedId(item?.id);
    API.put(`/circuit_diagram/archs/${item?.id}/`, {
      new_sequence: item?.sequence - 1,
    })
      .then((res: any) => {
        dispatch(
          getArchImages({
            project: projectId,
            abbreviation: abbr,
            page: pageNumber,
            group_id: groupId,
            col_page: productpage
          })
        );
        setLoader(false);
      })
      .catch((err: any) => {
        setLoader(false);
      });
  };

  const AddblankaHandler = (item: any, type: any) => {

    var data = abbr == 0 ? { top_vault: item?.top_vault } : { vault: item?.vault }
    setLoader(true);
    setSelectedId(item?.id);
    if (type == "above") {
      API.post(`/circuit_diagram/archs/`, {
        reorder: item?.sequence,
      }, {
        group: item?.group,
        categories: item?.circuit_type,
        ...data
      })
        .then((res: any) => {
          setSequenceCount((prev: any) => prev + 1)
          dispatch(
            getArchImages({
              project: projectId,

              abbreviation: abbr,

              page: pageNumber,
              group_id: groupId,
              col_page: productpage
            })
          );
          setLoader(false);
        })
        .catch((err: any) => {
          setLoader(false);
        });
    } else {


      API.post(`/circuit_diagram/archs/`, {
        reorder: item?.sequence + 1,
      }, {
        group: item?.group,
        categories: item?.circuit_type,
        ...data
      })
        .then((res: any) => {
          dispatch(
            getArchImages({
              project: projectId,

              abbreviation: abbr,

              page: pageNumber,
              group_id: groupId,
              col_page: productpage
            })
          );
          setLoader(false);
        })
        .catch((err: any) => {
          setLoader(false);
        });
    }
  }

  const MoveDownHandler = (item: any) => {
    setLoader(true);
    setSelectedId(item?.id);
    API.put(`/circuit_diagram/archs/${item?.id}/`, {
      new_sequence: item?.sequence + 1,
    })
      .then((res: any) => {
        dispatch(
          getArchImages({
            project: projectId,

            abbreviation: abbr,

            page: pageNumber,
            group_id: groupId,
            col_page: productpage
          })
        );
        setLoader(false);
      })
      .catch((err: any) => {
        setLoader(false);
      });
  };
  const onImageRotate = async (rotation: number) => {
    try {
      await API.put(`/circuit_diagram/archs/${imageListdata?.id}/`, { rotation: rotation }, {}, 0).then((res: any) => {


        API.put(`/circuit_diagram/archs/${imageListdata?.id}/`, {
          validation: false,
        }).then((res: any) => {

        })
        dispatch(
          getArchImages({
            project: projectId,

            abbreviation: abbr,

            page: pageNumber,
            group_id: groupId,
            col_page: productpage
          })
        );

      });

      return true;
    } catch (e) {

      return false;
    }

  };
  // ----------------------------------------------------------------------------------
  // hooks
  const { handleRotate, isRotating } = useImageRotate(onImageRotate);

  // ----------------------------------------------------------------------------------
  // hooks
  const imageForApp = imageListdata && imageListdata?.original?.replaceAll("prod.xcpep.com/", "");
  const relImageURL = imageListdata && (imageListdata?.original?.includes("prod.xcpep.com/") === false ? imageListdata?.original?.split("?")[0]?.split(".com")[1]?.replaceAll("%20", " ")?.replaceAll("%28", "(")?.replaceAll("%29", ")") : imageForApp?.split("?")[0]?.split(".com")[1]?.replaceAll("%20", " ")?.replaceAll("%28", "(")?.replaceAll("%29", ")"));


  const SwalAlertHandlerImages = React.useCallback(() => {
    {
      Swal.fire({
        html: `<div><img src="${successIcon}" alt="Custom Image" style="width: 7rem; height: auto;" />
  <br />
  <p style="color:#007fff;"> Diagrams Updated successfully
  </p>   
   </div>`,
      });
    }

  }, []);

  const handleChangeCheckBox = (e: any) => {
    setvalidationdata(e?.target?.checked);
    API.put(`/circuit_diagram/archs/${imageListdata?.id}/`, {
      validation: e?.target?.checked,
    })
      .then((res: any) => {
        setSequenceCount((prev: any) => prev + 1)
        dispatch(
          getArchImages({
            project: projectId,

            abbreviation: abbr,

            page: pageNumber,
            group_id: groupId,
            col_page: productpage
          })
        );
      })
      .catch((err: any) => {
        setLoader(false);

        setvalidationdata(!e?.target?.checked);
      });
  };

  const handleOnLoad = () => {
    setIsLoaded(true);
  };
  const imageEditorHandler = (item: any) => {
    setsequencesItem(item)
    ImageEditor.open()
  }


  const MoveCardhandler = (item: any) => {
    setMoveCardData(item)
    movemodal.open()
  }



  return (
    <>
      <input
        type="file"
        accept="image/*"
        style={{ display: 'none' }}
        ref={SingleimageRef}
        onChange={(e) => SingleUploadImageS3(e)}


      />
      {ImageEditor?.isOpen &&
        <ArchImageEditModal
          Loader={Loader}
          open={ImageEditor.isOpen}
          imageURI={relImageURL}
          closeModal={ImageEditor.close}
          imageUrl={imageListdata?.original}

          updateImage={updateEditedImage}
        />
      }
      {tagModal &&
        <ArchImageTaggingModal
          userPermissionTopvaluewise={userPermissionTopvaluewise}
          projectId={projectId}
          vaultId={top_vault}
          isLoaded={isLoaded}
          setTagModal={setTagModal}
          tagModal={tagModal}
          selectedsceImages={imageListdata}
          topVaultId={top_vault}
        />
      }
      {
        ImageModalupload?.isOpen &&

        <ArchCreateImageUploadModal
          GroupData={GroupData}
          userPermissionTopvaluewise={userPermissionTopvaluewise}
          setcounter={setSelectedType}
          type={"old_group"}
          Item={ItemAbbr}
          onCloseModal={ImageModalupload.close}
          isOpen={ImageModalupload.isOpen}
        />
      }

      {
        movemodal.isOpen &&
        <CardMoveModal
          onCloseModal={movemodal.close}
          isOpen={movemodal.isOpen}
          Item={MoveCardData}
          setSelectedType={setSelectedType}
        />

      }


      {Loader
        &&
        squenceItem?.id == imageListdata?.id
        ?
        <Skeleton
          variant="rectangular"
          width={281}
          height={137}
          sx={{ margin: " 0rem 1rem 1rem 1rem" }}
        />

        : (
          (
            <div className={styles.image_card_container}>


              {imageListdata?.image_url_cdn == "" ?
                <>
                  <div className={styles.action_sequence}>
                    <span>{sequence}</span>
                  </div>
                  <Box

                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      objectFit: "contain",
                      height: "100%",
                      width: "100%",
                      cursor: "pointer",
                    }} >

                    Upload Diagram
                  </Box>

                  <div className={styles.action_container}>

                    {permissionValue && permissionValue?.includes("U") ?
                      <Button sx={{ cursor: "pointer", padding: '0.3rem 0.5rem', }} onClick={() => NAImageHandler(imageListdata)}>NA</Button> : ""
                    }


                    {
                      permissionValue && permissionValue?.includes("U") ?

                        <FileUploadIcon titleAccess="Upload Image"
                          sx={{ cursor: "pointer" }} onClick={() => NewuploadImageHandler(imageListdata)} /> : ""
                    }



                  </div></>

                :
                <>

                  <div className={styles.action_sequence}>
                    {/* <span onClick={()=>SequenceClick()} style={{ border:"1px solid",padding:"0rem 0.5rem 0.5rem 0.5rem",borderRadius:"50%"}}>
         {imageListdata?.sequence && imageListdata?.sequence}
        </span> */}
                    <TextField
                      value={sequence}
                      type="number"
                      disabled={permissionValue && permissionValue?.includes("U") ? false : true}
                      inputProps={{
                        step: 'any',
                        min: 1,
                        // style: { fontSize: '1rem', marginTop: '0.4rem' }
                      }}

                      onChange={(e: any) => sequenceHandler(e, imageListdata)}
                      sx={{
                        zIndex: 1,
                        width: "4rem", paddingLeft: "0.5rem", background: "#bbdefba1",
                        ".MuiInputBase-root.MuiInput-root": {
                          fontSize: '1rem !important'
                        },
                        '& .MuiInput-underline:before': { borderBottomColor: 'primary.light' },
                        '& .MuiInput-underline:after': { borderBottomColor: 'primary.main' },
                      }}
                      variant="standard"
                    />
                    {/* <button
                onClick={() => {
                  savehandler();
                }}>
                Save
              </button> */}
                  </div>


                  <Box
                    sx={{
                      width: "2rem",
                      textAlign: "center",
                      position: "absolute",
                      right: "1rem",
                      zIndex: 1,
                    }}>
                    {validationdata !== undefined && (
                      <Checkbox
                        style={{ padding: "3px", color: "red" }}
                        onChange={(e) => handleChangeCheckBox(e)}
                        checked={validationdata}
                        disabled={permissionValue && permissionValue?.includes("U") ? false : true}
                        sx={{ color: "primary.main" }}
                        checkedIcon={<Box
                          sx={{
                            backgroundColor: "white",
                            borderRadius: "50%",
                            height: "1rem",
                            width: "1rem",
                            margin: "0.4rem 0.35rem 0.35rem",
                            // marginRight: "0.5rem",
                          }}>
                          <VerifiedIcon
                            sx={{
                              color: "#39FF14",
                              position: "relative",
                              margin: "-0.36rem -0.25rem 0",
                            }}

                          />
                        </Box>} />
                    )}
                  </Box>
                  <Image
                    className={styles.image}
                    src={imageListdata?.image_url_cdn && imageListdata?.image_url_cdn}
                    onLoad={handleOnLoad}
                    placeholderImg={NoImgLoader}
                    onClick={(e: any) => abbr == 0 ? ImageDataHandler(imageListdata) : openTagmodal(imageListdata?.top_vault)}
                    alt={imageListdata?.name}
                    style={{
                      objectFit: "contain",
                      height: "100%",
                      width: "100%",
                      cursor: "pointer",
                    }} />

                  <div className={styles.action_container}>


                    {
                      permissionValue && permissionValue?.includes("D") ?
                        <CloseIcon
                          titleAccess="Clear Diagram"
                          onClick={(e: any) => DeleteHandler(e, imageListdata?.id, imageListdata)}

                          sx={{ cursor: "pointer" }} /> : ""
                    }




                    {
                      permissionValue && permissionValue?.includes("D") ?
                        <DeleteIcon

                          titleAccess="Delete Diagram"
                          sx={{ cursor: "pointer" }}
                          onClick={(e: any) => DeleteHandler(e, imageListdata?.id, imageListdata, "Delete")} />


                        : ""
                    }




                    {
                      permissionValue && permissionValue?.includes("U")


                        ?
                        <EditIcon
                          titleAccess="Edit Diagram"
                          onClick={() => imageEditorHandler(imageListdata)}
                          sx={{ cursor: "pointer" }}
                        />
                        : ""

                    }


                    {imageListdata?.sequence && imageListdata?.sequence !== 1 && (

                      permissionValue && permissionValue?.includes("U") ?

                        <MoveUpIcon
                          onClick={() => MoveUpHandler(imageListdata)}
                          titleAccess="Move Up"
                          sx={{ cursor: "pointer" }} />
                        : ""
                    )}




                    {
                      permissionValue && permissionValue?.includes("U") ?
                        <MoveDownIcon
                          onClick={() => MoveDownHandler(imageListdata)}
                          titleAccess="Move Down"
                          sx={{ cursor: "pointer" }} />

                        : ""
                    }




                    {
                      permissionValue && permissionValue?.includes("U") ?

                        <ArrowCircleUpIcon
                          titleAccess="Add blank above"
                          onClick={() => AddblankaHandler(imageListdata, "above")}
                          sx={{ cursor: "pointer" }}
                        />
                        : ""
                    }




                    {
                      permissionValue && permissionValue?.includes("U") ?

                        <ArrowCircleDownIcon
                          onClick={() => AddblankaHandler(imageListdata, "below")}
                          titleAccess="Add blank below"
                          sx={{ cursor: "pointer" }}
                        /> : ""
                    }


                    <LoadingButton disabled={permissionValue && permissionValue?.includes("U") ? false : true} className={styles.icon} style={{ padding: 0, minWidth: "1.5rem", height: "1.7rem" }} loading={isRotating?.type == 'undo' ? isRotating?.load : false}
                      sx={{
                        marginTop: '2px',
                        '.MuiCircularProgress-root': {
                          color: 'primary.main',
                        },
                      }}>
                      <RotateLeftIcon titleAccess="rotate Left" onClick={() => handleRotate('undo')} />
                    </LoadingButton>
                    <LoadingButton disabled={permissionValue && permissionValue?.includes("U") ? false : true} className={styles.icon} style={{ padding: 0, minWidth: "1.5rem", height: "1.7rem" }} loading={isRotating?.type == 'redo' ? isRotating?.load : false}
                      sx={{
                        marginTop: '2px',
                        '.MuiCircularProgress-root': {
                          color: 'primary.main',
                        },
                      }}>
                      <RotateRightIcon titleAccess="rotate Right" onClick={() => handleRotate('redo')} />
                    </LoadingButton>


                    {permissionValue && permissionValue?.includes("U") ?

                      <FileUploadIcon
                        titleAccess="Upload Image"
                        sx={{ cursor: "pointer" }}
                        onClick={(e: any) => singleImageUplaod(e, imageListdata)} />
                      : ""}

                    {
                      permissionValue && permissionValue?.includes("U") ?

                        <DriveFileMoveOutlinedIcon sx={{ cursor: "pointer" }} titleAccess="Move to other Group" onClick={() => MoveCardhandler(imageListdata)} />
                        : ""
                    }


                  </div>
                </>


              }

            </div>
          )
          // ) : (
          //   <div className={styles.image_card_container}>
          //     <div className={styles.action_container}>
          //       <FileUploadIcon />
          //     </div>
          //   </div>
        )}
    </>
  );
};
export default ArchitectureImageCard;
